/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i3 from "primeng/components/checkbox/checkbox";
import * as i4 from "@angular/forms";
import * as i5 from "./checkbox.component";
import * as i6 from "@ngx-translate/core";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p-checkbox", [["binary", "true"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.Checked = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onChange.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Checkbox_0, i2.RenderType_Checkbox)), i1.ɵdid(1, 49152, null, 0, i3.Checkbox, [i1.ChangeDetectorRef], { disabled: [0, "disabled"], binary: [1, "binary"], label: [2, "label"], style: [3, "style"] }, { onChange: "onChange" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.Checkbox]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.disabled; var currVal_8 = "true"; var currVal_9 = _co.label; var currVal_10 = _co.style; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.disabled; var currVal_12 = _co.Checked; _ck(_v, 3, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CheckboxComponent]), i1.ɵdid(2, 114688, null, 0, i5.CheckboxComponent, [i6.TranslateService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CheckboxComponentNgFactory = i1.ɵccf("dulo-checkbox", i5.CheckboxComponent, View_CheckboxComponent_Host_0, { checked: "checked", style: "style", disabled: "disabled", label: "label", name: "name" }, { onChange: "onChange" }, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
