import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'dulo-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isLoading$: Observable<boolean>;
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.hide();
    this.isLoading$ = this.loaderService.isLoading.asObservable();
  }
}
