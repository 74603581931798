/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./supplies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/table/table.component.ngfactory";
import * as i3 from "../../components/table/table.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../components/generators/excel-generator/excel-generator.service";
import * as i6 from "@angular/common";
import * as i7 from "./supplies.component";
import * as i8 from "../../_services/product-type.service";
import * as i9 from "../../_services/supply.service";
import * as i10 from "../../_services/color.service";
var styles_SuppliesComponent = [i0.styles];
var RenderType_SuppliesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuppliesComponent, data: {} });
export { RenderType_SuppliesComponent as RenderType_SuppliesComponent };
export function View_SuppliesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-table", [], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(1, 245760, null, 0, i3.TableComponent, [i4.HttpClient, i5.ExcelGeneratorService, i1.ChangeDetectorRef], { rows: [0, "rows"], columns: [1, "columns"], values: [2, "values"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = 20; var currVal_1 = _co.cols; var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform(_co.supplies$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_SuppliesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-supplies", [], null, null, null, View_SuppliesComponent_0, RenderType_SuppliesComponent)), i1.ɵdid(1, 49152, null, 0, i7.SuppliesComponent, [i8.ProductTypeService, i9.SupplyService, i10.ColorService], null, null)], null, null); }
var SuppliesComponentNgFactory = i1.ɵccf("app-supplies", i7.SuppliesComponent, View_SuppliesComponent_Host_0, {}, {}, []);
export { SuppliesComponentNgFactory as SuppliesComponentNgFactory };
