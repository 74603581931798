import { NgModule } from '@angular/core';
import { SwitchComponent } from './switch.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [SwitchComponent],
  imports: [SharedModule, FormsModule, InputSwitchModule],
  exports: [SwitchComponent],
})
export class SwitchModule {}
