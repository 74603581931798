/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accordion.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/accordion/accordion.ngfactory";
import * as i3 from "primeng/components/accordion/accordion";
import * as i4 from "@angular/common";
import * as i5 from "./accordion.component";
var styles_AccordionComponent = [i0.styles];
var RenderType_AccordionComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AccordionComponent, data: {} });
export { RenderType_AccordionComponent as RenderType_AccordionComponent };
function View_AccordionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AccordionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p-accordionTab", [], [[8, "className", 0]], null, null, i2.View_AccordionTab_0, i2.RenderType_AccordionTab)), i1.ɵdid(1, 1228800, [[1, 4]], 2, i3.AccordionTab, [i3.Accordion, i1.ChangeDetectorRef], { header: [0, "header"] }, null), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { templates: 1 }), (_l()(), i1.ɵand(16777216, null, 1, 2, null, View_AccordionComponent_3)), i1.ɵdid(5, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(6, { $implicit: 0, index: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _v.context.$implicit.data, _v.context.index); var currVal_3 = _co.template; _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.meta == null) ? null : _v.context.$implicit.meta.class); _ck(_v, 0, 0, currVal_0); }); }
function View_AccordionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "p-accordion", [], null, null, null, i2.View_Accordion_0, i2.RenderType_Accordion)), i1.ɵdid(2, 1228800, null, 1, i3.Accordion, [i1.ElementRef, i1.ChangeDetectorRef], { activeIndex: [0, "activeIndex"] }, null), i1.ɵqud(603979776, 1, { tabList: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccordionComponent_2)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tabs; _ck(_v, 5, 0, currVal_1); }, null); }
function View_AccordionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AccordionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p-accordionTab", [], null, null, null, i2.View_AccordionTab_0, i2.RenderType_AccordionTab)), i1.ɵdid(1, 1228800, [[4, 4]], 2, i3.AccordionTab, [i3.Accordion, i1.ChangeDetectorRef], { header: [0, "header"] }, null), i1.ɵqud(603979776, 5, { headerFacet: 1 }), i1.ɵqud(603979776, 6, { templates: 1 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_AccordionComponent_6)), i1.ɵdid(5, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.tabsMap[_v.context.$implicit]; _ck(_v, 5, 0, currVal_1); }, null); }
function View_AccordionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "p-accordion", [], null, null, null, i2.View_Accordion_0, i2.RenderType_Accordion)), i1.ɵdid(2, 1228800, null, 1, i3.Accordion, [i1.ElementRef, i1.ChangeDetectorRef], { activeIndex: [0, "activeIndex"] }, null), i1.ɵqud(603979776, 4, { tabList: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccordionComponent_5)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.keys; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AccordionComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "dulo-accordion"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccordionComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccordionComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.template; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.template; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AccordionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-accordion", [], null, null, null, View_AccordionComponent_0, RenderType_AccordionComponent)), i1.ɵdid(1, 49152, null, 0, i5.AccordionComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var AccordionComponentNgFactory = i1.ɵccf("dulo-accordion", i5.AccordionComponent, View_AccordionComponent_Host_0, { activeIndex: "activeIndex", template: "template", tabs: "tabs", TabsMap: "TabsMap" }, {}, []);
export { AccordionComponentNgFactory as AccordionComponentNgFactory };
