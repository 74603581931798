import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProductTypeService {
    constructor(http) {
        this.http = http;
        this.baseUrl = 'product_types';
    }
    getAll() {
        return this.http.get(`${this.baseUrl}`);
    }
}
ProductTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductTypeService_Factory() { return new ProductTypeService(i0.ɵɵinject(i1.HttpClient)); }, token: ProductTypeService, providedIn: "root" });
