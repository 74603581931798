<div class="d-flex">
  <p-calendar
    [style]="style"
    [inputStyle]="{ width: '80%' }"
    [disabled]="disabled"
    [defaultDate]="defaultDate"
    dateFormat="dd.mm.yy"
    [(ngModel)]="value"
    [selectionMode]="selectionMode"
    showIcon="true"
    monthNavigator="true"
    yearNavigator="true"
    yearRange="2000:2100"
  >
    <p-footer>
      <button
        *ngIf="showOkButton()"
        [disabled]="!isRangeChosen()"
        (click)="finish()"
      >
        Ok
      </button>
    </p-footer>
  </p-calendar>
  <button (click)="cancel()" type="button">X</button>
</div>
