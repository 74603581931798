/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./primer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/switch/switch.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../../components/switch/switch.component";
import * as i5 from "../../components/card/card.component.ngfactory";
import * as i6 from "../../components/card/card.component";
import * as i7 from "@angular/common";
import * as i8 from "../../components/button/button.component.ngfactory";
import * as i9 from "../../components/button/button.component";
import * as i10 from "./primer.component";
import * as i11 from "../../_services/order.service";
import * as i12 from "../../components/popup/popup.service";
var styles_PrimerComponent = [i0.styles];
var RenderType_PrimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrimerComponent, data: {} });
export { RenderType_PrimerComponent as RenderType_PrimerComponent };
function View_PrimerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Nema dizajn"], ["class", "w-100 h-auto"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Proizvod - ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Veli\u010Dina - ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Boja - ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "w-100 my-2 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "dulo-switch", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; if (("onChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.primer_used = !_v.parent.context.$implicit.primer_used) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SwitchComponent_0, i2.RenderType_SwitchComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.SwitchComponent]), i1.ɵdid(10, 114688, null, 0, i4.SwitchComponent, [i1.ChangeDetectorRef], { label: [0, "label"], Checked: [1, "Checked"] }, { onChange: "onChange" })], function (_ck, _v) { var currVal_4 = (_v.parent.context.$implicit.primer_used ? "Sa prajmerom" : "Bez prajmera"); var currVal_5 = _v.parent.context.$implicit.primer_used; _ck(_v, 10, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.product_images[0].product_image_url; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit["product_type_name"]; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit["product_size"]; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit["product_color"]; _ck(_v, 6, 0, currVal_3); }); }
function View_PrimerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-card", [["class", "col-2 p-2"]], null, null, null, i5.View_CardComponent_0, i5.RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i6.CardComponent, [], { mainTemplate: [0, "mainTemplate"] }, null), (_l()(), i1.ɵand(0, [["orderItemTemplate", 2]], null, 0, null, View_PrimerComponent_2))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PrimerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-fluid my-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrimerComponent_1)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row d-flex justify-content-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "dulo-button", [["label", "Sa\u010Duvaj"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(6, 114688, null, 0, i9.ButtonComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orderItems; _ck(_v, 3, 0, currVal_0); var currVal_1 = "Sa\u010Duvaj"; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PrimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-primer", [], null, null, null, View_PrimerComponent_0, RenderType_PrimerComponent)), i1.ɵdid(1, 245760, null, 0, i10.PrimerComponent, [i11.OrderService, i12.PopupService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrimerComponentNgFactory = i1.ɵccf("app-primer", i10.PrimerComponent, View_PrimerComponent_Host_0, {}, {}, []);
export { PrimerComponentNgFactory as PrimerComponentNgFactory };
