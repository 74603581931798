/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./number-range-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../input/input.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../input/input.component";
import * as i5 from "@angular/common";
import * as i6 from "./number-range-input.component";
var styles_NumberRangeInputComponent = [i0.styles];
var RenderType_NumberRangeInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NumberRangeInputComponent, data: {} });
export { RenderType_NumberRangeInputComponent as RenderType_NumberRangeInputComponent };
export function View_NumberRangeInputComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { minInput: 0 }), i1.ɵqud(402653184, 2, { maxInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "dulo-input", [["class", "dulo-input min"]], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.check() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InputComponent_0, i2.RenderType_InputComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.InputComponent]), i1.ɵdid(5, 114688, [[1, 4], ["min", 4]], 0, i4.InputComponent, [i1.ChangeDetectorRef], { placeholder: [0, "placeholder"], disabled: [1, "disabled"], regex: [2, "regex"], Value: [3, "Value"] }, { onChange: "onChange" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "dulo-input", [["class", "dulo-input max"]], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.check() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InputComponent_0, i2.RenderType_InputComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.InputComponent]), i1.ɵdid(9, 114688, [[2, 4], ["max", 4]], 0, i4.InputComponent, [i1.ChangeDetectorRef], { placeholder: [0, "placeholder"], disabled: [1, "disabled"], regex: [2, "regex"], Value: [3, "Value"] }, { onChange: "onChange" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 5, "input", [["class", "value"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.Value = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(16, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minPlaceholder; var currVal_1 = _co.disabled; var currVal_2 = (_co.onlyPositive ? "pnum" : "num"); var currVal_3 = i1.ɵunv(_v, 5, 3, i1.ɵnov(_v, 6).transform(_co.minValue)); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.maxPlaceholder; var currVal_5 = _co.disabled; var currVal_6 = (_co.onlyPositive ? "pnum" : "num"); var currVal_7 = i1.ɵunv(_v, 9, 3, i1.ɵnov(_v, 10).transform(_co.maxValue)); _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_15 = _co.Value; _ck(_v, 14, 0, currVal_15); }, function (_ck, _v) { var currVal_8 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 16).ngClassValid; var currVal_13 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_NumberRangeInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-number-range-input", [], null, null, null, View_NumberRangeInputComponent_0, RenderType_NumberRangeInputComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.NumberRangeInputComponent]), i1.ɵdid(2, 114688, null, 0, i6.NumberRangeInputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NumberRangeInputComponentNgFactory = i1.ɵccf("dulo-number-range-input", i6.NumberRangeInputComponent, View_NumberRangeInputComponent_Host_0, { disabled: "disabled", minRangeValue: "minRangeValue", maxRangeValue: "maxRangeValue", minPlaceholder: "minPlaceholder", maxPlaceholder: "maxPlaceholder", onlyPositive: "onlyPositive" }, { range: "range" }, []);
export { NumberRangeInputComponentNgFactory as NumberRangeInputComponentNgFactory };
