import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { DynamicDialogConfig } from "primeng/api";
import { ExportOrderService } from "src/app/_services/export-order.service";
import { TableColumn } from "src/app/components/table/table-column";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersComponent implements OnInit {
  cols: TableColumn[] = [
    {
      header: "ID",
      field: "id",
      filterType: "string",
      type: "alphanumeric",
      width: "5%",
    },
    {
      header: "Ime",
      field: "firstName",
      filterType: "string",
      type: "alphanumeric",
      width: "10%",
    },
    {
      header: "Prezime",
      field: "lastName",
      filterType: "string",
      type: "alphanumeric",
      width: "10%",
    },
    {
      header: "Email",
      field: "email",
      filterType: "string",
      type: "alphanumeric",
      width: "10%",
    },
    {
      header: "Adresa",
      field: "address",
      filterType: "string",
      type: "alphanumeric",
      width: "10%",
    },
    {
      header: "Grad",
      field: "city",
      filterType: "string",
      type: "alphanumeric",
      width: "10%",
    },
    {
      header: "Država",
      field: "country",
      filterType: "string",
      type: "alphanumeric",
      width: "10%",
    },
    {
      header: "Poštanski broj",
      field: "postalCode",
      filterType: "string",
      type: "alphanumeric",
      width: "10%",
    },
  ];

  pageSize = 30;
  currentFilters = {
    first: 0,
    rows: this.pageSize,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  orders$ = ((event) => {
    this.currentFilters = {
      ...this.currentFilters,
      ...event,
      filters: {
        ...event.filters,
      },
    };
    return this.exportOrderService.getOrders(
      this.config.data.exportOrder.id,
      this.currentFilters
    );
  }).bind(this);

  constructor(
    private exportOrderService: ExportOrderService,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}
}
