<div class='row'>
    <div class="col">Proizvod: <strong>{{ orderItem.product_type_name }}</strong></div>
    <div class="col">Boja: <strong>{{ orderItem.product_color }}</strong></div>
    <div class="col">Velicina: <strong>{{ orderItem.product_size }}</strong></div>
</div>
<div class='row'>
    <div class='col-10 row'>
        <ng-container *ngIf='orderItem.hasDesign'>
            <ng-container *ngFor='let pi of orderItem.product_images'>
                <div *ngIf='pi.canvas_image_url' class='col-4 p-2'>
                    <dulo-card [mainTemplate]='piTemplate' [headerTemplate]='piHeaderTemplate' #card [styleClass]='pi.finished ? "bg-success" : "bg-danger"'>
                        <ng-template #piTemplate>
                            <img [src]="pi.product_image_url" class='w-100 h-auto my-2'>
                            <div class='d-flex justify-content-center align-items-center flex-column'>
                                <dulo-button class='my-2' label='Preuzmi sliku' *ngIf='pi.canvas_image_url' (click)='downloadCanvasPhoto(pi)'></dulo-button>
                                <dulo-switch [Checked]='pi.finished' [disabled]='!pi.canvas_image_url' [label]='pi.finished ? "Zavrseno" : "Nezavrseno"' (onChange)='toggleFinish(pi)'></dulo-switch>
                            </div>
                        </ng-template>
                        <ng-template #piHeaderTemplate>
                            <p class='text-center'>{{pi.product_type_part_name}}</p>
                        </ng-template>
                    </dulo-card>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf='!orderItem.hasDesign'>
            <h1 class='text-center mt-4 w-100'>Ne treba ništa da se štampa</h1>
        </ng-container>
    </div>
    <div class='col-2 d-flex flex-column justify-content-between align-items-center'>
        <dulo-button styleClass='w-100 bg-secondary' label='Ponovi izradu' (click)='repeatManufacturing()'></dulo-button>
        <div class='w-100'>
            <dulo-textarea styleClass='w-100' [placeholder]='"Komentar"' [Value]='comment'></dulo-textarea>
            <dulo-button [duloTooltip]='"Morate završiti sa izradom svih delova"' tooltipPosition='left' [tooltipDisabled]='!areAllOrderItemsFinished()' [disabled]='areAllOrderItemsFinished()' styleClass='w-100' label='Završi' (click)='finishOrderItem()'></dulo-button>
        </div>
    </div>
</div>