import { Directive, AfterViewInit, Input, OnInit, ElementRef, Renderer2, NgZone, HostBinding } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[duloTooltip]',
})
export class TooltipDirective implements OnInit, AfterViewInit {

  @Input() tooltipPosition = 'right';

  @Input() tooltipEvent = 'hover';

  @Input() appendTo: any = 'body';

  @Input() positionStyle: string;

  @Input() tooltipStyleClass: string;

  @Input() tooltipZIndex = 'auto';

  @Input("tooltipDisabled") set disabled(disabled: boolean) {
    this.tooltip.disabled = disabled;
  }

  @Input() escape = true;

  @Input() showDelay: number;

  @Input() hideDelay: number;

  @Input() life: number;

  @Input('duloTooltip') set text(text: string) {
    this.tooltip.text = text;
  }

  @HostBinding('attr.pTooltip') tooltip = new Tooltip(this.elRef, this.ngZone);

  constructor(private elRef: ElementRef, private ngZone: NgZone) { }

  ngOnInit(): void {
    this.tooltip.tooltipPosition = this.tooltipPosition;
    this.tooltip.tooltipEvent = this.tooltipEvent;
    this.tooltip.appendTo = this.appendTo;
    this.tooltip.positionStyle = this.positionStyle;
    this.tooltip.tooltipStyleClass = this.tooltipStyleClass;
    this.tooltip.tooltipZIndex = this.tooltipZIndex;
    this.tooltip.disabled = this.disabled;
    this.tooltip.escape = this.escape;
    this.tooltip.showDelay = this.showDelay;
    this.tooltip.hideDelay = this.hideDelay;
    this.tooltip.life = this.life;
    this.tooltip.el = this.elRef;
  }

  ngAfterViewInit(): void {
    this.tooltip.ngAfterViewInit();
  }
}
