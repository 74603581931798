import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import { OrderItem } from 'src/app/_view-models/order-item';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { SubSink } from 'subsink';
import { saveAs } from 'file-saver';
import { OrderService } from 'src/app/_services/order.service';
import { PopupService } from 'src/app/components/popup/popup.service';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderComponent implements OnInit, OnDestroy {

  orderItem: OrderItem;

  @Input() dialog: DialogComponent;

  comment = '';
  subs = new SubSink();
  constructor(private orderService: OrderService,
              private popupService: PopupService) { }

  ngOnInit() {
    this.orderItem = this.dialog.getData().orderItem;
    this.orderItem.product_images.forEach(pi => {
      pi.finished = !pi.canvas_image_url;
    });
  }

  toggleFinish(pi: {
    finished: boolean;
    canvas_image_url: string;
    product_type_part_name: string;
    product_image_url: string;
}) {
    pi.finished = !pi.finished;
  }

  downloadCanvasPhoto(pi: {
    finished: boolean;
    canvas_image_url: string;
    product_type_part_name: string;
    product_image_url: string;
}) {
    this.subs.add(this.orderService.getPhoto(pi.canvas_image_url).subscribe(res => {
      saveAs(res.body, 'print.png');
    }));
  }

  finishOrderItem(): void {
    this.subs.add(this.orderService.toggleOrderItemIsManufactured(this.orderItem.id).subscribe((res) => {
      this.dialog.close(true);
    }));
  }

  repeatManufacturing(): void {
    const controlNumber = 1000 + Math.floor(Math.random() * 9000);
    this.popupService.fire({
      titleText: 'Da li ste sigurni da želite da ponovite izradu?',
      text: 'Ako jeste, unesite sledeći broj - ' + controlNumber,
      input: 'text',
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Da',
      inputValidator: (value) => {
        return +value === controlNumber ? null : 'Uneli ste pogrešan broj';
      },
    }).then((res) => {
      if (res.dismiss) {
        return;
      }
      this.subs.add(this.orderService.repeatOrderItemManufacturing(this.orderItem.id).subscribe(() => {
        this.orderItem.product_images.forEach(pi => {
          if (!pi.canvas_image_url) {
            return;
          }
          pi.finished = false;
        });
        this.popupService.fire({
          type: 'success',
          title: 'Započni ponovo'
        });
      }));
    });
  }

  areAllOrderItemsFinished(): boolean {
    return this.orderItem.product_images.filter(pi => !pi.finished).length !== 0;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
