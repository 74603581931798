<span class="dulo-select-button">
  <p-selectButton
    class="p-select-button"
    [options]="values"
    [disabled]="disabled"
    [(ngModel)]="SelectedItem"
    [multiple]="multiple"
    (onChange)="onChange.emit($event)"
    [styleClass]="styleClass"
    *ngIf="template"
  >
    <ng-template let-item>
      <ng-container
        *ngTemplateOutlet="template; context: { $implicit: item }"
      ></ng-container>
    </ng-template>
  </p-selectButton>
  <p-selectButton
    class="p-select-button"
    [options]="values"
    [disabled]="disabled"
    [(ngModel)]="SelectedItem"
    [multiple]="multiple"
    (onChange)="onChange.emit($event)"
    [styleClass]="styleClass"
    *ngIf="!template"
  ></p-selectButton>
</span>
