<div class="container active-orders mb-4">
  <dulo-select
    #workOrderSelect
    optionLabel="label"
    [multiple]="false"
    placeholder="Odaberi radni nalog"
    [values]="activeWorkOrders$ | async"
    [SelectedItem]="selectedWorkOrder"
    (onChange)="getOrders()"
  ></dulo-select>
</div>

<div class="container">
  <dulo-accordion [tabs]="orders$ | async" [template]="orderTemplate">
  </dulo-accordion>

  <dulo-paginator
    class="mt-3"
    [page]="currentFilters.first"
    [pageSize]="currentFilters.rows"
    [totalRecords]="totalCount"
    (pageChangeHandler)="changePage($event)"
  ></dulo-paginator>
</div>

<ng-template #orderTemplate let-o let-index="index">
  <div class="w-100">
    <dulo-button
      [disabled]="o.state !== 'ACCEPTED'"
      class="w-100"
      label="Zаpočni porudžbinu"
      (click)="startOrder(o)"
    ></dulo-button>
    <dulo-button
      *ngIf="o.shipping_carrier_dispatch_in_advance"
      class="ml-2"
      label="Preuzmi adresnicu"
      (click)="getShippingLabel(o)"
    ></dulo-button>
    <ng-container *ngIf="o.state !== 'ACCEPTED'">
      <div
        class="row {{ oi.meta.rowClass }} my-4 main-color"
        style="border: 6px solid black"
        *ngFor="let oi of o.order_item_list"
      >
        <main class="d-flex flex-column p-2 w-75">
          <div style="font-size: 18px" class="mb-2">
            <p>Proizvod - {{ oi["product_type_name"] }}</p>
            <p>Veličina - {{ oi["product_size"] }}</p>
            <p>Boja - {{ oi["product_color"] }}</p>
            <p>ID priprema: {{ oi["id"] }}</p>
            <p
              *ngIf="
                oi['from_manufactured_product_id'] !== null &&
                oi['from_manufactured_product_id'] !== 0
              "
            >
              Id gotovog proizvoda - {{ oi["from_manufactured_product_id"] }}
            </p>
          </div>

          <hr />

          <div class="d-flex" *ngFor="let pi of oi.product_images">
            <fieldset>
              <legend>
                {{ pi["product_type_part_name"] }} - {{ pi["print_type_name"] }}
              </legend>

              <div class="d-flex mb-3">
                <div class="col-6">
                  <img
                    *ngIf="pi.product_image_url"
                    [src]="pi.product_image_url"
                    alt="Nema dizajn"
                    class="w-100 h-auto"
                  />
                  <p *ngIf="!pi.product_image_url">Nema dizajn</p>
                </div>
                <div style="font-size: 18px" class="col-11">
                  <p>Širina printa - {{ pi["print_area_width"] }} mm</p>
                  <p>
                    Udaljenost od kragne -
                    {{ pi["print_vertical_offset_mm"] }} mm
                  </p>
                </div>
              </div>
            </fieldset>
          </div>

          <hr />
        </main>

        <div class="d-flex w-100 p-2 justify-content-end">
          <dulo-button
            class="mr-2"
            label="Završi"
            [disabled]="oi.is_manufactured"
            (click)="finishOrderItem(oi)"
          ></dulo-button>
          <dulo-button
            label="Ponovi"
            (click)="repeatOrderItem(oi)"
          ></dulo-button>
        </div>
      </div>
      <div class="col-12" *ngIf="o['reclamation_item_list']">
        <fieldset>
          <legend>Info o reklamaciji</legend>
          <div class="row w-100" *ngFor="let ri of o['reclamation_item_list']">
            <div class="col-3">
              <p class="m-0">Opis - {{ ri["note"] }}</p>
            </div>
            <div class="col-9">
              <span
                *ngFor="let image of ri['reclamation_image_list']"
                style="width: 50px; height: 50px"
              >
                <img [src]="image" class="w-auto h-auto mw-100 mh-100" />
              </span>
            </div>
          </div>
        </fieldset>
      </div>
      <dulo-button
        class="w-100"
        label="Završi porudžbinu"
        [disabled]="
          !allOrderItemsManufactured(o) || o.state === 'READY_FOR_SHIPPING'
        "
        (click)="finishOrder(o, index)"
      ></dulo-button>
    </ng-container>
  </div>
</ng-template>
