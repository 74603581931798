/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/button/button.ngfactory";
import * as i3 from "primeng/components/button/button";
import * as i4 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Button_0, i2.RenderType_Button)), i1.ɵdid(2, 49152, null, 0, i3.Button, [], { type: [0, "type"], iconPos: [1, "iconPos"], icon: [2, "icon"], label: [3, "label"], disabled: [4, "disabled"], styleClass: [5, "styleClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.type; var currVal_2 = _co.iconPosition; var currVal_3 = _co.icon; var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.label, ""); var currVal_5 = _co.disabled; var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.styleClass, ""); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "dulo-button ", _co.styleClass, " ", _co.wrapperStyleClass, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i4.ButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonComponentNgFactory = i1.ɵccf("dulo-button", i4.ButtonComponent, View_ButtonComponent_Host_0, { debounceTime: "debounceTime", label: "label", name: "name", disabled: "disabled", icon: "icon", iconPosition: "iconPosition", styleClass: "styleClass", wrapperStyleClass: "wrapperStyleClass", type: "type" }, { onClick: "onClick" }, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
