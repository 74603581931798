import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SupplyService } from 'src/app/_services/supply.service';
import { TableColumn } from 'src/app/components/table/table-column';
import { map } from 'rxjs/operators';
import { ProductTypeService } from 'src/app/_services/product-type.service';
import { ColorService } from 'src/app/_services/color.service';

@Component({
  selector: 'app-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuppliesComponent {
  supplies$ = this.supplyService.getAll();

  cols: TableColumn[]  = [{
    header: 'Proizvod',
    field: 'product_type',
    filterType: 'select',
    type: 'alphanumeric',
    options: {
      rawValues: this.productTypeService.getAll().pipe(map((productTypes) => {
        return productTypes.map((pt) => {
          return {
            id: pt['name'],
            name: pt['name']
          };
        });
      })),
      hasNone: false
    }
  }, {
    header: 'Boja',
    field: 'product_color',
    filterType: 'select',
    type: 'alphanumeric',
    options: {
      rawValues: this.colorService.getAll().pipe(map((colors) => {
        return colors.map((c) => {
          return {
            id: c['name'],
            name: c['name']
          };
        });
      })),
      hasNone: false
    }
  }, {
    header: 'Veličina',
    field: 'product_size',
    filterType: 'select',
    type: 'alphanumeric',
    options: {
      values: [
        {value: 'XS', label: 'XS'},
        {value: 'S', label: 'S'},
        {value: 'M', label: 'M'},
        {value: 'L', label: 'L'},
        {value: 'XL', label: 'XL'},
        {value: 'XXL', label: 'XXL'},
        {value: 'XXXL', label: 'XXXL'}
      ]
    },
  }, {
    header: 'Količina na stanju',
    field: 'amount_on_stock',
    filterType: 'number',
    type: 'alphanumeric',
  }];
  constructor(private productTypeService: ProductTypeService,
              private supplyService: SupplyService,
              private colorService: ColorService) { }
}
