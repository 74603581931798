import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { OrderService } from 'src/app/_services/order.service';
import { PaginatedResult } from 'src/app/components/table/paginated-result';
import { Order } from 'src/app/_view-models/order';
import { SubSink } from 'subsink';
import { PopupService } from 'src/app/components/popup/popup.service';

@Component({
  selector: 'app-primer',
  templateUrl: './primer.component.html',
  styleUrls: ['./primer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimerComponent implements OnInit, OnDestroy {
  type = 'manufacturing';
  orderItems = [];
  // productImages = [];
  currentFilters = {
    first: 0,
    rows: 100,
    sortOrder: 1,
    sortField: '',
    filters: {}
  };
  subs = new SubSink();
  constructor(private orderService: OrderService,
              private popupService: PopupService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.subs.add(this.orderService.getAll(this.type, this.currentFilters).subscribe((res: PaginatedResult<Order>) => {
      if (res.current_results) {
        this.orderItems = [].concat.apply([], res.current_results.map(o => o.order_item_list));
        // this.productImages = [].concat.apply([], this.orderItems.map(o => o['product_images']));
        this.cdr.markForCheck();
      }
    }));
  }

  save(): void {
    this.subs.add(this.orderService.updatePrimers(this.orderItems.map(oi => {
      return {
        id: oi.id,
        primerUsed: oi['primer_used']
      };
    })).subscribe(res => {
      this.popupService.fire({
        type: 'success',
        title: 'Proizvodi označeni za prajmer!'
      });
    }, error => {
      this.popupService.fire({
        type: 'error',
        title: 'Došlo je do greške'
      });
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
