import { Observable, throwError, Subject } from "rxjs";
import { catchError, concatMap, tap, take, switchMap, finalize, } from "rxjs/operators";
import { SKIP_INTERCEPTORS, SKIP_JWT_RENEW_INTERCEPTOR, } from "./skip-interceptors";
import * as i0 from "@angular/core";
import * as i1 from "../_services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../_services/jwt.service";
export class JwtRenewInterceptor {
    constructor(authService, router, jwtService) {
        this.authService = authService;
        this.router = router;
        this.jwtService = jwtService;
        this.requestInProgress = false;
        this.tokenSubject = new Subject();
    }
    intercept(req, next) {
        if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
            return next.handle(req);
        }
        if (req.headers.get(SKIP_JWT_RENEW_INTERCEPTOR) === SKIP_JWT_RENEW_INTERCEPTOR) {
            return next.handle(req);
        }
        if (this.requestInProgress && req.headers.get("refresh") !== "refresh") {
            return this.tokenSubject.pipe(take(1), switchMap((token) => {
                return next.handle(req);
            }));
        }
        if (this.jwtService.isUserLoggedIn() &&
            this.jwtService.isTokenExpired() &&
            !this.requestInProgress) {
            this.requestInProgress = true;
            return this.authService
                .renewRefreshToken({
                access_token: this.jwtService.getAccessToken(),
                user_id: this.jwtService.getUserId(),
                refresh_token: this.jwtService.getRefreshToken(),
            })
                .pipe(concatMap((res) => {
                this.jwtService.saveRefreshToken(res.refresh_token);
                this.jwtService.saveAccessToken(res.access_token);
                this.tokenSubject.next(res.access_token);
                return next.handle(req).pipe(tap(() => {
                    this.tokenSubject = new Subject();
                }));
            }), catchError((err) => {
                this.jwtService.removeRefreshToken();
                this.jwtService.removeAccessToken();
                this.router.navigate(["/login"]);
                return throwError(err);
            }), finalize(() => {
                this.requestInProgress = false;
            }));
        }
        return next.handle(req);
    }
}
JwtRenewInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtRenewInterceptor_Factory() { return new JwtRenewInterceptor(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.JwtService)); }, token: JwtRenewInterceptor, providedIn: "root" });
