import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
} from '@angular/forms';
import { FormModel } from './form-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { forkJoin, isObservable, BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dulo-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() fields: FormModel[];
  @Input() orientation = 'horizontal';
  @Input() validators: any[];
  @Input() asyncValidators: any[];
  @Input() editMode: boolean = false;
  @Input() updatingObject: any;
  @Output() onSubmit = new EventEmitter<any>();

  FILE_SEPARATOR = '$@;#';

  form: FormGroup;
  controls: any;
  object = Object;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    let customRowsCount = 0;
    this.controls = this.populateControls(this.fields, customRowsCount);
    this.displayInitialForm();
    if (this.editMode) {
      this.form.patchValue(this.updatingObject);
      this.getFiles();
    }
  }

  ngAfterViewInit(): void {}

  populateControls(
    fields: FormModel[],
    customRowsCount: number,
    initValue: any = null,
  ): any {
    const controls = {};
    fields.forEach((f, index): any => {
      if (!f.column) {
        f.column = 1;
      } else {
        // customRowsCount++;
      }
      if (!f.row) {
        // f.row = index + 1 - customRowsCount;
        f.row = index + 1;
      }
      if (!f.colSpan) {
        f.colSpan = 1;
      }
      if (!f.rowSpan) {
        f.rowSpan = 1;
      }
      if (f.type === 'group') {
        controls[f.formControlName] = this.fb.group(
          this.populateControls(
            f.formControls,
            customRowsCount,
            initValue ? initValue[f.formControlName] : null,
          ),
          {
            validators: f.validators,
            asyncValidators: f.asyncValidators,
            updateOn: f.updateOn,
          },
        );
      } else if (f.type === 'array') {
        const formArray = [];
        if (f.options && f.options.initialValues) {
          f.options.initialValues.forEach((iv, index): void => {
            formArray.push(
              this.fb.group(
                this.populateControls(
                  this.isArray(f.arrayControls)
                    ? (f.arrayControls as FormModel[])
                    : (f.arrayControls[index] as FormModel[]),
                  customRowsCount,
                  iv,
                ),
                {
                  validators: f.validators,
                  asyncValidators: f.asyncValidators,
                  updateOn: f.updateOn,
                },
              ),
            );
          });
        }
        controls[f.formControlName] = this.fb.array(formArray, {
          validators: f.validators,
          asyncValidators: f.asyncValidators,
          updateOn: f.updateOn,
        });
      } else {
        controls[f.formControlName] = [
          {
            value:
              initValue && initValue[f.formControlName]
                ? initValue[f.formControlName]
                : f.initValue
                ? f.initValue
                : f.type === 'checkbox' || f.type === 'switch'
                ? false
                : '',
            disabled: f.disabled,
          },
          {
            validators: f.validators,
            asyncValidators: f.asyncValidators,
            updateOn: f.updateOn,
          },
        ];
      }
    });
    return controls;
  }

  displayInitialForm(): void {
    this.form = this.fb.group(this.controls, {
      validators: this.validators,
      asyncValidators: this.asyncValidators,
    });
  }

  getFormControl(fullFormControlName: string): AbstractControl {
    const words = fullFormControlName.split('.');
    let control = this.form.get(words[0]);
    for (let i = 1; i < words.length; i++) {
      if (!control) {
        return null;
      }
      control = control.get(words[i]);
    }
    return control;
  }

  getField(fullFormControlName: string): FormModel {
    const words = fullFormControlName.split('.');
    let field = this.fields.filter(
      (f): boolean => f.formControlName === words[0],
    )[0];

    for (let i = 1; i < words.length; i++) {
      if (field.formControls) {
        const groupField = field.formControls.filter(
          (f): boolean => f.formControlName === words[i],
        )[0];

        if (groupField) {
          field = groupField;
          continue;
        }
      }
      if (field.arrayControls) {
        let arrayField = null;
        if (this.isArray(field.arrayControls)) {
          arrayField = (field.arrayControls as FormModel[]).filter(
            (f): boolean => f.formControlName === words[i + 1],
          )[0];
          i++;
        } else {
          arrayField = (field.arrayControls[words[i]] as FormModel[]).filter(
            (f): boolean => f.formControlName === words[i + 1],
          )[0];
          i++;
        }
        if (arrayField) {
          field = arrayField;
          continue;
        }
      }

      field = null;
      break;
    }
    return field;
  }

  getValues(): any {
    return this.form.getRawValue();
  }

  hasFiles(): boolean {
    return this.hasFilesRecursive(this.fields);
  }

  hasFilesRecursive(fields: FormModel[]): boolean {
    if (!fields || fields.length === 0) return false;
    return (
      fields.filter(
        (f: FormModel): boolean =>
          f.type === 'file' ||
          (f.formControls && this.hasFilesRecursive(f.formControls)) ||
          (f.arrayControls &&
            this.isArray(f.arrayControls) &&
            this.hasFilesRecursive(f.arrayControls as FormModel[])) ||
          (f.arrayControls &&
            !this.isArray(f.arrayControls) &&
            Object.keys(f.arrayControls).reduce(
              (acc, current): boolean =>
                acc || this.hasFilesRecursive(f.arrayControls[current]),
              false,
            )),
      ).length > 0
    );
  }

  getFileFields(): string[] {
    return this.getFileFieldsRecursive(this.fields).map(
      (f: FormModel): string => f.formControlName,
    );
  }

  getFileFieldsRecursive(fields: FormModel[]): FormModel[] {
    if (!fields || fields.length === 0) return [];
    let fieldsArray = [];
    fields.forEach((f: FormModel): void => {
      if (f.type === 'file') fieldsArray.push(f);
      if (f.arrayControls) {
        if (this.isArray(f.arrayControls)) {
          const array = f.arrayControls as FormModel[];
          if (array.length > 0) {
            fieldsArray = fieldsArray.concat(
              this.getFileFieldsRecursive(array),
            );
          }
        } else {
          Object.keys(f.arrayControls).forEach((k): void => {
            const array = f.arrayControls[k] as FormModel[];
            if (array.length > 0) {
              fieldsArray = fieldsArray.concat(
                this.getFileFieldsRecursive(array),
              );
            }
          });
        }
      }
      if (f.formControls && f.formControls.length > 0) {
        fieldsArray = fieldsArray.concat(
          this.getFileFieldsRecursive(f.formControls),
        );
      }
    });
    return fieldsArray;
  }

  ngOnChanges(): void {
    if (this.form && this.updatingObject) {
      this.form.patchValue(this.updatingObject);
      this.getFiles();
    }
  }

  // // treba izmeniti(radi samo na root-u)
  getFiles(): void {
  //   const requests = [];
  //   this.fields.forEach((f): void => {
  //     if (f.type !== 'file') return;
  //     if (!this.updatingObject[f.formControlName]) return;
  //     const fileUrls = this.updatingObject[f.formControlName].split(
  //       this.FILE_SEPARATOR,
  //     );
  //     fileUrls.forEach((fu: string): void => {
  //       const req = this.http
  //         .get(this.fileServerUrlPipe.transform(fu), {
  //           responseType: 'blob',
  //           observe: 'response',
  //           headers: new HttpHeaders().set(SkipRouteUrlInterceptor, ''),
  //         })
  //         .pipe(
  //           map((res): { field: FormModel; file: File } => {
  //             const fileName = this.updatingObject[f.formControlName]
  //               .split('\\')
  //               .pop();
  //             const file = new File([res.body], fileName, {
  //               type: res.headers.get('content-type'),
  //             });
  //             if (this.isImage(file)) {
  //               (file as any).objectURL = this.sanitizer.bypassSecurityTrustUrl(
  //                 window.URL.createObjectURL(file),
  //               );
  //             }
  //             return {
  //               field: f,
  //               file: file,
  //             };
  //           }),
  //         );
  //       requests.push(req);
  //     });
  //   });
  //   forkJoin(requests).subscribe(
  //     (files: { field: FormModel; file: File }[]): void => {
  //       this.fields.forEach((f): void => {
  //         f.options.files = files
  //           .filter((fi): boolean => fi.field === f)
  //           .map((fi): File => fi.file);
  //       });
  //       this.fields = [...this.fields];
  //       this.rerender();
  //     },
  //   );
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  // ne moze da radi ako nisu sinhronizavni addFormModel i addArrayRow u slucaju da svaki red ima zasebnu definiciju form modela
  addArrayRow(formControlName: string, object: any = {}): void {
    const field = this.getField(formControlName);
    const array = (this.getFormControl(formControlName) as FormArray)['controls'];
    array[array.length] = this.fb.group(
      this.populateControls(
        this.isArray(field.arrayControls)
          ? (field.arrayControls as FormModel[])
          : (field.arrayControls[array.length] as FormModel[]),
        0,
        object,
      ),
      {
        validators: field.validators,
        asyncValidators: field.asyncValidators,
        updateOn: field.updateOn,
      },
    );
  }

  addArrayRowByFieldInstance(
    groupPrefix: string,
    field: FormModel,
    object: any = {},
  ): void {
    const array = (this.getFormControl(
      groupPrefix + '.' + field.formControlName,
    ) as FormArray).controls;
    array[array.length] = this.fb.group(
      this.populateControls(
        this.isArray(field.arrayControls)
          ? (field.arrayControls as FormModel[])
          : (field.arrayControls[array.length] as FormModel[]),
        0,
        object,
      ),
      {
        validators: field.validators,
        asyncValidators: field.asyncValidators,
        updateOn: field.updateOn,
      },
    );
  }

  addOptionValue(fullFormControlName: string, object: any): void {
    this.getField(fullFormControlName).options.values.push(object);
  }

  addOptionValueToArray(
    fullFormControlName: string,
    index: number,
    object: any,
  ): void {
    const field = this.getField(fullFormControlName);
    field.options.values[index].push(object);
  }

  initializeOptionValuesForArray(
    fullFormControlName: string,
    index: number,
  ): void {
    const field = this.getField(fullFormControlName);
    field.options.values[index] = [];
  }

  initializeOptionRawValuesForArray<T>(
    fullFormControlName: string,
    index: number,
  ): void {
    const field = this.getField(fullFormControlName);
    field.options.rawValues[index] = new BehaviorSubject<T>(null);
  }

  removeArrayRow(formControlName: string, index: number): void {
    const array = this.getFormControl(formControlName) as FormArray;
    array.removeAt(index);
  }

  removeArrayRowByFieldInstance(
    groupPrefix: string,
    field: FormModel,
    index: number,
  ): void {
    const array = this.getFormControl(
      groupPrefix + '.' + field.formControlName,
    ) as FormArray;
    array.removeAt(index);
  }

  removeOptionValue(fullFormControlName: string, index: number): void {
    this.getField(fullFormControlName).options.values.splice(index, 1);
  }

  removeOptionValueFromArray(
    fullFormControlName: string,
    arrayIndex: number,
    index: number,
  ): void {
    this.getField(fullFormControlName).options.values[arrayIndex].splice(
      index,
      1,
    );
  }

  fillValues(fullFormControlName: string, values: any[]): void {
    this.getField(fullFormControlName).options.rawValues.next(values);
  }

  fillValuesToArray(
    fullFormControlName: string,
    index: number,
    values: any[],
  ): void {
    const field = this.getField(fullFormControlName);
    field.options.rawValues[index].next(values);
  }

  addFormModel(field: FormModel, formControlName?: string): void {
    if (!field.row) {
      field.row = this.fields.length + 1;
    }
    const control = this.populateControls(
      [field],
      0,
      field.initValue,
    ) as FormControl;
    let formControl = null;
    if (field.type === 'array') {
      formControl = this.fb.array([control[field.formControlName]]);
    } else if (field.type === 'group') {
      formControl = this.fb.group(control[field.formControlName]);
    } else {
      formControl = this.fb.control(
        control[field.formControlName][0],
        control[field.formControlName][0][0],
        control[field.formControlName][0][1],
      );
    }
    if (formControlName) {
      const parentField = this.getField(formControlName);
      parentField.formControls.push(field);
      const parentFormControl = this.getFormControl(
        formControlName,
      ) as FormGroup;
      parentFormControl.addControl(field.formControlName, formControl);
    } else {
      this.form.addControl(field.formControlName, formControl);
      this.fields = [...this.fields, field];
    }
    this.rerender();
  }

  removeFormModel(formControlName: string): void {
    const field = this.getFormControl(formControlName);
    if (!field) {
      return;
    }
    const words = formControlName.split('.');
    (field.parent as FormGroup).removeControl(words[words.length - 1]);
    this.rerender();
  }

  isArray(values): boolean {
    return values instanceof Array || isObservable<any[]>(values);
  }

  rerender(): void {
    this.cdr.markForCheck();
  }
}
