import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { WorkOrderResponse } from "../_view-models/work-order-response";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class SupplyService {
  baseUrl = "partner/product_type_items";
  constructor(private http: HttpClient) {}

  countFreeOrderItems() {
    return this.http.get(`partner/work_orders/count_free_order_items`);
  }

  startWorkOrder(workOrderId: string) {
    return this.http.put(`partner/work_orders/${workOrderId}/start`, {});
  }

  finishWorkOrder(workOrderId: string) {
    return this.http.put(`partner/work_orders/${workOrderId}/finish`, {});
  }

  getWorkOrders(filters: any) {
    let params = this.generateFilters(filters);

    return this.http.get<WorkOrderResponse>(`partner/work_orders`, {
      params,
    });
  }

  modifyFilterDate(filterValue: any) {
    let modifiedDate: string;
    let finalDates: any[] = [];

    filterValue.forEach((date: Date) => {
      const dateType =
        filterValue.indexOf(date) === 0 ? "00-00-01" : "23-59-59";
      if (date) {
        modifiedDate = moment(date).format(`YYYY-MM-DD-${dateType}`);
      } else {
        modifiedDate = moment().format(`YYYY-MM-DD-${dateType}`);
      }
      finalDates.push(modifiedDate);
    });

    return finalDates;
  }

  generateFilters(filters: any) {
    let params = new HttpParams()
      .set("page", filters.first / filters.rows + "")
      .set("size", filters.rows + "")
      .set("sort_by", filters.sortField ? filters.sortField : "created")
      .set("sort_order", "desc");

    Object.entries(filters.filters).forEach(
      (filter: [string, { value: any; matchMode: string }]) => {
        const { 0: name, 1: filterInfo } = filter;

        switch (name) {
          case "id":
            filterInfo.matchMode = "eq";
            break;

          case "state":
            filterInfo.matchMode = "in";
            break;

          default:
            break;
        }

        if (filterInfo.matchMode === "dateBetween") {
          filterInfo.value = this.modifyFilterDate(filterInfo.value);
          filterInfo.matchMode = "btn";
        }

        params = params.set(
          name,
          `${filterInfo.matchMode}:${filterInfo.value}`
        );
      }
    );

    return params;
  }

  createWorkOrder(workOrderInfo: {
    workOrderCreationOption: string;
    value: string;
  }) {
    return this.http.post(`partner/work_orders`, workOrderInfo);
  }

  getAll(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}`);
  }

  getAllForTakeover(date: Date): Observable<any[]> {
    let mm = date.getMonth() + 1 + "";
    let dd = date.getDate() + "";
    if (mm.length === 1) {
      mm = "0" + mm;
    }
    if (dd.length === 1) {
      dd = "0" + dd;
    }
    const yy = date.getFullYear();
    return this.http.get<any[]>(`partner/orders/items_for_manufacturing`, {
      params: new HttpParams().set(
        "manufacturing_date",
        yy + "-" + mm + "-" + dd + "-00-00-00"
      ),
    });
  }

  update(id: number, amount: number): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}/amount`, { amount });
  }
}
