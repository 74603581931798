/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./orders-shipping.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/radio-button/radio-button.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../../components/radio-button/radio-button.component";
import * as i5 from "../../components/button/button.component.ngfactory";
import * as i6 from "../../components/button/button.component";
import * as i7 from "@angular/common";
import * as i8 from "../../components/table/table.component.ngfactory";
import * as i9 from "../../components/table/table.component";
import * as i10 from "@angular/common/http";
import * as i11 from "../../components/generators/excel-generator/excel-generator.service";
import * as i12 from "../../components/card/card.component.ngfactory";
import * as i13 from "../../components/card/card.component";
import * as i14 from "./orders-shipping.component";
import * as i15 from "../../_services/order.service";
import * as i16 from "../../_services/company.service";
import * as i17 from "../../components/popup/popup.service";
var styles_OrdersShippingComponent = [i0.styles];
var RenderType_OrdersShippingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrdersShippingComponent, data: {} });
export { RenderType_OrdersShippingComponent as RenderType_OrdersShippingComponent };
function View_OrdersShippingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-radio-button", [["name", "company"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.setCompanyId(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RadioButtonComponent_0, i2.RenderType_RadioButtonComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.RadioButtonComponent]), i1.ɵdid(2, 114688, null, 0, i4.RadioButtonComponent, [i1.ChangeDetectorRef], { name: [0, "name"], value: [1, "value"], label: [2, "label"], Selected: [3, "Selected"] }, { onClick: "onClick" })], function (_ck, _v) { var currVal_0 = "company"; var currVal_1 = _v.context.$implicit.id; var currVal_2 = _v.context.$implicit.name; var currVal_3 = _v.parent.parent.context.ngIf[0].id; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_OrdersShippingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "dulo-radio-button", [["class", "mr-2"], ["label", "Samo READY_FOR_SHIPING"], ["name", "type"], ["value", "ready_for_shipping"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.changeType("ready_for_shipping") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RadioButtonComponent_0, i2.RenderType_RadioButtonComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.RadioButtonComponent]), i1.ɵdid(4, 114688, null, 0, i4.RadioButtonComponent, [i1.ChangeDetectorRef], { name: [0, "name"], value: [1, "value"], label: [2, "label"], Selected: [3, "Selected"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "dulo-radio-button", [["label", "Svi"], ["name", "type"], ["value", "manufacturing-ready_for_shipping"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.changeType("manufacturing-ready_for_shipping") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RadioButtonComponent_0, i2.RenderType_RadioButtonComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.RadioButtonComponent]), i1.ɵdid(7, 114688, null, 0, i4.RadioButtonComponent, [i1.ChangeDetectorRef], { name: [0, "name"], value: [1, "value"], label: [2, "label"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "dulo-button", [["label", "Ozna\u010Di kao poslate"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markOrdersAsSent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(9, 114688, null, 0, i6.ButtonComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "dulo-button", [["label", "Skini excel za po\u0161tu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadShippingExcels() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(11, 114688, null, 0, i6.ButtonComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "my-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersShippingComponent_3)), i1.ɵdid(15, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "type"; var currVal_1 = "ready_for_shipping"; var currVal_2 = "Samo READY_FOR_SHIPING"; var currVal_3 = "ready_for_shipping"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "type"; var currVal_5 = "manufacturing-ready_for_shipping"; var currVal_6 = "Svi"; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = "Ozna\u010Di kao poslate"; _ck(_v, 9, 0, currVal_7); var currVal_8 = "Skini excel za po\u0161tu"; _ck(_v, 11, 0, currVal_8); var currVal_9 = _v.parent.context.ngIf; _ck(_v, 15, 0, currVal_9); }, null); }
function View_OrdersShippingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-table", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.setCurrentFilters($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_TableComponent_0, i8.RenderType_TableComponent)), i1.ɵdid(1, 245760, [[1, 4]], 0, i9.TableComponent, [i10.HttpClient, i11.ExcelGeneratorService, i1.ChangeDetectorRef], { rows: [0, "rows"], lazy: [1, "lazy"], request: [2, "request"], mapData: [3, "mapData"], columns: [4, "columns"], filterable: [5, "filterable"], sortable: [6, "sortable"], selectable: [7, "selectable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentFilters.rows; var currVal_1 = true; var currVal_2 = _co.orderRequest; var currVal_3 = _co.mapData; var currVal_4 = _co.cols; var currVal_5 = false; var currVal_6 = false; var currVal_7 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_OrdersShippingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid my-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "dulo-card", [], null, null, null, i12.View_CardComponent_0, i12.RenderType_CardComponent)), i1.ɵdid(2, 114688, null, 0, i13.CardComponent, [], { headerTemplate: [0, "headerTemplate"], mainTemplate: [1, "mainTemplate"] }, null), (_l()(), i1.ɵand(0, [["ordersHeaderTemplate", 2]], null, 0, null, View_OrdersShippingComponent_2)), (_l()(), i1.ɵand(0, [["ordersTemplate", 2]], null, 0, null, View_OrdersShippingComponent_4))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_OrdersShippingComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit["delivery_info"]["delivery_date"], "dd.MM.yyyy.")); _ck(_v, 1, 0, currVal_0); }); }
function View_OrdersShippingComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersShippingComponent_6)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit["delivery_info"]["delivery_date"]; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fulfillmentTypeTranslation[_v.context.$implicit["delivery_info"]["fulfillment_type"]]; _ck(_v, 2, 0, currVal_0); }); }
export function View_OrdersShippingComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { table: 0 }), i1.ɵqud(402653184, 2, { deliveryInfoTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_OrdersShippingComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [[2, 2], ["deliveryInfoTemplate", 2]], null, 0, null, View_OrdersShippingComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.companies$)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_OrdersShippingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-orders-shipping", [], null, null, null, View_OrdersShippingComponent_0, RenderType_OrdersShippingComponent)), i1.ɵdid(1, 245760, null, 0, i14.OrdersShippingComponent, [i15.OrderService, i16.CompanyService, i17.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrdersShippingComponentNgFactory = i1.ɵccf("app-orders-shipping", i14.OrdersShippingComponent, View_OrdersShippingComponent_Host_0, {}, {}, []);
export { OrdersShippingComponentNgFactory as OrdersShippingComponentNgFactory };
