import { NgModule } from '@angular/core';
import { TextareaComponent } from './textarea.component';
import { SharedModule } from '../shared.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [TextareaComponent],
  imports: [SharedModule, FormsModule, InputTextareaModule],
  exports: [TextareaComponent],
})
export class TextareaModule {}
