import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Type,
  ViewChild,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'dulo-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<FileUploadComponent> => FileUploadComponent,
      ),
      multi: true,
    },
  ],
})
export class FileUploadComponent
  implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() url: string;
  @Input() name: string;
  @Input() method: string = 'POST';
  @Input() accept: string;
  @Input() multiple: boolean = false;
  @Input() formUpload: boolean = true;
  @Input() auto: boolean = false;
  @Input() disabled: boolean = false;
  @Input() maxFileSize: number;
  @Input() fileLimit: number;
  @Input() showFiles: boolean = true;
  @Output() onUpload = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();
  @Output() onProgress = new EventEmitter<any>();
  files: [] = [];
  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  get Files(): [] {
    return this.files;
  }

  @Input('files') set Files(files: []) {
    this.files = files ? files : [];
    this.cdr.detectChanges();
    this.writeValue();
  }

  @ViewChild(FileUpload, { static: true }) fileUpload: FileUpload;
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.showFiles) {
      this.elRef.nativeElement.querySelectorAll(
        '.ui-fileupload-content',
      )[0].style.display = 'none';
      const buttonBar = this.elRef.nativeElement.querySelectorAll(
        '.ui-fileupload-buttonbar',
      )[0];
      buttonBar.style.border = 'none';
      buttonBar.style.background = 'unset';
      const choose = this.elRef.nativeElement.querySelectorAll(
        '.ui-fileupload-choose',
      )[0];
      choose.style.marginRight = 0;
      choose.style.width = '100%';
    }
  }

  select(event): void {
    this.writeValue();
    this.onSelect.emit(event);
  }

  clear(event): void {
    this.writeValue();
    this.onClear.emit(event);
  }

  remove(event): void {
    this.files.splice(event.file, 1);
    this.fileUpload.files.splice(event.file, 1);
    this.cdr.detectChanges();
    this.writeValue();
    this.onRemove.emit(event);
  }

  writeValue(): void {
    this.onModelChange(this.fileUpload.files);
    this.onModelTouched();
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
