import { Injectable } from "@angular/core";
import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

@Injectable({ providedIn: "root" })
export class PopupService {
  fire(settings: PopupOptions): Promise<PopupAlertResult> {
    return "customClass" in settings
      ? Swal.fire(settings)
      : Swal.fire({
          ...settings,
          cancelButtonText: "Otkaži",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn btn-small btn-submit popup-confirm-button",
            cancelButton: "btn btn-small btn-cancel popup-cancel-button",
            icon: "swal-icon",
            title: "swal-title",
            popup: "popup",
            content: "swal-content",
            actions: "swal-actions",
          },
        });
  }

  fireToast(settings: PopupOptions): Promise<PopupAlertResult> {
    return "customClass" in settings
      ? Swal.fire(settings)
      : Swal.fire({
          toast: true,
          position: "top-end",
          timer: 2000,
          ...settings,
          customClass: {
            confirmButton: "btn btn-medium btn-submit popup-confirm-button",
            cancelButton: "btn btn-outline-primary popup-cancel-button",
            icon: "popup-icon",
            title: "popup-title",
            popup: "popup",
            content: "popup-content",
            actions: "popup-actions",
          },
        });
  }

  fireYesNo(
    yesText: string,
    noText: string,
    settings: PopupOptions
  ): Promise<PopupAlertResult> {
    return "customClass" in settings
      ? Swal.fire(settings)
      : Swal.fire({
          showCancelButton: true,
          cancelButtonText: noText,
          confirmButtonText: yesText,
          focusCancel: true,
          ...settings,
          customClass: {
            confirmButton: "btn btn-medium btn-submit popup-confirm-button",
            cancelButton: "btn btn-outline-primary popup-cancel-button",
            icon: "popup-icon",
            title: "popup-title",
            popup: "popup",
            content: "popup-content",
            actions: "popup-actions",
          },
        });
  }
}

export enum MessageType {
  POST,
  PUT,
  DELETE,
  ERROR_GET,
  ERROR_POST,
  ERROR_PUT,
  ERROR_DELETE,
  CONFIRM_DELETE,
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PopupOptions extends SweetAlertOptions {
  template?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PopupAlertResult extends SweetAlertResult {}
