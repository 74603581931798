import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  Type,
  ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'dulo-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<TextareaComponent> => TextareaComponent,
      ),
      multi: true,
    },
  ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
  @Input() rows: string = 'auto';
  @Input() cols: string = 'auto';
  value: string;
  @Input() autoResize: boolean = false;
  @Input() disabled: boolean = false;
  @Input() floatablePlaceholder: boolean = true;
  @Input() placeholder: string;
  @Input() styleClass: string;
  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  get Value(): string {
    return this.value;
  }

  @Input() set Value(value: string) {
    this.value = value;
    this.onModelChange(value);
    this.onModelTouched();
    this.cdr.markForCheck();
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  writeValue(value: string): void {
    this.Value = value;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
