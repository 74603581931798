/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/button/button.component.ngfactory";
import * as i3 from "../../components/button/button.component";
import * as i4 from "@angular/common";
import * as i5 from "../../components/switch/switch.component.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "../../components/switch/switch.component";
import * as i8 from "../../components/card/card.component.ngfactory";
import * as i9 from "../../components/card/card.component";
import * as i10 from "../../components/textarea/textarea.component.ngfactory";
import * as i11 from "../../components/textarea/textarea.component";
import * as i12 from "../../components/tooltip/tooltip.directive";
import * as i13 from "./order.component";
import * as i14 from "../../_services/order.service";
import * as i15 from "../../components/popup/popup.service";
var styles_OrderComponent = [i0.styles];
var RenderType_OrderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderComponent, data: {} });
export { RenderType_OrderComponent as RenderType_OrderComponent };
function View_OrderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-button", [["class", "my-2"], ["label", "Preuzmi sliku"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadCanvasPhoto(_v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Preuzmi sliku"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OrderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "w-100 h-auto my-2"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "d-flex justify-content-center align-items-center flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderComponent_5)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "dulo-switch", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.toggleFinish(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SwitchComponent_0, i5.RenderType_SwitchComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.SwitchComponent]), i1.ɵdid(6, 114688, null, 0, i7.SwitchComponent, [i1.ChangeDetectorRef], { disabled: [0, "disabled"], label: [1, "label"], Checked: [2, "Checked"] }, { onChange: "onChange" })], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.canvas_image_url; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_v.parent.parent.context.$implicit.canvas_image_url; var currVal_3 = (_v.parent.parent.context.$implicit.finished ? "Zavrseno" : "Nezavrseno"); var currVal_4 = _v.parent.parent.context.$implicit.finished; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.product_image_url; _ck(_v, 0, 0, currVal_0); }); }
function View_OrderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.product_type_part_name; _ck(_v, 1, 0, currVal_0); }); }
function View_OrderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-4 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "dulo-card", [], null, null, null, i8.View_CardComponent_0, i8.RenderType_CardComponent)), i1.ɵdid(2, 114688, [["card", 4]], 0, i9.CardComponent, [], { headerTemplate: [0, "headerTemplate"], mainTemplate: [1, "mainTemplate"], styleClass: [2, "styleClass"] }, null), (_l()(), i1.ɵand(0, [["piTemplate", 2]], null, 0, null, View_OrderComponent_4)), (_l()(), i1.ɵand(0, [["piHeaderTemplate", 2]], null, 0, null, View_OrderComponent_6))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4); var currVal_1 = i1.ɵnov(_v, 3); var currVal_2 = (_v.parent.context.$implicit.finished ? "bg-success" : "bg-danger"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_OrderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.canvas_image_url; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OrderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orderItem.product_images; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OrderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-center mt-4 w-100"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ne treba ni\u0161ta da se \u0161tampa"]))], null, null); }
export function View_OrderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Proizvod: "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Boja: "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Velicina: "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "col-10 row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderComponent_1)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderComponent_7)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 9, "div", [["class", "col-2 d-flex flex-column justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "dulo-button", [["label", "Ponovi izradu"], ["styleClass", "w-100 bg-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.repeatManufacturing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(21, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"], styleClass: [1, "styleClass"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "dulo-textarea", [["styleClass", "w-100"]], null, null, null, i10.View_TextareaComponent_0, i10.RenderType_TextareaComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i11.TextareaComponent]), i1.ɵdid(25, 114688, null, 0, i11.TextareaComponent, [i1.ChangeDetectorRef], { placeholder: [0, "placeholder"], styleClass: [1, "styleClass"], Value: [2, "Value"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 2, "dulo-button", [["label", "Zavr\u0161i"], ["styleClass", "w-100"], ["tooltipPosition", "left"]], [[1, "pTooltip", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.finishOrderItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(27, 4276224, null, 0, i12.TooltipDirective, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], disabled: [1, "disabled"], text: [2, "text"] }, null), i1.ɵdid(28, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"], disabled: [1, "disabled"], styleClass: [2, "styleClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.orderItem.hasDesign; _ck(_v, 16, 0, currVal_3); var currVal_4 = !_co.orderItem.hasDesign; _ck(_v, 18, 0, currVal_4); var currVal_5 = "Ponovi izradu"; var currVal_6 = "w-100 bg-secondary"; _ck(_v, 21, 0, currVal_5, currVal_6); var currVal_7 = "Komentar"; var currVal_8 = "w-100"; var currVal_9 = _co.comment; _ck(_v, 25, 0, currVal_7, currVal_8, currVal_9); var currVal_11 = "left"; var currVal_12 = !_co.areAllOrderItemsFinished(); var currVal_13 = "Morate zavr\u0161iti sa izradom svih delova"; _ck(_v, 27, 0, currVal_11, currVal_12, currVal_13); var currVal_14 = "Zavr\u0161i"; var currVal_15 = _co.areAllOrderItemsFinished(); var currVal_16 = "w-100"; _ck(_v, 28, 0, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orderItem.product_type_name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.orderItem.product_color; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.orderItem.product_size; _ck(_v, 12, 0, currVal_2); var currVal_10 = i1.ɵnov(_v, 27).tooltip; _ck(_v, 26, 0, currVal_10); }); }
export function View_OrderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order", [], null, null, null, View_OrderComponent_0, RenderType_OrderComponent)), i1.ɵdid(1, 245760, null, 0, i13.OrderComponent, [i14.OrderService, i15.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderComponentNgFactory = i1.ɵccf("app-order", i13.OrderComponent, View_OrderComponent_Host_0, { dialog: "dialog" }, {}, []);
export { OrderComponentNgFactory as OrderComponentNgFactory };
