import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CompanyService {
    constructor(http) {
        this.http = http;
        this.baseUrl = 'partner/companies';
    }
    getAll() {
        return this.http.get(`${this.baseUrl}`);
    }
}
CompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.ɵɵinject(i1.HttpClient)); }, token: CompanyService, providedIn: "root" });
