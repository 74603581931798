/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "primeng/components/keyfilter/keyfilter";
import * as i4 from "@angular/common";
import * as i5 from "./input.component";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
function View_InputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [["class", "ui-float-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "input", [["id", "float-input"], ["pInputText", ""], ["type", "text"]], [[8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "change"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "keypress"], [null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (i1.ɵnov(_v, 3).onKeyPress($event) !== false);
        ad = (pd_5 && ad);
    } if (("paste" === en)) {
        var pd_6 = (i1.ɵnov(_v, 3).onPaste($event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.Value = $event) !== false);
        ad = (pd_7 && ad);
    } if (("input" === en)) {
        var pd_8 = (_co.onInput.emit($event) !== false);
        ad = (pd_8 && ad);
    } if (("change" === en)) {
        var pd_9 = (_co.onChange.emit($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i3.KeyFilter, [i1.ElementRef], { pValidateOnly: [0, "pValidateOnly"], pattern: [1, "pattern"] }, { ngModelChange: "ngModelChange" }), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.KeyFilter]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [["for", "float-input"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.enableTypingIfErrors; var currVal_9 = _co.regex; _ck(_v, 3, 0, currVal_8, currVal_9); var currVal_10 = _co.disabled; var currVal_11 = _co.Value; _ck(_v, 6, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "form-control ", (_co.Value ? "ui-state-filled" : ""), " ", _co.styleClass, ""); var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_12 = _co.placeholder; _ck(_v, 10, 0, currVal_12); }); }
function View_InputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "input", [["pInputText", ""], ["type", "text"]], [[8, "className", 0], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "change"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "keypress"], [null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (i1.ɵnov(_v, 2).onKeyPress($event) !== false);
        ad = (pd_5 && ad);
    } if (("paste" === en)) {
        var pd_6 = (i1.ɵnov(_v, 2).onPaste($event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.Value = $event) !== false);
        ad = (pd_7 && ad);
    } if (("input" === en)) {
        var pd_8 = (_co.onInput.emit($event) !== false);
        ad = (pd_8 && ad);
    } if (("change" === en)) {
        var pd_9 = (_co.onChange.emit($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(2, 16384, null, 0, i3.KeyFilter, [i1.ElementRef], { pValidateOnly: [0, "pValidateOnly"], pattern: [1, "pattern"] }, { ngModelChange: "ngModelChange" }), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.KeyFilter]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.enableTypingIfErrors; var currVal_10 = _co.regex; _ck(_v, 2, 0, currVal_9, currVal_10); var currVal_11 = _co.disabled; var currVal_12 = _co.Value; _ck(_v, 5, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "form-control ", _co.styleClass, ""); var currVal_1 = _co.placeholder; var currVal_2 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 7).ngClassValid; var currVal_7 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_InputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_InputComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_1)), i1.ɵdid(2, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_4)), i1.ɵdid(8, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preTemplate; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.floatablePlaceholder; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.floatablePlaceholder; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.postTemplate; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.InputComponent]), i1.ɵdid(2, 114688, null, 0, i5.InputComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputComponentNgFactory = i1.ɵccf("dulo-input", i5.InputComponent, View_InputComponent_Host_0, { placeholder: "placeholder", floatablePlaceholder: "floatablePlaceholder", preTemplate: "preTemplate", postTemplate: "postTemplate", disabled: "disabled", styleClass: "styleClass", regex: "regex", enableTypingIfErrors: "enableTypingIfErrors", Value: "Value" }, { onInput: "onInput", onChange: "onChange" }, []);
export { InputComponentNgFactory as InputComponentNgFactory };
