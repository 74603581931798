<div class='container-fluid my-5'>
    <div class='row'>
        <dulo-card [mainTemplate]='orderItemTemplate' *ngFor='let oi of orderItems' class='col-2 p-2'>
            <ng-template #orderItemTemplate>
                <img [src]="oi.product_images[0].product_image_url" alt="Nema dizajn" class='w-100 h-auto'>
                <p class='text-center'>Proizvod - {{ oi['product_type_name'] }}</p>
                <p class='text-center'>Veličina - {{ oi['product_size'] }}</p>
                <p class='text-center'>Boja - {{ oi['product_color'] }}</p>
                <div class='w-100 my-2 d-flex justify-content-center'>
                    <dulo-switch [Checked]='oi.primer_used' [label]='oi.primer_used ? "Sa prajmerom" : "Bez prajmera"' (onChange)='oi.primer_used = !oi.primer_used'></dulo-switch>
                </div>
            </ng-template>
        </dulo-card>
    </div>
    <div class='row d-flex justify-content-center mt-4'>
        <dulo-button label='Sačuvaj' (click)='save()'></dulo-button>
    </div>
</div>
