import { NgModule } from '@angular/core';
import { FileUploadComponent } from './file-upload.component';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [SharedModule, PrimeNgFileUploadModule],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
