<nav
  class="navbar navbar-expand-sm navbar-light bg-light"
  style="height: 100px"
>
  <div class="h-100 d-flex align-items-baseline mt-2">
    <img src="assets/images/logo.png" alt="Logo" class="h-75 w-auto" />
    <img
      class="h-50 w-auto ml-2"
      src="assets/images/{{ countryCode === 'rs' ? 'serbia' : 'croatia' }}.png"
      alt=""
    />
  </div>
  <button
    #hamburger
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarAuth"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse bg-light px-3"
    id="navbarAuth"
    *ngIf="loggedIn$ | async"
  >
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" routerLinkActive="active">
        <a
          class="nav-link"
          routerLink="/supplies-takeover"
          (click)="closeHamburgerIfOpen(hamburger)"
          >Radni nalozi</a
        >
      </li>

      <li
        *ngIf="countryCode !== 'rs' && (isFactory$ | async)"
        class="nav-item"
        routerLinkActive="active"
      >
        <a
          class="nav-link"
          routerLink="/export-orders"
          (click)="closeHamburgerIfOpen(hamburger)"
          >Izvozni nalozi</a
        >
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a
          class="nav-link"
          routerLink="/orders/manufacturing-mobile"
          (click)="closeHamburgerIfOpen(hamburger)"
          >Porudžbine u obradi</a
        >
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a
          class="nav-link"
          routerLink="/orders/shipping"
          (click)="closeHamburgerIfOpen(hamburger)"
          >Čekaju slanje</a
        >
      </li>
      <li
        *ngIf="isManufacturedProductsWarehouse$ | async"
        class="nav-item"
        routerLinkActive="active"
      >
        <a
          class="nav-link"
          routerLink="/orders/manufactured-products"
          (click)="closeHamburgerIfOpen(hamburger)"
          >Vraćeni proizvodi</a
        >
      </li>
      <li *ngIf="isFactory$ | async" class="nav-item" routerLinkActive="active">
        <a
          class="nav-link"
          routerLink="/item-manufacturing-info"
          (click)="closeHamburgerIfOpen(hamburger)"
          >Stanje porudžbina</a
        >
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link cursor-pointer" authLogout (onLogout)="onLogout()"
          >Logout</a
        >
      </li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>
<dulo-loader></dulo-loader>
