import { Directive, HostListener, EventEmitter, Output } from "@angular/core";
import { AuthService } from "../_services/auth.service";
import { JwtService } from "../_services/jwt.service";

@Directive({
  selector: "[authLogout]",
})
export class LogoutDirective {
  @Output() onLogout = new EventEmitter<any>();
  constructor(
    private authService: AuthService,
    private jwtService: JwtService
  ) {}

  @HostListener("click") logout(): void {
    this.authService.logOut().subscribe((): void => {});

    this.jwtService.removeRefreshToken();
    this.jwtService.removeAccessToken();
    this.onLogout.emit();
  }
}
