/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./orders-manufacturing-mobile.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/button/button.component.ngfactory";
import * as i3 from "../../components/button/button.component";
import * as i4 from "@angular/common";
import * as i5 from "../../components/select/select.component.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "../../components/select/select.component";
import * as i8 from "../../components/select/resolve-select.pipe";
import * as i9 from "../../components/accordion/accordion.component.ngfactory";
import * as i10 from "../../components/accordion/accordion.component";
import * as i11 from "../../components/paginator/paginator.component.ngfactory";
import * as i12 from "../../components/paginator/paginator.component";
import * as i13 from "./orders-manufacturing-mobile.component";
import * as i14 from "../../_services/order.service";
import * as i15 from "../../components/popup/popup.service";
var styles_OrdersManufacturingMobileComponent = [i0.styles];
var RenderType_OrdersManufacturingMobileComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_OrdersManufacturingMobileComponent, data: {} });
export { RenderType_OrdersManufacturingMobileComponent as RenderType_OrdersManufacturingMobileComponent };
function View_OrdersManufacturingMobileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-button", [["class", "ml-2"], ["label", "Preuzmi adresnicu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getShippingLabel(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Preuzmi adresnicu"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OrdersManufacturingMobileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Id gotovog proizvoda - ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit["from_manufactured_product_id"]; _ck(_v, 1, 0, currVal_0); }); }
function View_OrdersManufacturingMobileComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Nema dizajn"], ["class", "w-100 h-auto"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.product_image_url; _ck(_v, 0, 0, currVal_0); }); }
function View_OrdersManufacturingMobileComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nema dizajn"]))], null, null); }
function View_OrdersManufacturingMobileComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " - ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "d-flex mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_8)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "col-11"], ["style", "font-size: 18px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["\u0160irina printa - ", " mm"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" Udaljenost od kragne - ", " mm "]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.product_image_url; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_v.context.$implicit.product_image_url; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit["product_type_part_name"]; var currVal_1 = _v.context.$implicit["print_type_name"]; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit["print_area_width"]; _ck(_v, 12, 0, currVal_4); var currVal_5 = _v.context.$implicit["print_vertical_offset_mm"]; _ck(_v, 14, 0, currVal_5); }); }
function View_OrdersManufacturingMobileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["style", "border: 6px solid black"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "main", [["class", "d-flex flex-column p-2 w-75"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "mb-2"], ["style", "font-size: 18px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Proizvod - ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Veli\u010Dina - ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["Boja - ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["ID priprema: ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_5)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_6)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "d-flex w-100 p-2 justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "dulo-button", [["class", "mr-2"], ["label", "Zavr\u0161i"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.finishOrderItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(19, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "dulo-button", [["label", "Ponovi"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.repeatOrderItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(21, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_5 = ((_v.context.$implicit["from_manufactured_product_id"] !== null) && (_v.context.$implicit["from_manufactured_product_id"] !== 0)); _ck(_v, 12, 0, currVal_5); var currVal_6 = _v.context.$implicit.product_images; _ck(_v, 15, 0, currVal_6); var currVal_7 = "Zavr\u0161i"; var currVal_8 = _v.context.$implicit.is_manufactured; _ck(_v, 19, 0, currVal_7, currVal_8); var currVal_9 = "Ponovi"; _ck(_v, 21, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "row ", _v.context.$implicit.meta.rowClass, " my-4 main-color"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit["product_type_name"]; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit["product_size"]; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit["product_color"]; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit["id"]; _ck(_v, 10, 0, currVal_4); }); }
function View_OrdersManufacturingMobileComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "width: 50px; height: 50px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "w-auto h-auto mw-100 mh-100"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_OrdersManufacturingMobileComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Opis - ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-9"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_11)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit["reclamation_image_list"]; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit["note"]; _ck(_v, 3, 0, currVal_0); }); }
function View_OrdersManufacturingMobileComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Info o reklamaciji"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_10)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit["reclamation_item_list"]; _ck(_v, 5, 0, currVal_0); }, null); }
function View_OrdersManufacturingMobileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_9)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "dulo-button", [["class", "w-100"], ["label", "Zavr\u0161i porud\u017Ebinu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.finishOrder(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(6, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"], disabled: [1, "disabled"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.order_item_list; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit["reclamation_item_list"]; _ck(_v, 4, 0, currVal_1); var currVal_2 = "Zavr\u0161i porud\u017Ebinu"; var currVal_3 = (!_co.allOrderItemsManufactured(_v.parent.context.$implicit) || (_v.parent.context.$implicit.state === "READY_FOR_SHIPPING")); _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
function View_OrdersManufacturingMobileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dulo-button", [["class", "w-100"], ["label", "Z\u0430po\u010Dni porud\u017Ebinu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startOrder(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(2, 114688, null, 0, i3.ButtonComponent, [], { label: [0, "label"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingMobileComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "Z\u0430po\u010Dni porud\u017Ebinu"; var currVal_1 = (_v.context.$implicit.state !== "ACCEPTED"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.shipping_carrier_dispatch_in_advance; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_v.context.$implicit.state !== "ACCEPTED"); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_OrdersManufacturingMobileComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { accordionComponent: 0 }), i1.ɵqud(402653184, 2, { workOrderSelect: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "container active-orders mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "dulo-select", [["optionLabel", "label"], ["placeholder", "Odaberi radni nalog"]], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.getOrders() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SelectComponent_0, i5.RenderType_SelectComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.SelectComponent]), i1.ɵdid(5, 770048, [[2, 4], ["workOrderSelect", 4]], 0, i7.SelectComponent, [i8.ResolveSelectPipe, i1.ChangeDetectorRef], { optionLabel: [0, "optionLabel"], placeholder: [1, "placeholder"], multiple: [2, "multiple"], values: [3, "values"], SelectedItem: [4, "SelectedItem"] }, { onChange: "onChange" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "dulo-accordion", [], null, null, null, i9.View_AccordionComponent_0, i9.RenderType_AccordionComponent)), i1.ɵdid(9, 49152, [[1, 4]], 0, i10.AccordionComponent, [i1.ChangeDetectorRef], { template: [0, "template"], tabs: [1, "tabs"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "dulo-paginator", [["class", "mt-3"]], null, [[null, "pageChangeHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChangeHandler" === en)) {
        var pd_0 = (_co.changePage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_PaginatorComponent_0, i11.RenderType_PaginatorComponent)), i1.ɵdid(12, 114688, null, 0, i12.PaginatorComponent, [], { totalRecords: [0, "totalRecords"], pageSize: [1, "pageSize"], page: [2, "page"] }, { pageChangeHandler: "pageChangeHandler" }), (_l()(), i1.ɵand(0, [["orderTemplate", 2]], null, 0, null, View_OrdersManufacturingMobileComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "label"; var currVal_1 = "Odaberi radni nalog"; var currVal_2 = false; var currVal_3 = i1.ɵunv(_v, 5, 3, i1.ɵnov(_v, 6).transform(_co.activeWorkOrders$)); var currVal_4 = _co.selectedWorkOrder; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 13); var currVal_6 = i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 10).transform(_co.orders$)); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = _co.totalCount; var currVal_8 = _co.currentFilters.rows; var currVal_9 = _co.currentFilters.first; _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9); }, null); }
export function View_OrdersManufacturingMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-orders-manufacturing-mobile", [], null, null, null, View_OrdersManufacturingMobileComponent_0, RenderType_OrdersManufacturingMobileComponent)), i1.ɵdid(1, 114688, null, 0, i13.OrdersManufacturingMobileComponent, [i14.OrderService, i15.PopupService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrdersManufacturingMobileComponentNgFactory = i1.ɵccf("app-orders-manufacturing-mobile", i13.OrdersManufacturingMobileComponent, View_OrdersManufacturingMobileComponent_Host_0, {}, {}, []);
export { OrdersManufacturingMobileComponentNgFactory as OrdersManufacturingMobileComponentNgFactory };
