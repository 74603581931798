import {
  Component,
  Input,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'dulo-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AccordionComponent {
  @Input() activeIndex: number;
  @Input() template: TemplateRef<any>;
  @Input() tabs: {
    name: string,
    data: any,
    meta: any
  }[];
  tabsMap: {[key: string]: TemplateRef<any>};
  keys: string[];

  get TabsMap(): any {
    return this.tabsMap;
  }

  @Input() set TabsMap(data: any) {
    this.tabsMap = data;
    this.keys = Object.keys(this.tabsMap);
  }

  constructor(public cdr: ChangeDetectorRef) {}
}
