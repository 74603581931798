/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/card/card.ngfactory";
import * as i3 from "primeng/components/card/card";
import * as i4 from "../../../../node_modules/primeng/components/common/shared.ngfactory";
import * as i5 from "primeng/components/common/shared";
import * as i6 from "@angular/common";
import * as i7 from "./card.component";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0, null, View_CardComponent_3))], null, null); }
function View_CardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0, null, View_CardComponent_5))], null, null); }
function View_CardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0, null, View_CardComponent_7))], null, null); }
function View_CardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "p-card", [], null, null, null, i2.View_Card_0, i2.RenderType_Card)), i1.ɵdid(2, 49152, null, 2, i3.Card, [i1.ElementRef], { styleClass: [0, "styleClass"] }, null), i1.ɵqud(603979776, 1, { headerFacet: 0 }), i1.ɵqud(603979776, 2, { footerFacet: 0 }), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "p-header", [], null, null, null, i4.View_Header_0, i4.RenderType_Header)), i1.ɵdid(6, 49152, [[1, 4]], 0, i5.Header, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CardComponent_2)), i1.ɵdid(8, 540672, null, 0, i6.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_CardComponent_4)), i1.ɵdid(10, 540672, null, 0, i6.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(11, 0, null, 2, 3, "p-footer", [], null, null, null, i4.View_Footer_0, i4.RenderType_Footer)), i1.ɵdid(12, 49152, [[2, 4]], 0, i5.Footer, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CardComponent_6)), i1.ɵdid(14, 540672, null, 0, i6.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.styleClass; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.headerTemplate; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.mainTemplate; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.footerTemplate; _ck(_v, 14, 0, currVal_3); }, null); }
function View_CardComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CardComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0, null, View_CardComponent_10))], null, null); }
function View_CardComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "p-card", [], null, null, null, i2.View_Card_0, i2.RenderType_Card)), i1.ɵdid(2, 49152, null, 2, i3.Card, [i1.ElementRef], { header: [0, "header"], subheader: [1, "subheader"], styleClass: [2, "styleClass"] }, null), i1.ɵqud(603979776, 3, { headerFacet: 0 }), i1.ɵqud(603979776, 4, { footerFacet: 0 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_CardComponent_9)), i1.ɵdid(6, 540672, null, 0, i6.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; var currVal_1 = _co.subheader; var currVal_2 = _co.styleClass; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.mainTemplate; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_CardComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "dulo-card"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_8)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasTemplates; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.hasTemplates; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i7.CardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardComponentNgFactory = i1.ɵccf("dulo-card", i7.CardComponent, View_CardComponent_Host_0, { header: "header", subheader: "subheader", headerTemplate: "headerTemplate", mainTemplate: "mainTemplate", footerTemplate: "footerTemplate", styleClass: "styleClass" }, {}, []);
export { CardComponentNgFactory as CardComponentNgFactory };
