import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule as PrimeNgTableModule } from 'primeng/table';
import { TableComponent } from './table.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { NumberRangeInputModule } from '../number-range-input/number-range-input.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared.module';
import { ButtonModule } from '../button/button.module';
import { InputModule } from '../input/input.module';
import { SelectModule } from '../select/select.module';

@NgModule({
  declarations: [TableComponent],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    PrimeNgTableModule,
    DatePickerModule,
    NumberRangeInputModule,
    CheckboxModule,
    ButtonModule,
    InputModule,
    SelectModule,
    HttpClientModule,
  ],
  exports: [TableComponent],
})
export class TableModule {}
