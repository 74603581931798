import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { SKIP_ERROR_HANDLING_INTERCEPTORS } from "./skip-interceptors";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/auth.service";
import * as i3 from "../components/popup/popup.service";
export class HttpMessageInterceptor {
    constructor(router, authService, popupService) {
        this.router = router;
        this.authService = authService;
        this.popupService = popupService;
    }
    intercept(req, next) {
        if (req.headers.get(SKIP_ERROR_HANDLING_INTERCEPTORS) ===
            SKIP_ERROR_HANDLING_INTERCEPTORS) {
            return next.handle(req);
        }
        return next.handle(req).pipe(catchError((err) => {
            if (Math.floor(err.status / 100) === 5) {
                this.title = "Doslo je do greške na serveru";
            }
            else if (err.status === 401) {
                this.title = "Nemate prava da vidite podatke";
            }
            else if (err.status === 403) {
                this.title = "Morate se ulogovati ponovo";
                this.authService.logOut();
                this.router.navigate(["/"]);
            }
            else if (Math.floor(err.status / 100) === 4) {
                return throwError(err);
            }
            this.popupService.fire({
                type: "error",
                titleText: this.title,
                text: this.text,
            });
            return throwError(err);
        }));
    }
}
HttpMessageInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpMessageInterceptor_Factory() { return new HttpMessageInterceptor(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.PopupService)); }, token: HttpMessageInterceptor, providedIn: "root" });
