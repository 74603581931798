<form [formGroup]="form" (ngSubmit)="updateExportOrder()" class="p-2 mt-3">
  <div class="d-flex">
    <dulo-input
      formControlName="importCost"
      placeholder="Unesite troškove izvoza"
      class="mr-2"
    ></dulo-input>
    <dulo-input
      formControlName="exportCost"
      placeholder="Unesite troškove uvoza"
    ></dulo-input>
  </div>

  <div class="mt-4 align-items-center d-flex justify-content-end">
    <dulo-button
      type="submit"
      class="ml-2"
      label="Dodaj troškove"
    ></dulo-button>
  </div>
</form>
