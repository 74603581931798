import { ErrorHandler } from "@angular/core";
import { HttpClient, } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular";
import * as Raven from "raven-js";
import { environment } from "src/environments/environment";
Raven.config(environment.sentryDSNKey).install();
export class RavenErrorHandler {
    handleError(err) {
        Raven.captureException(err.originalError);
    }
}
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
export function getToken() {
    return localStorage.getItem("accessToken");
}
const ɵ0 = Sentry.createErrorHandler(), ɵ1 = () => () => { };
export class AppModule {
}
export { ɵ0, ɵ1 };
