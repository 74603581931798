import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { JwtService } from "./_services/jwt.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = "BrendlyPartnerPlatform";
  loggedIn$: Observable<boolean>;
  isManufacturedProductsWarehouse$ = new BehaviorSubject<boolean>(null);
  countryCode: string;
  isFactory$ = new BehaviorSubject<boolean>(false);

  constructor(
    private jwtService: JwtService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (window.location.href.includes(".rs")) {
      this.countryCode = "rs";
    } else {
      this.countryCode = "hr";
    }

    const country = environment.countries.find(
      (country) => country.alpha2Code === this.countryCode
    );
    environment.apiUrl = country.apiUrl;

    this.translateService.setDefaultLang("sr");
    this.translateService.use("sr");
    this.loggedIn$ = this.jwtService.accessTokens$.pipe(
      map((accessToken) => accessToken !== null)
    );

    this.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        this.isManufacturedProductsWarehouse$.next(
          this.jwtService.getDecodedAccessToken()
            .manufactured_products_warehouse
        );

        this.isFactory$.next(this.jwtService.getDecodedAccessToken().factory);
      }
    });
  }

  onLogout() {
    this.router.navigate([""]);
  }

  closeHamburgerIfOpen(hamburger: HTMLElement) {
    if (hamburger.attributes.getNamedItem("aria-expanded").value === "true") {
      hamburger.click();
    }
  }
}
