import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Type,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'dulo-auto-completer',
  templateUrl: './auto-completer.component.html',
  styleUrls: ['./auto-completer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<AutoCompleterComponent> => AutoCompleterComponent,
      ),
      multi: true,
    },
  ],
})
export class AutoCompleterComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() minLength: number = 1;
  @Input() type: string = 'text';
  @Input() values: any[];
  @Input() field: string;
  @Input() scrollHeight: string = '200px';
  @Input() delay: number = 300;
  @Input() tabIndex: number;
  @Input() forceSelection: boolean;
  @Input() serverFiltering: boolean = false;
  @Input() filterUrl: string;
  @Input() style: any;
  @Input() disabled = false;

  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  selected: any;

  get Selected(): any {
    return this.selected;
  }
  set Selected(value: any) {
    this.selected = value;
    this.onModelChange(value);
    this.onModelTouched();
  }

  filteredValues: any[];
  previousInputLength = 0;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    if (this.serverFiltering) {
      this.http.get<any[]>(this.filterUrl).subscribe((res): void => {
        this.filteredValues = res;
      });
    } else {
      this.filteredValues = this.values;
    }
  }

  filter(event: any): void {
    const sourceValues =
      event.query.length > this.previousInputLength
        ? this.filteredValues
        : this.values;
    if (this.serverFiltering) {
      this.http
        .get<any[]>(this.filterUrl + '?filter=' + event.query)
        .subscribe((res): void => {
          this.filteredValues = res;
        });
    } else {
      if (this.field) {
        this.filteredValues = sourceValues.filter((v): any[] =>
          v[this.field].startsWith(event.query),
        );
      } else {
        this.filteredValues = sourceValues.filter((v): any[] =>
          v.startsWith(event.query),
        );
      }
    }
    this.previousInputLength = event.query.length;
  }

  writeValue(obj: any): void {
    this.selected = obj;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
