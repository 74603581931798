import { HttpParams } from "@angular/common/http";
import * as moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ExportOrderService {
    constructor(http) {
        this.http = http;
        this.baseUrl = "partner/export_orders";
    }
    generateFilters(filters) {
        let params = new HttpParams()
            .set("page", filters.first / filters.rows + "")
            .set("size", filters.rows + "")
            .set("sort_by", filters.sortField ? filters.sortField : "created")
            .set("sort_order", "desc");
        Object.entries(filters.filters).forEach((filter) => {
            const { 0: name, 1: filterInfo } = filter;
            switch (name) {
                case "id":
                    filterInfo.matchMode = "eq";
                    break;
                case "state":
                    filterInfo.matchMode = "in";
                    break;
                default:
                    break;
            }
            if (filterInfo.matchMode === "dateBetween") {
                filterInfo.value = this.modifyFilterDate(filterInfo.value);
                filterInfo.matchMode = "btn";
            }
            params = params.set(name, `${filterInfo.matchMode}:${filterInfo.value}`);
        });
        return params;
    }
    modifyFilterDate(filterValue) {
        let modifiedDate;
        let finalDates = [];
        filterValue.forEach((date) => {
            const dateType = filterValue.indexOf(date) === 0 ? "00-00-01" : "23-59-59";
            if (date) {
                modifiedDate = moment(date).format(`YYYY-MM-DD-${dateType}`);
            }
            else {
                modifiedDate = moment().format(`YYYY-MM-DD-${dateType}`);
            }
            finalDates.push(modifiedDate);
        });
        return finalDates;
    }
    getExportOrders(filters) {
        let params = this.generateFilters(filters);
        return this.http.get(this.baseUrl, {
            params,
        });
    }
    createExportOrder(listOfRawMaterials) {
        return this.http.post(this.baseUrl, {
            exportParcelRawMaterialList: listOfRawMaterials,
        });
    }
    updateExportOrder(exportOrderId, data) {
        return this.http.put(`${this.baseUrl}/${exportOrderId}`, Object.assign({}, data));
    }
    finishExportOrder(orderId) {
        return this.http.put(`${this.baseUrl}/${orderId}/finish`, {});
    }
    startExportOrder(orderId) {
        return this.http.put(`${this.baseUrl}/${orderId}/start`, {});
    }
    getOrders(orderId, filters) {
        let params = this.generateFilters(filters);
        return this.http.get(`${this.baseUrl}/${orderId}/orders`, {
            params,
        });
    }
}
ExportOrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExportOrderService_Factory() { return new ExportOrderService(i0.ɵɵinject(i1.HttpClient)); }, token: ExportOrderService, providedIn: "root" });
