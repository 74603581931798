import { NgModule } from '@angular/core';
import { DatePickerComponent } from './date-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [SharedModule, BrowserAnimationsModule, FormsModule, CalendarModule],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
