import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthInterceptor } from "../_interceptors/route-url.interceptor";
import { JwtService } from "./jwt.service";
import { Token } from "../_view-models/token";
import { SKIP_JWT_RENEW_INTERCEPTOR } from "../_interceptors/skip-interceptors";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = "partner/auth";

  AUTH_HEADERS = {
    headers: new HttpHeaders()
      .set(AuthInterceptor, "Auth")
      .set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR),
  };

  constructor(private http: HttpClient, private jwtService: JwtService) {}

  get isLoggedIn() {
    return this.jwtService.getDecodedAccessToken();
  }

  login(user: {
    email?: string;
    password?: string;
    barcode?: string;
    pin?: string;
  }): Observable<any> {
    const url: string = this.baseUrl + "/login";
    return this.http.post(url, user, this.AUTH_HEADERS).pipe(
      switchMap((res): any => {
        this.jwtService.saveAccessToken(res["accessToken"]);
        this.jwtService.saveRefreshToken(res["refreshToken"]);
        return of(this.jwtService.getDecodedAccessToken());
      })
    );
  }

  forgotPassword(forgotPassword: {
    resetUrl: string;
    email: string;
  }): Observable<any> {
    const url: string =
      this.baseUrl + "/forgot_password/" + forgotPassword.email;
    return this.http.post(url, this.AUTH_HEADERS);
  }

  resetPassword(newPassword: any): Observable<any> {
    const url: string =
      this.baseUrl +
      `${this.jwtService.getDecodedAccessToken()["user_id"]}/reset_password`;
    return this.http.put(url, { new_password: newPassword }, this.AUTH_HEADERS);
  }

  changePassword(password: string): Observable<any> {
    const url: string = this.baseUrl + "/change_password";
    return this.http.put(url, { password }, this.AUTH_HEADERS);
  }

  logOut(): Observable<any> {
    const url = `${this.baseUrl}/invalidate_refresh_token`;
    return this.http.post(
      url,
      {
        access_token: this.jwtService.getAccessToken(),
        user_id: this.jwtService.getDecodedAccessToken()["user_id"],
        refresh_token: this.jwtService.getRefreshToken(),
      },
      this.AUTH_HEADERS
    );
  }

  renewRefreshToken(token: Token): Observable<Token> {
    return this.http.put<Token>(`${this.baseUrl}/refresh_token`, token, {
      headers: new HttpHeaders().set("refresh", "refresh"),
    });
  }
}
