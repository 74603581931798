import { HttpHeaders } from "@angular/common/http";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthInterceptor } from "../_interceptors/route-url.interceptor";
import { SKIP_JWT_RENEW_INTERCEPTOR } from "../_interceptors/skip-interceptors";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./jwt.service";
export class AuthService {
    constructor(http, jwtService) {
        this.http = http;
        this.jwtService = jwtService;
        this.baseUrl = "partner/auth";
        this.AUTH_HEADERS = {
            headers: new HttpHeaders()
                .set(AuthInterceptor, "Auth")
                .set(SKIP_JWT_RENEW_INTERCEPTOR, SKIP_JWT_RENEW_INTERCEPTOR),
        };
    }
    get isLoggedIn() {
        return this.jwtService.getDecodedAccessToken();
    }
    login(user) {
        const url = this.baseUrl + "/login";
        return this.http.post(url, user, this.AUTH_HEADERS).pipe(switchMap((res) => {
            this.jwtService.saveAccessToken(res["accessToken"]);
            this.jwtService.saveRefreshToken(res["refreshToken"]);
            return of(this.jwtService.getDecodedAccessToken());
        }));
    }
    forgotPassword(forgotPassword) {
        const url = this.baseUrl + "/forgot_password/" + forgotPassword.email;
        return this.http.post(url, this.AUTH_HEADERS);
    }
    resetPassword(newPassword) {
        const url = this.baseUrl +
            `${this.jwtService.getDecodedAccessToken()["user_id"]}/reset_password`;
        return this.http.put(url, { new_password: newPassword }, this.AUTH_HEADERS);
    }
    changePassword(password) {
        const url = this.baseUrl + "/change_password";
        return this.http.put(url, { password }, this.AUTH_HEADERS);
    }
    logOut() {
        const url = `${this.baseUrl}/invalidate_refresh_token`;
        return this.http.post(url, {
            access_token: this.jwtService.getAccessToken(),
            user_id: this.jwtService.getDecodedAccessToken()["user_id"],
            refresh_token: this.jwtService.getRefreshToken(),
        }, this.AUTH_HEADERS);
    }
    renewRefreshToken(token) {
        return this.http.put(`${this.baseUrl}/refresh_token`, token, {
            headers: new HttpHeaders().set("refresh", "refresh"),
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtService)); }, token: AuthService, providedIn: "root" });
