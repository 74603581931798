<dulo-table
  [columns]="cols"
  [rows]="10"
  [expandedTemplate]="orderItemTemplate"
  [request]="manufacturedOrders$"
  [expandable]="true"
  [lazy]="true"
  [paginator]="true"
>
  <ng-template #orderItemTemplate let-order>
    <div class="d-flex flex-wrap">
      <div class="d-flex m-1" *ngFor="let orderItem of order.order_item_list">
        <div class="bg-white border">
          <img
            style="width: 180px"
            [src]="orderItem.product_images[0].product_image_url"
            class="m-2"
          />
          <div class="d-flex flex-column align-items-center">
            <p>{{ orderItem.id }}</p>
            <p>
              {{ orderItem.product_name }} ({{ orderItem.product_type_name }})
            </p>
            <p>{{ orderItem.product_color }} ({{ orderItem.product_size }})</p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</dulo-table>
