import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";

import { PopupService } from "../components/popup/popup.service";
import { AuthService } from "../_services/auth.service";
import { SKIP_ERROR_HANDLING_INTERCEPTORS } from "./skip-interceptors";

@Injectable({
  providedIn: "root",
})
export class HttpMessageInterceptor implements HttpInterceptor {
  text: string;
  title: string;
  constructor(
    private router: Router,
    private authService: AuthService,
    private popupService: PopupService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.headers.get(SKIP_ERROR_HANDLING_INTERCEPTORS) ===
      SKIP_ERROR_HANDLING_INTERCEPTORS
    ) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (Math.floor(err.status / 100) === 5) {
          this.title = "Doslo je do greške na serveru";
        } else if (err.status === 401) {
          this.title = "Nemate prava da vidite podatke";
        } else if (err.status === 403) {
          this.title = "Morate se ulogovati ponovo";
          this.authService.logOut();
          this.router.navigate(["/"]);
        } else if (Math.floor(err.status / 100) === 4) {
          return throwError(err);
        }
        this.popupService.fire({
          type: "error",
          titleText: this.title,
          text: this.text,
        });
        return throwError(err);
      })
    );
  }
}
