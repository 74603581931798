<div class="row">
  <div class="col-12">
    <form
      class="form-{{ orientation }} d-grid"
      [formGroup]="form"
      (ngSubmit)="onSubmit.emit({ values: getValues(), hasFiles: hasFiles() })"
    >
      <ng-container *ngFor="let fcKey of object.keys(form.controls)">
        <ng-container *ngIf="getField(fcKey) as field" [ngSwitch]="field.type">
          <div
            class="form-group"
            [hidden]="field?.hidden"
            [ngStyle]="{
              'grid-area':
                field.row +
                ' / ' +
                field.column +
                ' / span ' +
                field.rowSpan +
                ' / span ' +
                field.colSpan
            }"
          >
            <span [ngClass]="field?.options?.wrapperClass">
              <ng-container
                *ngSwitchCase="
                  [
                    'input',
                    'password',
                    'range',
                    'date',
                    'select',
                    'select-button',
                    'radio'
                  ].includes(field.type)
                    ? field.type
                    : ''
                "
              >
                <label
                  *ngIf="!field.options?.hideLabel"
                  class="col-form-label {{ field.options?.labelClass }}"
                  for="{{ field?.options?.label }}"
                  >{{ field?.translationKey | translate }}</label
                >
              </ng-container>
              <ng-container *ngSwitchCase="'input'">
                <dulo-input
                  placeholder="{{ field.translationKey | translate }}"
                  [formControlName]="field.formControlName"
                  [floatablePlaceholder]="field.options?.floatablePlaceholder"
                  [styleClass]="field.options?.class"
                ></dulo-input>
                <!-- <dulo-field-errors
                  [field]="field.formControlName"
                  [form]="form"
                ></dulo-field-errors> -->
              </ng-container>
              <ng-container *ngSwitchCase="'textarea'">
                <dulo-textarea
                  [formControlName]="field.formControlName"
                  [floatablePlaceholder]="field.options?.floatablePlaceholder"
                  [cols]="field.options?.cols"
                  [rows]="field.options?.rows"
                  [classList]="field.options.class"
                ></dulo-textarea>
              </ng-container>
              <ng-container *ngSwitchCase="'password'">
                <dulo-password
                  placeholder="{{ field.translationKey | translate }}"
                  [formControlName]="field.formControlName"
                ></dulo-password>
              </ng-container>
              <ng-container *ngSwitchCase="'range'">
                <dulo-number-range-input
                  [formControlName]="field.formControlName"
                  [minRangeValue]="field.options?.minValue"
                  [maxRangeValue]="field.options?.maxValue"
                  [minPlaceholder]="field.options?.minPlaceholder | translate"
                  [maxPlaceholder]="field.options?.maxPlaceholder | translate"
                  [onlyPositive]="field.options?.onlyPositive"
                ></dulo-number-range-input>
              </ng-container>
              <ng-container *ngSwitchCase="'checkbox'">
                <dulo-checkbox
                  [style]="field?.style"
                  [checked]="field?.options?.checked"
                  [label]="field?.translationKey | translate"
                  [formControlName]="field.formControlName"
                  (onChange)="
                    field.options.onChange({
                      event: $event,
                      controlName: field.formControlName
                    })
                  "
                >
                </dulo-checkbox>
              </ng-container>
              <ng-container *ngSwitchCase="'switch'">
                <dulo-switch
                  [style]="field?.style"
                  [Checked]="field?.options?.checked"
                  [formControlName]="field.formControlName"
                  [label]="field?.options?.label | translate"
                  (onChange)="
                    field.options.onChange({
                      event: $event,
                      controlName: field.formControlName
                    })
                  "
                >
                </dulo-switch>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <dulo-date-picker
                  [style]="field?.style"
                  [selectionMode]="field?.options?.selectionMode || 'single'"
                  [formControlName]="field.formControlName"
                ></dulo-date-picker>
                <!-- <dulo-field-errors
            [form]="form"
            [formControlName]="field.formControlName"
            [nicename]="field.title"
          ></dulo-field-errors> -->
              </ng-container>
              <ng-container *ngSwitchCase="'select'">
                <dulo-select
                  [style]="field.style"
                  [rawValues]="field.options.rawValues | async"
                  [values]="field.options.values"
                  [formControlName]="field.formControlName"
                  [placeholder]="field.options.placeholder"
                  [multiple]="field.options.multiple"
                  [hasNone]="field.options.hasNone"
                  (onChange)="
                    field.options.onChange({
                      event: $event,
                      controlName: field.formControlName
                    })
                  "
                ></dulo-select>
                <!-- <dulo-field-errors
            [form]="form"
            [formControlName]="field.formControlName"
            [nicename]="field.title"
          ></dulo-field-errors> -->
              </ng-container>
              <ng-container *ngSwitchCase="'select-button'">
                <dulo-select-button
                  [rawValues]="field.options.rawValues | async"
                  [values]="field.options.values"
                  [multiple]="field.options.multiple"
                  [formControlName]="field.formControlName"
                  (onChange)="
                    field.options.onChange({
                      event: $event,
                      controlName: field.formControlName
                    })
                  "
                ></dulo-select-button>
              </ng-container>
              <ng-container *ngSwitchCase="'autocompleter'">
                <dulo-auto-completer
                  [style]="field?.style"
                  [values]="field?.options.values"
                  [formControlName]="field.formControlName"
                ></dulo-auto-completer>
                <!-- <dulo-field-errors
            [form]="form"
            [formControlName]="field.formControlName"
            [nicename]="field.title"
          ></dulo-field-errors> -->
              </ng-container>
              <ng-container *ngSwitchCase="'button'">
                <dulo-button
                  [label]="field?.translationKey"
                  (onClick)="
                    field.options.onClick({
                      event: $event,
                      controlName: field.buttonName
                    })
                  "
                  [type]="field?.options.submitButton ? 'submit' : 'button'"
                  [disabled]="field?.options.submitButton && !form.valid"
                  [styleClass]="field.options?.class"
                ></dulo-button>
              </ng-container>
              <ng-container *ngSwitchCase="'radio'">
                <dulo-radio-button
                  *ngFor="let value of field.options.values"
                  [label]="value"
                  (onClick)="
                    field.options.onClick({
                      event: $event,
                      controlName: field.formControlName
                    })
                  "
                  [name]="field.formControlName"
                  [value]="value"
                  [formControlName]="field.formControlName"
                ></dulo-radio-button>
              </ng-container>
              <ng-container *ngSwitchCase="'file'">
                <dulo-file-upload
                  [formControlName]="field.formControlName"
                  [multiple]="field.options.multiple"
                  [files]="field.options.files"
                >
                </dulo-file-upload>
              </ng-container>
              <ng-container *ngSwitchCase="'group'">
                <div [formGroupName]="field.formControlName">
                  <ng-container
                    *ngTemplateOutlet="
                      fieldsTemplate;
                      context: {
                        $implicit: field.formControls,
                        groupPrefix: field.formControlName,
                        class: field.options?.fieldClass
                      }
                    "
                  ></ng-container>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'array'">
                <div
                  [formArrayName]="field.formControlName"
                  class="{{ field.options.class }}"
                >
                  <span
                    *ngIf="field.options.expandable || field.options.addable"
                    (click)="addArrayRow(field.formControlName)"
                    ><i class="pi pi-plus"></i
                  ></span>
                  <div
                    *ngFor="
                      let v of getFormControl(field.formControlName)['controls'];
                      let i = index
                    "
                    [formGroupName]="i"
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        fieldsTemplate;
                        context: {
                          $implicit: field.arrayControls,
                          groupPrefix: field.formControlName + '.' + i,
                          index: i,
                          class: field.options?.fieldClass
                        }
                      "
                    ></ng-container>
                    <span
                      *ngIf="
                        field.options.expandable || field.options.deletable
                      "
                      (click)="removeArrayRow(field.formControlName, i)"
                      ><i
                        class="pi pi-trash {{
                          field.options?.removeButtonClass
                        }}"
                      ></i
                    ></span>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'custom'">
                <ng-container
                  *ngTemplateOutlet="field.options.template"
                ></ng-container>
              </ng-container>
            </span>
          </div>
          <ng-template
            #fieldsTemplate
            let-fields
            let-values="values"
            let-groupPrefix="groupPrefix"
            let-index="index"
            let-class="class"
          >
            <div
              class="d-grid {{ class }}"
              [formGroup]="getFormControl(groupPrefix)"
            >
              <ng-container
                *ngFor="
                  let fcKey of object.keys(getFormControl(groupPrefix)['controls'])
                "
              >
                <ng-container
                  *ngIf="getField(groupPrefix + '.' + fcKey) as field"
                  [ngSwitch]="field.type"
                >
                  <div
                    class="form-group"
                    [hidden]="field.hidden"
                    [ngStyle]="{
                      'grid-area':
                        field.row +
                        ' / ' +
                        field.column +
                        ' / span ' +
                        field.rowSpan +
                        ' / span ' +
                        field.colSpan
                    }"
                  >
                    <span [ngClass]="field?.options?.wrapperClass">
                      <ng-container
                        *ngSwitchCase="
                          [
                            'input',
                            'password',
                            'range',
                            'date',
                            'select',
                            'select-button',
                            'radio'
                          ].includes(field.type)
                            ? field.type
                            : ''
                        "
                      >
                        <label
                          *ngIf="!field.options?.hideLabel"
                          class="col-form-label {{ field.options?.labelClass }}"
                          for="{{ field?.options?.label }}"
                          >{{ field?.translationKey | translate }}</label
                        >
                      </ng-container>
                      <ng-container *ngSwitchCase="'input'">
                        <dulo-input
                          placeholder="{{ field.translationKey | translate }}"
                          [formControlName]="field.formControlName"
                          [floatablePlaceholder]="
                            field.options?.floatablePlaceholder
                          "
                          [styleClass]="field.options?.class"
                        ></dulo-input>
                      </ng-container>
                      <ng-container *ngSwitchCase="'textarea'">
                        <dulo-textarea
                          [formControlName]="field.formControlName"
                          [floatablePlaceholder]="
                            field.options?.floatablePlaceholder
                          "
                          [cols]="field.options?.cols"
                          [rows]="field.options?.rows"
                          [classList]="field.options.class"
                        ></dulo-textarea>
                      </ng-container>
                      <ng-container *ngSwitchCase="'password'">
                        <dulo-password
                          placeholder="{{ field.translationKey | translate }}"
                          [formControlName]="field.formControlName"
                        ></dulo-password>
                      </ng-container>
                      <ng-container *ngSwitchCase="'range'">
                        <dulo-number-range-input
                          [formControlName]="field.formControlName"
                          [minRangeValue]="field.options?.minValue"
                          [maxRangeValue]="field.options?.maxValue"
                          [minPlaceholder]="
                            field.options?.minPlaceholder | translate
                          "
                          [maxPlaceholder]="
                            field.options?.maxPlaceholder | translate
                          "
                          [onlyPositive]="field.options?.onlyPositive"
                        ></dulo-number-range-input>
                      </ng-container>
                      <ng-container *ngSwitchCase="'checkbox'">
                        <dulo-checkbox
                          [style]="field?.style"
                          [checked]="field?.options?.checked"
                          [label]="field?.translationKey | translate"
                          [formControlName]="field.formControlName"
                          (onChange)="
                            field.options.onChange({
                              event: $event,
                              controlName:
                                groupPrefix + '.' + field.formControlName
                            })
                          "
                        >
                        </dulo-checkbox>
                      </ng-container>
                      <ng-container *ngSwitchCase="'switch'">
                        <dulo-switch
                          [style]="field?.style"
                          [Checked]="field?.options?.checked"
                          [formControlName]="field.formControlName"
                          [label]="field?.options?.label | translate"
                          (onChange)="
                            field.options.onChange({
                              event: $event,
                              controlName:
                                groupPrefix + '.' + field.formControlName
                            })
                          "
                        >
                        </dulo-switch>
                      </ng-container>
                      <ng-container *ngSwitchCase="'date'">
                        <dulo-date-picker
                          [style]="field?.style"
                          [selectionMode]="
                            field?.options?.selectionMode || 'single'
                          "
                          [formControlName]="field.formControlName"
                        ></dulo-date-picker>
                        <!-- <dulo-field-errors
                        [form]="form"
                        [formControlName]="groupPrefix + '.' + field.formControlName"
                        [nicename]="field.title"
                      ></dulo-field-errors> -->
                      </ng-container>
                      <ng-container *ngSwitchCase="'select'">
                        <dulo-select
                          [style]="field.style"
                          [rawValues]="
                            field.options.rawValues &&
                            !isArray(field.options.rawValues)
                              ? (field.options.rawValues[index] | async)
                              : (field.options.rawValues | async)
                          "
                          [values]="
                            field.options.values &&
                            !isArray(field.options.values)
                              ? field.options.values[index]
                              : field.options.values
                          "
                          [formControlName]="field.formControlName"
                          [placeholder]="field.options.placeholder"
                          [multiple]="field.options.multiple"
                          [hasNone]="field.options.hasNone"
                          (onChange)="
                            field.options.onChange({
                              event: $event,
                              controlName:
                                groupPrefix + '.' + field.formControlName
                            })
                          "
                        ></dulo-select>
                        <!-- <dulo-field-errors
                        [form]="form"
                        [formControlName]="groupPrefix + '.' + field.formControlName"
                        [nicename]="field.title"
                      ></dulo-field-errors> -->
                      </ng-container>
                      <ng-container *ngSwitchCase="'select-button'">
                        <dulo-select-button
                          [rawValues]="
                            field?.options.rawValues &&
                            !isArray(field?.options.rawValues)
                              ? ((field?.options.rawValues)[index] | async)
                              : (field?.options.rawValues | async)
                          "
                          [values]="
                            field?.options.values &&
                            !isArray(field?.options.values)
                              ? (field?.options.values)[index]
                              : field?.options.values
                          "
                          [multiple]="field.options.multiple"
                          [formControlName]="field.formControlName"
                          (onChange)="
                            field.options.onChange({
                              event: $event,
                              controlName:
                                groupPrefix + '.' + field.formControlName
                            })
                          "
                        ></dulo-select-button>
                      </ng-container>
                      <ng-container *ngSwitchCase="'autocompleter'">
                        <dulo-auto-completer
                          [style]="field?.style"
                          [values]="field?.options.values"
                          [formControlName]="field.formControlName"
                        ></dulo-auto-completer>
                        <!-- <dulo-field-errors
                        [form]="form"
                        [formControlName]="groupPrefix + '.' + field.formControlName"
                        [nicename]="field.title"
                      ></dulo-field-errors> -->
                      </ng-container>
                      <ng-container *ngSwitchCase="'button'">
                        <dulo-button
                          [label]="field?.translationKey"
                          (onClick)="
                            field.options.onClick({
                              event: $event,
                              controlName: groupPrefix + '.' + field.buttonName
                            })
                          "
                          [type]="
                            field?.options.submitButton ? 'submit' : 'button'
                          "
                          [disabled]="
                            field?.options.submitButton && !form.valid
                          "
                          [styleClass]="field.options?.class"
                        ></dulo-button>
                      </ng-container>
                      <ng-container *ngSwitchCase="'radio'">
                        <dulo-radio-button
                          *ngFor="let value of field.options.values"
                          [label]="value"
                          (onClick)="
                            field.options.onClick({
                              event: $event,
                              controlName:
                                groupPrefix + '.' + field.formControlName
                            })
                          "
                          [name]="groupPrefix + '.' + field.formControlName"
                          [value]="value"
                          [formControlName]="field.formControlName"
                        ></dulo-radio-button>
                      </ng-container>
                      <ng-container *ngSwitchCase="'file'">
                        <dulo-file-upload
                          [formControlName]="field.formControlName"
                          [multiple]="field.options.multiple"
                          [files]="field.options.files"
                        >
                        </dulo-file-upload>
                      </ng-container>
                      <ng-container *ngSwitchCase="'group'">
                        <div [formGroupName]="field.formControlName">
                          <ng-container
                            *ngTemplateOutlet="
                              fieldsTemplate;
                              context: {
                                $implicit: field.formControls,
                                index: index,
                                class: field.options?.fieldClass,
                                groupPrefix:
                                  groupPrefix + '.' + field.formControlName
                              }
                            "
                          ></ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'array'">
                        <div
                          [formArrayName]="field.formControlName"
                          class="{{ field.options.class }}"
                        >
                          <span
                            *ngIf="
                              field.options.expandable || field.options.addable
                            "
                            (click)="
                              addArrayRowByFieldInstance(groupPrefix, field)
                            "
                            ><i class="pi pi-plus"></i
                          ></span>
                          <div
                            *ngFor="
                              let v of getFormControl(
                                groupPrefix + '.' + field.formControlName
                              )['controls'];
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <ng-container
                              *ngTemplateOutlet="
                                fieldsTemplate;
                                context: {
                                  $implicit: field.arrayControls,
                                  groupPrefix:
                                    groupPrefix +
                                    '.' +
                                    field.formControlName +
                                    '.' +
                                    i,
                                  index: i,
                                  class: field.options?.fieldClass
                                }
                              "
                            ></ng-container>
                            <span
                              *ngIf="
                                field.options.expandable ||
                                field.options.deletable
                              "
                              (click)="
                                removeArrayRowByFieldInstance(
                                  groupPrefix,
                                  field,
                                  i
                                )
                              "
                              ><i
                                class="pi pi-trash {{
                                  field.options?.removeButtonClass
                                }}"
                              ></i
                            ></span>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'custom'">
                        <ng-container
                          *ngTemplateOutlet="field.options.template"
                        ></ng-container>
                      </ng-container>
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>
