/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manufactured-products.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/table/table.component.ngfactory";
import * as i4 from "../../components/table/table.component";
import * as i5 from "@angular/common/http";
import * as i6 from "../../components/generators/excel-generator/excel-generator.service";
import * as i7 from "./manufactured-products.component";
import * as i8 from "../../_services/order.service";
var styles_ManufacturedProductsComponent = [i0.styles];
var RenderType_ManufacturedProductsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManufacturedProductsComponent, data: {} });
export { RenderType_ManufacturedProductsComponent as RenderType_ManufacturedProductsComponent };
function View_ManufacturedProductsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex m-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "bg-white border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "m-2"], ["style", "width: 180px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "d-flex flex-column align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " (", ") "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " (", ")"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.product_images[0].product_image_url; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.product_name; var currVal_3 = _v.context.$implicit.product_type_name; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.product_color; var currVal_5 = _v.context.$implicit.product_size; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
function View_ManufacturedProductsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManufacturedProductsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.order_item_list; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ManufacturedProductsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-table", [], null, null, null, i3.View_TableComponent_0, i3.RenderType_TableComponent)), i1.ɵdid(1, 245760, null, 0, i4.TableComponent, [i5.HttpClient, i6.ExcelGeneratorService, i1.ChangeDetectorRef], { expandable: [0, "expandable"], rows: [1, "rows"], paginator: [2, "paginator"], expandedTemplate: [3, "expandedTemplate"], lazy: [4, "lazy"], request: [5, "request"], columns: [6, "columns"] }, null), (_l()(), i1.ɵand(0, [["orderItemTemplate", 2]], null, 0, null, View_ManufacturedProductsComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = 10; var currVal_2 = true; var currVal_3 = i1.ɵnov(_v, 2); var currVal_4 = true; var currVal_5 = _co.manufacturedOrders$; var currVal_6 = _co.cols; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ManufacturedProductsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-manufactured-products", [], null, null, null, View_ManufacturedProductsComponent_0, RenderType_ManufacturedProductsComponent)), i1.ɵdid(1, 49152, null, 0, i7.ManufacturedProductsComponent, [i8.OrderService], null, null)], null, null); }
var ManufacturedProductsComponentNgFactory = i1.ɵccf("app-manufactured-products", i7.ManufacturedProductsComponent, View_ManufacturedProductsComponent_Host_0, {}, {}, []);
export { ManufacturedProductsComponentNgFactory as ManufacturedProductsComponentNgFactory };
