/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./switch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/inputswitch/inputswitch.ngfactory";
import * as i3 from "primeng/components/inputswitch/inputswitch";
import * as i4 from "@angular/forms";
import * as i5 from "./switch.component";
var styles_SwitchComponent = [i0.styles];
var RenderType_SwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwitchComponent, data: {} });
export { RenderType_SwitchComponent as RenderType_SwitchComponent };
export function View_SwitchComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "p-inputSwitch", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onChange"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.Checked = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_InputSwitch_0, i2.RenderType_InputSwitch)), i1.ɵdid(3, 49152, null, 0, i3.InputSwitch, [i1.ChangeDetectorRef], { style: [0, "style"], disabled: [1, "disabled"] }, { onChange: "onChange" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.InputSwitch]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.style; var currVal_9 = _co.disabled; _ck(_v, 3, 0, currVal_8, currVal_9); var currVal_10 = _co.disabled; var currVal_11 = _co.Checked; _ck(_v, 5, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_SwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-switch", [], null, null, null, View_SwitchComponent_0, RenderType_SwitchComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.SwitchComponent]), i1.ɵdid(2, 114688, null, 0, i5.SwitchComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SwitchComponentNgFactory = i1.ɵccf("dulo-switch", i5.SwitchComponent, View_SwitchComponent_Host_0, { disabled: "disabled", style: "style", label: "label", Checked: "Checked" }, { onChange: "onChange" }, []);
export { SwitchComponentNgFactory as SwitchComponentNgFactory };
