/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./radio-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/radiobutton/radiobutton.ngfactory";
import * as i3 from "primeng/components/radiobutton/radiobutton";
import * as i4 from "@angular/forms";
import * as i5 from "./radio-button.component";
var styles_RadioButtonComponent = [i0.styles];
var RenderType_RadioButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RadioButtonComponent, data: {} });
export { RenderType_RadioButtonComponent as RenderType_RadioButtonComponent };
export function View_RadioButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p-radioButton", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.Selected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onClick" === en)) {
        var pd_1 = (_co.onClick.emit({ originalEvent: $event, value: _co.value }) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_RadioButton_0, i2.RenderType_RadioButton)), i1.ɵdid(1, 49152, null, 0, i3.RadioButton, [i1.ChangeDetectorRef], { value: [0, "value"], name: [1, "name"], disabled: [2, "disabled"], label: [3, "label"], styleClass: [4, "styleClass"] }, { onClick: "onClick" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioButton]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.value; var currVal_8 = _co.name; var currVal_9 = _co.disabled; var currVal_10 = _co.label; var currVal_11 = _co.styleClass; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.name; var currVal_13 = _co.disabled; var currVal_14 = _co.Selected; _ck(_v, 3, 0, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_RadioButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dulo-radio-button", [], null, null, null, View_RadioButtonComponent_0, RenderType_RadioButtonComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.RadioButtonComponent]), i1.ɵdid(2, 114688, null, 0, i5.RadioButtonComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RadioButtonComponentNgFactory = i1.ɵccf("dulo-radio-button", i5.RadioButtonComponent, View_RadioButtonComponent_Host_0, { name: "name", value: "value", label: "label", disabled: "disabled", styleClass: "styleClass", Selected: "Selected" }, { onClick: "onClick" }, []);
export { RadioButtonComponentNgFactory as RadioButtonComponentNgFactory };
