import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subject } from "rxjs";
import { SubSink } from "subsink";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "dulo-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() debounceTime = 450;
  @Input() label: string;
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() icon: string;
  @Input() iconPosition: string = "left";
  @Input() styleClass: string;
  @Input() wrapperStyleClass: string;
  @Input() type: "button" | "submit" = "button";
  @Output() onClick = new EventEmitter<any>();
  namespace: string = "button";
  buttonClicked$ = new Subject<Event>();
  subs = new SubSink();

  constructor() {}

  ngOnInit(): void {
    this.subs.add(
      this.buttonClicked$
        .pipe(debounceTime(this.debounceTime))
        .subscribe((event: Event) => this.onClick.emit(event))
    );
  }

  handleClick(event: Event): void {
    this.buttonClicked$.next(event);
  }
}
