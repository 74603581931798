import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { ButtonComponent } from './button.component';
import { ButtonModule as PrimeNgButtonModule } from 'primeng/button';

@NgModule({
  declarations: [ButtonComponent],
  imports: [SharedModule, PrimeNgButtonModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
