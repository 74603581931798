import { NgModule } from '@angular/core';
import { NumberRangeInputComponent } from './number-range-input.component';
import { SharedModule } from '../shared.module';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [NumberRangeInputComponent],
  imports: [SharedModule, SliderModule, InputModule, FormsModule],
  exports: [NumberRangeInputComponent],
})
export class NumberRangeInputModule {}
