import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Pagging } from './pagging.model';

@Component({
  selector: 'dulo-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit {
  @Input() totalRecords = 0;
  @Input() pageSize = 0;
  @Input() page = 0;
  @Input() pageLinkSize = 5;
  @Input() elClass: string;
  @Input() elStyle: any;
  @Input() alwaysShow = false;
  @Input() showPageLinks = false;
  @Input() showCurrentPageReport = false;
  @Input() currentPageReportTemplate = '{currentPage} od {totalPages}';
  @Output() pageChangeHandler = new EventEmitter<Pagging>();
  constructor() {}

  ngOnInit(): void {}
}
