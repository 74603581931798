import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ColorService {
    constructor(http) {
        this.http = http;
        this.baseUrl = 'partner/colors';
    }
    getAll() {
        return this.http.get(`${this.baseUrl}`);
    }
}
ColorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ColorService_Factory() { return new ColorService(i0.ɵɵinject(i1.HttpClient)); }, token: ColorService, providedIn: "root" });
