import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { ResolveSelectPipe } from './resolve-select.pipe';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [SelectComponent, ResolveSelectPipe],
  imports: [SharedModule, FormsModule, DropdownModule, MultiSelectModule],
  exports: [SelectComponent],
  providers: [ResolveSelectPipe],
})
export class SelectModule {}
