import { NgModule } from '@angular/core';
import { CheckboxComponent } from './checkbox.component';
import { CheckboxModule as PrimeNgCheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [SharedModule, FormsModule, PrimeNgCheckboxModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
