import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { DynamicDialogConfig } from "primeng/api";

@Component({
  selector: "app-order-raw-materials",
  templateUrl: "./order-raw-materials.component.html",
  styleUrls: ["./order-raw-materials.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderRawMaterialsComponent implements OnInit {
  rawMaterials = this.config.data.rawMaterials;

  constructor(private config: DynamicDialogConfig) {}

  ngOnInit() {}
}
