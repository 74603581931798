import { NgModule } from '@angular/core';
import { PasswordComponent } from './password.component';
import { SharedModule } from '../shared.module';
import { PasswordModule as PrimeNgPasswordModule } from 'primeng/password';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PasswordComponent],
  imports: [SharedModule, FormsModule, PrimeNgPasswordModule],
  exports: [PasswordComponent],
})
export class PasswordModule {}
