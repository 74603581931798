import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  ElementRef,
} from "@angular/core";
import { OrderService } from "src/app/_services/order.service";
import { map, skip, tap } from "rxjs/operators";
import { PaginatedResult } from "src/app/components/table/paginated-result";
import { Order } from "src/app/_view-models/order";
import { SubSink } from "subsink";
import { PopupService } from "src/app/components/popup/popup.service";
import { AccordionComponent } from "src/app/components/accordion/accordion.component";
import { BehaviorSubject } from "rxjs";
import { WorkOrder } from "src/app/_view-models/work-order";
import * as moment from "moment";
import { SelectComponent } from "src/app/components/select/select.component";
import { FulfillmentTypeTranslation } from "src/app/enums/fulfillment-type-translation.enum";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-orders-manufacturing-mobile",
  templateUrl: "./orders-manufacturing-mobile.component.html",
  styleUrls: ["./orders-manufacturing-mobile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OrdersManufacturingMobileComponent implements OnInit {
  @ViewChild(AccordionComponent, { static: true })
  accordionComponent: AccordionComponent;
  type = "manufacturing-shipping";
  currentFilters = {
    first: 0,
    rows: 50,
    sortOrder: -1,
    sortField: "urgent",
    filters: {},
  };

  totalCount = 0;
  @ViewChild("workOrderSelect", { static: true })
  workOrderSelect: SelectComponent;

  orders$ = new BehaviorSubject<any>([]);
  selectedWorkOrder;

  activeWorkOrders$ = this.orderService.getActiveWorkOrders().pipe(
    map(
      (activeWorkOrders: {
        current_results: [];
        page_number: number;
        page_size: number;
        total_pages: number;
        total_results: number;
      }) => activeWorkOrders.current_results
    ),
    tap((activeWorkOrders) => {
      activeWorkOrders.forEach((activeWorkOrder: WorkOrder) => {
        // Radni nalog 25 (07.01.2022 13:22)
        activeWorkOrder.label = `Radni nalog ${activeWorkOrder.id} (${moment(
          activeWorkOrder.created
        ).format("DD.MM.YYYY. HH:mm")})`;

        return activeWorkOrder;
      });
    })
  );

  subs = new SubSink();
  constructor(
    private orderService: OrderService,
    private popupService: PopupService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // this.getOrders();
  }

  startOrder(order) {
    this.subs.add(
      this.orderService.startManufacturing(order.id).subscribe(() => {
        order.state = "MANUFACTURING";
        this.cdr.markForCheck();
      })
    );
  }

  getOrders(): void {
    this.selectedWorkOrder = this.workOrderSelect.selectedItem;
    this.orderService
      .getWorkOrders(this.selectedWorkOrder.id, this.currentFilters)
      .pipe(
        map((res: PaginatedResult<Order>) => {
          if (res.current_results) {
            this.totalCount = res.total_results;
            const states = ["ACCEPTED", "MANUFACTURING", "READY_FOR_SHIPPING"];
            return res.current_results
              .filter((o) => states.includes(o.state))
              .map((o) => {
                return {
                  name:
                    "Porudžbina " +
                    o.id +
                    " - " +
                    o.first_name +
                    " " +
                    o.last_name +
                    " (" +
                    o.shop_name +
                    ")" +
                    "-" +
                    o.city +
                    " " +
                    o.address +
                    " " +
                    o.number +
                    "/" +
                    o.apartment +
                    (o.last_order_id !== "-1" ? " - REKLAMACIJA" : "") +
                    " " +
                    (o.urgent ? "- HITNA" : "") +
                    " " +
                    `(${
                      FulfillmentTypeTranslation[
                        o["delivery_info"]["fulfillment_type"]
                      ]
                    }${
                      o["delivery_info"]["delivery_date"]
                        ? " " +
                          moment(o["delivery_info"]["delivery_date"]).format(
                            "dd.MM.yyyy."
                          )
                        : ""
                    })`,
                  data: {
                    ...o,
                    order_item_list: o.order_item_list.map((oi) => {
                      return {
                        ...oi,
                        meta: {
                          rowClass: oi.is_manufactured
                            ? "bg-success"
                            : "bg-danger",
                        },
                      };
                    }),
                  },
                  meta: {
                    class:
                      o.state === "READY_FOR_SHIPPING"
                        ? "bg-success"
                        : o.order_item_list.every(oi => oi.product_color === 'Bela') ? "bg-light" : "bg-danger",
                  },
                };
              });
          }
        })
      )
      .subscribe((orders) => {
        this.orders$.next(orders);
      });
  }

  finishOrderItem(orderItem) {
    this.subs.add(
      this.orderService
        .toggleOrderItemIsManufactured(orderItem.id)
        .subscribe((res) => {
          orderItem.is_manufactured = true;
          orderItem.meta.rowClass = "bg-success";
          this.cdr.markForCheck();
        })
    );
  }

  repeatOrderItem(orderItem) {
    const controlNumber = 1000 + Math.floor(Math.random() * 9000);
    this.popupService
      .fire({
        titleText: "Da li ste sigurni da želite da ponovite izradu?",
        text: "Ako jeste, unesite sledeći broj - " + controlNumber,
        input: "text",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        cancelButtonText: "Ne",
        confirmButtonText: "Da",
        inputValidator: (value) => {
          return +value === controlNumber ? null : "Uneli ste pogrešan broj";
        },
      })
      .then((res) => {
        if (res.dismiss) {
          return;
        }
        this.subs.add(
          this.orderService
            .repeatOrderItemManufacturing(orderItem.id)
            .subscribe(() => {
              this.popupService.fire({
                type: "success",
                title: "Započni ponovo",
              });
              orderItem.is_manufactured = false;
              orderItem.meta.rowClass = "bg-danger";
              this.cdr.markForCheck();
            })
        );
      });
  }

  finishOrder(order, index) {
    this.orderService.finishManufacturing(order.id).subscribe((res) => {
      this.popupService.fireToast({
        type: "success",
        title: "Porudžbina obradjena",
      });
      this.accordionComponent.tabs[index].meta.class = "bg-success";
      order.state = "READY_FOR_SHIPPING";
      if (index < this.accordionComponent.tabs.length - 1) {
        this.accordionComponent.activeIndex = index + 1;
      } else {
        this.accordionComponent.activeIndex = -1;
      }
      this.accordionComponent.cdr.markForCheck();
    });
  }

  allOrderItemsManufactured(order: Order) {
    return (
      order.order_item_list.filter((oi) => !oi.is_manufactured).length === 0
    );
  }

  getShippingLabel(order): void {
    this.orderService.getShippingLabel(order.id).subscribe((res) => {
      saveAs(res.body, `shipping_label_${order.id}.pdf`);
    })
  }

  changePage(filters): void {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
    };
    this.getOrders();
  }
}
