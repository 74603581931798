<p-dialog
  header="Kreiraj izvozni nalog"
  [(visible)]="display"
  [style]="{ width: '30vw' }"
>
  <div class="m-4">
    <form [formGroup]="form" (ngSubmit)="createExportOrder()">
      <dulo-radio-button
        *ngFor="let exportOrderOption of exportOrderOptions"
        [Selected]="exportOrderOptions[0].value"
        [value]="exportOrderOption.value"
        [label]="exportOrderOption.label"
      >
      </dulo-radio-button>

      <div formArrayName="rawMaterials">
        <h6 class="mt-4 text-left">Unesi repromaterijale</h6>

        <div
          *ngFor="
            let formRawMaterial of formRawMaterials.controls;
            let i = index
          "
        >
          <div
            class="d-flex mt-2 align-items-center"
            [formGroup]="formRawMaterial"
          >
            <dulo-select
              elClass="w-100"
              formControlName="rawMaterial"
              class="mr-2 w-50"
              [multiple]="false"
              [values]="rawMaterials"
            ></dulo-select>
            <dulo-input
              placeholder="kg"
              [floatablePlaceholder]="false"
              formControlName="weight"
            ></dulo-input>
            <h1 (click)="removeRawMaterial(i)" class="ml-2 cursor-pointer">
              -
            </h1>
          </div>
        </div>
      </div>

      <div class="text-right w-100 d-flex justify-content-end">
        <h1 class="cursor-pointer" (click)="addRawMaterial()">+</h1>
      </div>

      <div class="mt-4 align-items-center d-flex justify-content-end">
        <dulo-button
          type="submit"
          class="ml-2"
          label="Kreiraj izvozni nalog"
        ></dulo-button>
      </div>
    </form>
  </div>
</p-dialog>

<div class="m-4 d-flex justify-content-between">
  <dulo-button (click)="show()" label="Kreiraj izvozni nalog"> </dulo-button>

  <dulo-button label="Osveži" (click)="reloadTable()"></dulo-button>
</div>

<ng-container>
  <dulo-table
    [columns]="cols"
    [rows]="10"
    [paginator]="true"
    [lazy]="true"
    [buttons]="true"
    [buttonsTemplate]="buttonsTemplate"
    [request]="exportOrders$"
  >
    <ng-template #buttonsTemplate let-exportOrder>
      <div class="d-flex justify-content-start flex-wrap">
        <dulo-button
          (click)="showOrders(exportOrder)"
          class="mb-1 mr-1"
          label="Porudžbine"
        ></dulo-button>

        <dulo-button
          (click)="showUpdateOrderForm(exportOrder)"
          class="mb-1 mr-1"
          label="Unesi troškove"
        ></dulo-button>

        <dulo-button
          (click)="showRawMaterials(exportOrder.exportParcelRawMaterialList)"
          class="mb-1 mr-1"
          label="Pregledaj"
        ></dulo-button>

        <dulo-button
          class="mb-1 mr-1"
          style="min-width: 112px"
          [class.wo-ready]="exportOrder.state !== exportOrderState.IN_TRANSIT"
          [class.wo-started]="exportOrder.state === exportOrderState.IN_TRANSIT"
          (click)="handleExportOrderCreation(exportOrder.state, exportOrder.id)"
          [style.visibility]="
            exportOrder.state !== exportOrderState.DELIVERED
              ? 'visible'
              : 'hidden'
          "
          [label]="
            exportOrder.state === exportOrderState.IN_TRANSIT
              ? 'Završi nalog '
              : 'Započni nalog'
          "
        ></dulo-button>
      </div>
    </ng-template>
  </dulo-table>
</ng-container>

<ng-template #stateTemplate let-exportOrder>
  <div class="d-flex">
    <p class="m-0">{{ exportOrder.stateTranslated }}</p>
  </div>
</ng-template>
