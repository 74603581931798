import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../components/loader/loader.service';

export const SkipLoadingSpinnerInterceptor = 'SkipLoadingSpinner';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  finished: boolean;
  constructor(
    private loaderService: LoaderService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.finished = false;
    setTimeout((): void => {
      if (!this.finished) {
        this.loaderService.show();
      }
    }, 800);
    return next.handle(req).pipe(
      finalize((): void => {
        this.finished = true;
        this.loaderService.hide();
      }),
    );
  }
}
