import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from 'src/app/_view-models/order';
import { OrderService } from 'src/app/_services/order.service';
import { TableColumn } from 'src/app/components/table/table-column';
import { SubSink } from 'subsink';
import { TableComponent } from 'src/app/components/table/table.component';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';
import { PaginatedResult } from 'src/app/components/table/paginated-result';
import { environment } from 'src/environments/environment';
import { JwtService } from 'src/app/_services/jwt.service';

@Component({
  selector: 'app-orders-manufacturing',
  templateUrl: './orders-manufacturing.component.html',
  styleUrls: ['./orders-manufacturing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OrdersManufacturingComponent implements OnInit, OnDestroy {
  @ViewChild('ordersTable', {static: false}) ordersTable: TableComponent;
  type = 'manufacturing';
  subs = new SubSink();
  cols: TableColumn[] = [{
    header: 'OrderID',
    field: 'id',
    filterType: 'string',
    type: 'alphanumeric',
    width: '10%'
  }, {
    header: 'Ime i prezime',
    field: 'fullName',
    filterType: 'string',
    type: 'alphanumeric',
    width: '35%'
  }, {
    header: 'Adresa',
    field: 'fullAddress',
    filterType: 'string',
    type: 'alphanumeric',
    width: '40%'
  }, {
    header: 'Cena',
    field: 'price',
    filterType: 'number',
    type: 'alphanumeric',
    width: '10%'
  }];
  orderItemsCols = [
    {
      header: 'Proizvod',
      field: 'product_type_name',
      filterType: 'string',
      type: 'alphanumeric',
      width: '25%'
    },
    {
      header: 'Boja',
      field: 'product_color',
      filterType: 'string',
      type: 'alphanumeric',
      width: '25%'
    },
    {
      header: 'Veličina',
      field: 'product_size',
      filterType: 'select',
      type: 'alphanumeric',
      width: '25%',
    },
    {
      header: 'Slika',
      field: 'image',
      filterType: 'select',
      type: 'image',
      width: '25%',
    }
  ];
  currentFilters = {
    first: 0,
    rows: 50,
    sortOrder: 1,
    sortField: '',
    filters: {}
  };

  ordersCount: number;
  orderItemsCount: number;

  orderRequest = ((event): Observable<PaginatedResult<Order>> => {
    return this.orderService.getAll(this.type, event).pipe(tap((res: PaginatedResult<Order>) => {
      if (res.current_results) {
        this.ordersCount = res.total_results;
        // this.orderItemsCount = res.current_results.reduce(
        //   (acc, curr)  => acc += (curr.order_item_list) ?
        //                             curr.order_item_list.reduce((innerAcc, innerCurr) => innerCurr.is_manufactured ? innerAcc : innerAcc += innerCurr.amount, 0) : 0, 0);
        this.cdr.markForCheck();
      }
    }));
  }).bind(this);

  mapData = (order, index) => {
    return {
      ...order,
      fullName: order['first_name'] + ' ' + order['last_name'],
      fullAddress:  (order.address + ' ' + order.number + ' ' + (order.sub_number ?  '(' + order.sub_number +')' : '' ) + ((order.floor) ? '/' + order.floor + '/' + order.apartment : '/' + order.apartment))  + ', ' + order['postal_code'] + ' ' + order['city'],
      order_item_list: order['order_item_list'].map(oi => {
        return {
          ...oi,
          image: oi['product_images'] ? oi['product_images'][0]['product_image_url']: ''
        };
      })
    };
  }


  constructor(
    private orderService: OrderService,
    private cdr: ChangeDetectorRef,
    private jwtService: JwtService,
    private elementRef: ElementRef) { }

  ngOnInit() {
    this.orderService.getOrderItemsCount().subscribe((orderItemsCount: number) => {
      this.orderItemsCount = orderItemsCount;
    });
  }

  toggleItems(index: number, order: Order) {
    order.expanded = !order.expanded;
    if (order.state === 'MANUFACTURING') {
      this.elementRef.nativeElement.querySelector('#row-index-' + index).click();
      return;
    }
    this.orderService.startManufacturing(order.id).subscribe(res => {
      this.elementRef.nativeElement.querySelector('#row-index-' + index).click();
    });
  }

  setCurrentFilters(event) {
    this.currentFilters = event;
  }

  downloadZip(): void {
    saveAs(`${environment.s3Url}porudzbine_za_danas${this.jwtService.getDecodedAccessToken()['user_id']}.zip?r=${Math.round(Math.random() * 1_000_000)}`, 'porudzbine_za_danas.zip');
  }

  downloadStickers(): void {
    saveAs(`${environment.s3Url}nalepnice_za_danas${this.jwtService.getDecodedAccessToken()['user_id']}.zip?r=${Math.round(Math.random() * 1_000_000)}`, 'porudzbine_za_danas.zip');
  }

  ngOnDestroy(): void {
   this.subs.unsubscribe();
  }
}
