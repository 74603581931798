import { SKIP_INTERCEPTORS, SKIP_JWT_AUTHORIZATION_INTERCEPTOR, } from "./skip-interceptors";
import * as i0 from "@angular/core";
import * as i1 from "../_services/jwt.service";
export class JwtAuthorizationInterceptorService {
    constructor(jwtService) {
        this.jwtService = jwtService;
    }
    intercept(req, next) {
        if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
            return next.handle(req);
        }
        if (req.headers.get(SKIP_JWT_AUTHORIZATION_INTERCEPTOR) ===
            SKIP_JWT_AUTHORIZATION_INTERCEPTOR) {
            return next.handle(req);
        }
        if (req.headers.get("Authorization")) {
            return next.handle(req);
        }
        else {
            const token = this.jwtService.getAccessToken();
            return next.handle(req.clone({
                headers: req.headers.append("Authorization", "Bearer " + token),
            }));
        }
    }
}
JwtAuthorizationInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtAuthorizationInterceptorService_Factory() { return new JwtAuthorizationInterceptorService(i0.ɵɵinject(i1.JwtService)); }, token: JwtAuthorizationInterceptorService, providedIn: "root" });
