<span class='dulo-card'>
  <ng-container *ngIf="hasTemplates">
    <p-card [styleClass]='styleClass'>
      <p-header>
        <ng-template *ngTemplateOutlet="headerTemplate"></ng-template>
      </p-header>
      <ng-template *ngTemplateOutlet="mainTemplate"></ng-template>
      <p-footer>
        <ng-template *ngTemplateOutlet="footerTemplate"></ng-template>
      </p-footer>
    </p-card>
  </ng-container>
  <ng-container *ngIf="!hasTemplates">
    <p-card [header]="header" [subheader]="subheader" [styleClass]='styleClass'>
      <ng-template *ngTemplateOutlet="mainTemplate"></ng-template>
    </p-card>
  </ng-container>
</span>

