import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { TableColumn } from "src/app/components/table/table-column";
import { OrderService } from "src/app/_services/order.service";

@Component({
  selector: "app-manufactured-products",
  templateUrl: "./manufactured-products.component.html",
  styleUrls: ["./manufactured-products.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturedProductsComponent {
  filters = {
    id: { key: "id", matchMode: "eq:" },
    first_name: { key: "firstName", matchMode: "like:" },
    last_name: { key: "lastName", matchMode: "like:" },
    deliveryCode: { key: "deliveryCode", matchMode: "like:" },
    entered_manufactured_warehouse: {
      key: "entered_manufactured_warehouse",
      matchMode: "eq:",
    },
    state: { key: "state", matchMode: "in:" },
  };

  manufacturedOrders$ = ((event) => {
    return this.orderService.getAll(
      "manufactured-products",
      this.generateFilters(event)
    );
  }).bind(this);

  cols: TableColumn[] = [
    {
      header: "ID",
      field: "id",
      filterType: "string",
      type: "alphanumeric",
    },
    {
      header: "Ime",
      field: "first_name",
      filterType: "string",
      type: "alphanumeric",
    },
    {
      header: "Prezime",
      field: "last_name",
      filterType: "string",
      type: "alphanumeric",
    },
    {
      header: "Broj pošiljke",
      field: "deliveryCode",
      filterType: "string",
      type: "alphanumeric",
    },
    {
      header: "Zavedene",
      field: "entered_manufactured_warehouse",
      filterType: "select",
      type: "alphanumeric",
      options: {
        multiple: false,
        values: [
          { value: false, label: "Ne" },
          { value: true, label: "Da" },
        ],
        hasNone: false,
      },
    },
  ];

  constructor(private orderService: OrderService) {}

  generateFilters(event: any) {
    let params = {
      eligible_for_manufactured_product_warehouse: "eq:true",
      entered_manufactured_warehouse: "false",
      state: "in:NOT_DELIVERED",
    };

    Object.entries(event.filters).forEach(
      (filter: [string, { value: any; matchMode: string }]) => {
        const { 0: name, 1: info } = filter;

        params = {
          ...params,
          [this.filters[name].key]:
            name === "entered_manufactured_warehouse"
              ? `${info.value}`
              : `${this.filters[name].matchMode}${info.value}`,
        };
      }
    );

    return (event = {
      ...event,
      filters: {
        ...params,
      },
    });
  }
}
