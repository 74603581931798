import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export const SkipRouteUrlInterceptor = 'SkipRouteUrl';
export const AuthInterceptor = 'Auth';

@Injectable()
export class RouteUrlInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      req.headers.get(SkipRouteUrlInterceptor) === '' ||
      req.url.indexOf('assets/i18n') !== -1
    ) {
      return next.handle(req);
    }
    return next.handle(
      req.clone({
        url: environment.apiUrl + req.url,
      }),
    );
  }
}
