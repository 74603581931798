import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResult } from "../components/table/paginated-result";
import { ExportOrder } from "../_view-models/export-order";
import * as moment from "moment";
import { ExportOrderById } from "../_view-models/export-order-by-id";

@Injectable({
  providedIn: "root",
})
export class ExportOrderService {
  baseUrl = "partner/export_orders";
  constructor(private http: HttpClient) {}

  generateFilters(filters: any) {
    let params = new HttpParams()
      .set("page", filters.first / filters.rows + "")
      .set("size", filters.rows + "")
      .set("sort_by", filters.sortField ? filters.sortField : "created")
      .set("sort_order", "desc");

    Object.entries(filters.filters).forEach(
      (filter: [string, { value: any; matchMode: string }]) => {
        const { 0: name, 1: filterInfo } = filter;

        switch (name) {
          case "id":
            filterInfo.matchMode = "eq";
            break;

          case "state":
            filterInfo.matchMode = "in";
            break;

          default:
            break;
        }

        if (filterInfo.matchMode === "dateBetween") {
          filterInfo.value = this.modifyFilterDate(filterInfo.value);
          filterInfo.matchMode = "btn";
        }

        params = params.set(
          name,
          `${filterInfo.matchMode}:${filterInfo.value}`
        );
      }
    );

    return params;
  }

  modifyFilterDate(filterValue: any) {
    let modifiedDate: string;
    let finalDates: any[] = [];

    filterValue.forEach((date: Date) => {
      const dateType =
        filterValue.indexOf(date) === 0 ? "00-00-01" : "23-59-59";
      if (date) {
        modifiedDate = moment(date).format(`YYYY-MM-DD-${dateType}`);
      } else {
        modifiedDate = moment().format(`YYYY-MM-DD-${dateType}`);
      }
      finalDates.push(modifiedDate);
    });

    return finalDates;
  }

  getExportOrders(filters: any): Observable<PaginatedResult<ExportOrder>> {
    let params = this.generateFilters(filters);

    return this.http.get<PaginatedResult<ExportOrder>>(this.baseUrl, {
      params,
    });
  }

  createExportOrder(listOfRawMaterials: any[]): Observable<ExportOrder> {
    return this.http.post<ExportOrder>(this.baseUrl, {
      exportParcelRawMaterialList: listOfRawMaterials,
    });
  }

  updateExportOrder(exportOrderId: number, data: any): Observable<ExportOrder> {
    return this.http.put<ExportOrder>(`${this.baseUrl}/${exportOrderId}`, {
      ...data,
    });
  }

  finishExportOrder(orderId: string): Observable<ExportOrder> {
    return this.http.put<ExportOrder>(`${this.baseUrl}/${orderId}/finish`, {});
  }

  startExportOrder(orderId: string): Observable<ExportOrder> {
    return this.http.put<ExportOrder>(`${this.baseUrl}/${orderId}/start`, {});
  }

  getOrders(
    orderId: string,
    filters: any
  ): Observable<PaginatedResult<ExportOrderById>> {
    let params = this.generateFilters(filters);

    return this.http.get<PaginatedResult<ExportOrderById>>(
      `${this.baseUrl}/${orderId}/orders`,
      {
        params,
      }
    );
  }
}
