import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Type,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { SelectItem } from "primeng/components/common/selectitem";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ResolveSelectPipe } from "./resolve-select.pipe";
import { BehaviorSubject } from "rxjs";
import { filter, take } from "rxjs/operators";

@Component({
  selector: "dulo-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef((): Type<SelectComponent> => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() optionLabel: string;
  @Input() rawValues: any[];
  @Input() defaultLabel: string = "Odaberi";
  @Input() placeholder: string;
  @Input() hasNone: boolean = true;
  @Input() style: any;
  @Input() elClass: any;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Output() onChange = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();
  @Input() filter: boolean = false;
  @Input() values: SelectItem[];
  valuesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(null);
  selectedItem: any | [];
  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  get SelectedItem(): any {
    return this.selectedItem;
  }

  @Input() set SelectedItem(value: any) {
    this.selectedItem = value;
    this.onModelChange(value);
    this.onModelTouched();
  }

  constructor(
    private resolveSelectPipe: ResolveSelectPipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["rawValues"] && changes["rawValues"].currentValue) {
      this.values = this.resolveSelectPipe.transform(
        this.rawValues,
        this.hasNone
      );
      this.valuesLoaded.next(true);
    }
  }

  writeValue(obj: any): void {
    if (this.values) {
      this.selectedItem = obj;
      this.cdr.markForCheck();
    } else {
      this.valuesLoaded
        .pipe(
          filter((v): boolean => v != null),
          take(1)
        )
        .subscribe((): void => {
          this.selectedItem = obj;
          this.cdr.markForCheck();
        });
    }
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngOnDestroy(): void {
    this.valuesLoaded.unsubscribe();
  }
}
