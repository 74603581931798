/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paginator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/paginator/paginator.ngfactory";
import * as i3 from "primeng/components/paginator/paginator";
import * as i4 from "./paginator.component";
var styles_PaginatorComponent = [i0.styles];
var RenderType_PaginatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginatorComponent, data: {} });
export { RenderType_PaginatorComponent as RenderType_PaginatorComponent };
export function View_PaginatorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-paginator", [], null, [[null, "onPageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onPageChange" === en)) {
        var pd_0 = (_co.pageChangeHandler.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Paginator_0, i2.RenderType_Paginator)), i1.ɵdid(1, 114688, null, 0, i3.Paginator, [i1.ChangeDetectorRef], { pageLinkSize: [0, "pageLinkSize"], style: [1, "style"], styleClass: [2, "styleClass"], alwaysShow: [3, "alwaysShow"], currentPageReportTemplate: [4, "currentPageReportTemplate"], showCurrentPageReport: [5, "showCurrentPageReport"], totalRecords: [6, "totalRecords"], first: [7, "first"], rows: [8, "rows"] }, { onPageChange: "onPageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageLinkSize; var currVal_1 = _co.elStyle; var currVal_2 = _co.elClass; var currVal_3 = _co.alwaysShow; var currVal_4 = _co.currentPageReportTemplate; var currVal_5 = _co.showCurrentPageReport; var currVal_6 = _co.totalRecords; var currVal_7 = _co.page; var currVal_8 = _co.pageSize; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_PaginatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-paginator", [], null, null, null, View_PaginatorComponent_0, RenderType_PaginatorComponent)), i1.ɵdid(1, 114688, null, 0, i4.PaginatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginatorComponentNgFactory = i1.ɵccf("dulo-paginator", i4.PaginatorComponent, View_PaginatorComponent_Host_0, { totalRecords: "totalRecords", pageSize: "pageSize", page: "page", pageLinkSize: "pageLinkSize", elClass: "elClass", elStyle: "elStyle", alwaysShow: "alwaysShow", showPageLinks: "showPageLinks", showCurrentPageReport: "showCurrentPageReport", currentPageReportTemplate: "currentPageReportTemplate" }, { pageChangeHandler: "pageChangeHandler" }, []);
export { PaginatorComponentNgFactory as PaginatorComponentNgFactory };
