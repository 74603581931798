<ng-container *ngIf="!multiple">
  <p-dropdown
    [optionLabel]="optionLabel"
    [styleClass]="elClass"
    [options]="values"
    [(ngModel)]="SelectedItem"
    [placeholder]="placeholder"
    [style]="style"
    [disabled]="disabled"
    [ariaFilterLabel]="defaultLabel"
    (onChange)="onChange.emit($event)"
    (onClick)="onClick.emit($event)"
    [filter]="filter"
  ></p-dropdown>
</ng-container>
<ng-container *ngIf="multiple">
  <p-multiSelect
    [optionLabel]="optionLabel"
    [options]="values"
    [(ngModel)]="SelectedItem"
    [style]="style"
    [disabled]="disabled"
    [defaultLabel]="defaultLabel"
    (onChange)="onChange.emit($event)"
  ></p-multiSelect>
</ng-container>
