<div class="ui-inputgroup">
  <ng-container *ngTemplateOutlet="preTemplate"></ng-container>
  <span class="ui-float-label" *ngIf="floatablePlaceholder">
    <input
      type="text"
      class="form-control {{ this.Value ? 'ui-state-filled' : '' }} {{
        styleClass
      }}"
      pInputText
      id="float-input"
      [disabled]="disabled"
      [(ngModel)]="Value"
      [pKeyFilter]="regex"
      [pValidateOnly]="enableTypingIfErrors"
      (input)="onInput.emit($event)"
      (change)="onChange.emit($event)"
    />
    <label for="float-input">{{ placeholder }}</label>
  </span>
  <input
    type="text"
    class="form-control {{ styleClass }}"
    pInputText
    [placeholder]="placeholder"
    *ngIf="!floatablePlaceholder"
    [disabled]="disabled"
    [(ngModel)]="Value"
    [pKeyFilter]="regex"
    [pValidateOnly]="enableTypingIfErrors"
    (input)="onInput.emit($event)"
    (change)="onChange.emit($event)"
  />
  <ng-container *ngTemplateOutlet="postTemplate"></ng-container>
</div>
