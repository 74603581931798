/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./orders.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/table/table.component.ngfactory";
import * as i3 from "../../../components/table/table.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../components/generators/excel-generator/excel-generator.service";
import * as i6 from "./orders.component";
import * as i7 from "../../../_services/export-order.service";
import * as i8 from "primeng/components/dynamicdialog/dynamicdialog-config";
var styles_OrdersComponent = [i0.styles];
var RenderType_OrdersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrdersComponent, data: {} });
export { RenderType_OrdersComponent as RenderType_OrdersComponent };
export function View_OrdersComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dulo-table", [], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(1, 245760, null, 0, i3.TableComponent, [i4.HttpClient, i5.ExcelGeneratorService, i1.ChangeDetectorRef], { rows: [0, "rows"], paginator: [1, "paginator"], lazy: [2, "lazy"], request: [3, "request"], columns: [4, "columns"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 10; var currVal_1 = true; var currVal_2 = true; var currVal_3 = _co.orders$; var currVal_4 = _co.cols; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_OrdersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-orders", [], null, null, null, View_OrdersComponent_0, RenderType_OrdersComponent)), i1.ɵdid(1, 114688, null, 0, i6.OrdersComponent, [i7.ExportOrderService, i8.DynamicDialogConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrdersComponentNgFactory = i1.ɵccf("app-orders", i6.OrdersComponent, View_OrdersComponent_Host_0, {}, {}, []);
export { OrdersComponentNgFactory as OrdersComponentNgFactory };
