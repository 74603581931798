<span class='app-orders-manufacturing'>
    <div class='container-fluid my-5'>
        <div class='row d-flex justify-content-center'>
            <dulo-button class='m-2' label='Skini zip' (click)='downloadZip()'></dulo-button>
            <dulo-button class='m-2' label='Skini nalepnice' (click)='downloadStickers()'></dulo-button>
        </div>
        <dulo-card [mainTemplate]='ordersTemplate' [headerTemplate]='ordersHeaderTemplate'>
            <ng-template #ordersHeaderTemplate>
                <h4>Broj porudžbina za danas: {{ordersCount}}</h4>
                <h4>Broj proizvoda za danas: {{orderItemsCount}}</h4>
            </ng-template>
            <ng-template #ordersTemplate>
                <div>
                    <dulo-table
                    [columns]="cols"
                    [rows]='currentFilters.rows'
                    [lazy]='true'
                    [request]='orderRequest'
                    [filterable]='false'
                    [sortable]='false'
                    [mapData]='mapData'
                    [expandable]='true'
                    [expandedTemplate]='orderExpandedTemplate'
                    (onChange)='setCurrentFilters($event)'
                    #ordersTable>
                    <ng-template #orderExpandedTemplate let-order>
                        <ng-container *ngIf='order'>
                            <h2>Stavke porudžbine</h2>
                            <dulo-table
                            id='order-items-table'
                            [columns]="orderItemsCols"
                            [values]="order.order_item_list"
                            [filterable]='false'
                            [paginator]='false'>
                            </dulo-table>
                        </ng-container>
                    </ng-template>
                    </dulo-table>
                </div>
            </ng-template>
        </dulo-card>
    </div>
</span>
