import Swal from "sweetalert2";
import * as i0 from "@angular/core";
export class PopupService {
    fire(settings) {
        return "customClass" in settings
            ? Swal.fire(settings)
            : Swal.fire(Object.assign({}, settings, { cancelButtonText: "Otkaži", reverseButtons: true, customClass: {
                    confirmButton: "btn btn-small btn-submit popup-confirm-button",
                    cancelButton: "btn btn-small btn-cancel popup-cancel-button",
                    icon: "swal-icon",
                    title: "swal-title",
                    popup: "popup",
                    content: "swal-content",
                    actions: "swal-actions",
                } }));
    }
    fireToast(settings) {
        return "customClass" in settings
            ? Swal.fire(settings)
            : Swal.fire(Object.assign({ toast: true, position: "top-end", timer: 2000 }, settings, { customClass: {
                    confirmButton: "btn btn-medium btn-submit popup-confirm-button",
                    cancelButton: "btn btn-outline-primary popup-cancel-button",
                    icon: "popup-icon",
                    title: "popup-title",
                    popup: "popup",
                    content: "popup-content",
                    actions: "popup-actions",
                } }));
    }
    fireYesNo(yesText, noText, settings) {
        return "customClass" in settings
            ? Swal.fire(settings)
            : Swal.fire(Object.assign({ showCancelButton: true, cancelButtonText: noText, confirmButtonText: yesText, focusCancel: true }, settings, { customClass: {
                    confirmButton: "btn btn-medium btn-submit popup-confirm-button",
                    cancelButton: "btn btn-outline-primary popup-cancel-button",
                    icon: "popup-icon",
                    title: "popup-title",
                    popup: "popup",
                    content: "popup-content",
                    actions: "popup-actions",
                } }));
    }
}
PopupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopupService_Factory() { return new PopupService(); }, token: PopupService, providedIn: "root" });
export var MessageType;
(function (MessageType) {
    MessageType[MessageType["POST"] = 0] = "POST";
    MessageType[MessageType["PUT"] = 1] = "PUT";
    MessageType[MessageType["DELETE"] = 2] = "DELETE";
    MessageType[MessageType["ERROR_GET"] = 3] = "ERROR_GET";
    MessageType[MessageType["ERROR_POST"] = 4] = "ERROR_POST";
    MessageType[MessageType["ERROR_PUT"] = 5] = "ERROR_PUT";
    MessageType[MessageType["ERROR_DELETE"] = 6] = "ERROR_DELETE";
    MessageType[MessageType["CONFIRM_DELETE"] = 7] = "CONFIRM_DELETE";
})(MessageType || (MessageType = {}));
