import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectButtonComponent } from './select-button.component';
import { SelectButtonModule as PrimengSelectButtonModule } from 'primeng/selectbutton';
import { SharedModule } from '../shared.module';
import { FormsModule } from '@angular/forms';
import { ResolveSelectButtonPipe } from './resolve-select-button.pipe';

@NgModule({
  declarations: [SelectButtonComponent, ResolveSelectButtonPipe],
  imports: [CommonModule, SharedModule, FormsModule, PrimengSelectButtonModule],
  exports: [SelectButtonComponent],
  providers: [ResolveSelectButtonPipe],
})
export class SelectButtonModule {}
