<p-autoComplete
  [(ngModel)]="selected"
  [suggestions]="filteredValues"
  [minLength]="minLength"
  [placeholder]="placeholder"
  (completeMethod)="filter($event)"
  [field]="field"
  [scrollHeight]="scrollHeight"
  [delay]="delay"
  [tabindex]="tabIndex"
  [autoHighlight]="true"
  [forceSelection]="forceSelection"
  [style]="style"
  [disabled]="disabled"
>
</p-autoComplete>
