import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Subject, BehaviorSubject } from "rxjs";
import { LocalStorageService } from "../components/local-storage-service";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  private ACCESS_TOKEN_KEY: string = "accessToken";
  private REFRESH_TOKEN_KEY: string = "refreshToken";
  private jwtHelper: JwtHelperService = new JwtHelperService();

  private accessTokens = new BehaviorSubject<string>(this.getAccessToken());
  public accessTokens$ = this.accessTokens.asObservable();

  constructor(private localStorageService: LocalStorageService) {}

  stillLoggedIn(): boolean {
    return this.isUserLoggedIn() && !this.isTokenExpired();
  }

  isUserLoggedIn(): boolean {
    return this.getAccessToken() !== null;
  }

  getAccessToken(): any {
    return this.localStorageService.getItem(this.ACCESS_TOKEN_KEY);
  }

  getRefreshToken(): any {
    return this.localStorageService.getItem(this.REFRESH_TOKEN_KEY);
  }

  getDecodedAccessToken(): any {
    return this.jwtHelper.decodeToken(this.getAccessToken());
  }

  saveAccessToken(accessToken: string): any {
    this.localStorageService.setItem(this.ACCESS_TOKEN_KEY, accessToken);
    this.accessTokens.next(accessToken);
  }

  removeAccessToken(): void {
    if (localStorage.getItem(this.ACCESS_TOKEN_KEY)) {
      localStorage.removeItem(this.ACCESS_TOKEN_KEY);
      this.accessTokens.next(null);
    }
  }
  removeRefreshToken(): void {
    if (localStorage.getItem(this.REFRESH_TOKEN_KEY)) {
      localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    }
  }

  saveRefreshToken(refreshToken: string): any {
    this.localStorageService.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  }

  getAccessTokenExpirationDate(): any {
    return this.jwtHelper.getTokenExpirationDate(this.getAccessToken());
  }

  isTokenExpired(): any {
    return this.jwtHelper.isTokenExpired(this.getAccessToken());
  }

  getUserId(): string {
    return this.jwtHelper.decodeToken(this.getAccessToken()).user_id;
  }
}
