<span class="dulo-button {{ styleClass }} {{ wrapperStyleClass }}">
  <p-button
    label="{{ label }}"
    [type]="type"
    [disabled]="disabled"
    [icon]="icon"
    [iconPos]="iconPosition"
    styleClass="{{ styleClass }}"
    (click)="handleClick($event)"
  ></p-button>
</span>
