import { ChangeDetectionStrategy, Component } from "@angular/core";
import { OrderService } from "src/app/_services/order.service";

@Component({
  selector: "app-item-manufacturing-info",
  templateUrl: "./item-manufacturing-info.component.html",
  styleUrls: ["./item-manufacturing-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemManufacturingInfoComponent {
  itemManufacturingData$ = this.orderService.getItemsManufacturingData();
  country = window.location.href.includes(".rs") ? "Srbiju" : "Hrvatsku ";

  constructor(private orderService: OrderService) {}
}
