import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'dulo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() header: string;
  @Input() subheader: string;
  hasTemplates: boolean;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() mainTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() styleClass: string;
  constructor() {}

  ngOnInit(): void {
    this.hasTemplates =
      this.headerTemplate !== null || this.footerTemplate !== null;
  }
}
