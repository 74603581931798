import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  forwardRef,
  Type,
  ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'dulo-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<RadioButtonComponent> => RadioButtonComponent,
      ),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor {
  @Input() name: string;
  @Input() value: any;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() styleClass: string;
  namespace: string = 'radio-button';
  @Output() onClick = new EventEmitter<any>();

  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  selected: boolean;

  get Selected(): any {
    return this.selected;
  }
  @Input() set Selected(value: any) {
    this.selected = value;
    this.onModelChange(value);
    this.onModelTouched();
    this.cdr.markForCheck();
  }
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  writeValue(obj: any): void {
    this.Selected = obj;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
