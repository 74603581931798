<span class='dulo-accordion'>
    <ng-container *ngIf='template'>
        <p-accordion [activeIndex]='activeIndex'>
            <p-accordionTab *ngFor="let tab of tabs; let i = index" [header]="tab.name" [class]='tab.meta?.class'>
                <ng-container *ngTemplateOutlet='template;context: {$implicit: tab.data, index: i}'></ng-container>
            </p-accordionTab>
        </p-accordion>
    </ng-container>
    <ng-container *ngIf='!template'>
        <p-accordion [activeIndex]='activeIndex'>
            <p-accordionTab *ngFor="let key of keys; let i = index" [header]="key">
                <ng-container *ngTemplateOutlet="tabsMap[key]"
                ></ng-container>
            </p-accordionTab>
        </p-accordion>
    </ng-container>
</span>
