import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  TemplateRef,
} from "@angular/core";
import { TableColumn } from "src/app/components/table/table-column";
import { OrderService } from "src/app/_services/order.service";
import { PaginatedResult } from "src/app/components/table/paginated-result";
import { Order } from "src/app/_view-models/order";
import { Observable, forkJoin } from "rxjs";
import { TableComponent } from "src/app/components/table/table.component";
import { SubSink } from "subsink";
import { PopupService } from "src/app/components/popup/popup.service";
import * as Excel from "exceljs/dist/exceljs.min.js";
import { tap } from "rxjs/operators";
import { saveAs } from "file-saver";
import { CompanyService } from "src/app/_services/company.service";
import { FulfillmentTypeTranslation } from "src/app/enums/fulfillment-type-translation.enum";

@Component({
  selector: "app-orders-shipping",
  templateUrl: "./orders-shipping.component.html",
  styleUrls: ["./orders-shipping.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersShippingComponent implements OnDestroy {
  @ViewChild(TableComponent, { static: false }) table: TableComponent;
  type = "ready_for_shipping";
  public get fulfillmentTypeTranslation(): typeof FulfillmentTypeTranslation {
    return FulfillmentTypeTranslation;
  }
  @ViewChild("deliveryInfoTemplate", { static: true })
  deliveryInfoTemplate: TemplateRef<any>;
  cols: TableColumn[];

  orders: Order[];

  currentFilters = {
    first: 0,
    rows: 50,
    sortOrder: 1,
    sortField: "",
    filters: {},
  };

  companies$ = this.companyService.getAll().pipe(
    tap((companies) => {
      this.currentFilters.filters["shippingCompanyId"] = companies[0].id;
    })
  );

  orderRequest = ((event): Observable<PaginatedResult<Order>> => {
    this.currentFilters = {
      ...this.currentFilters,
      ...event,
      filters: {
        ...this.currentFilters.filters,
        ...event.filters,
      },
    };
    return this.orderService.getAll(this.type, this.currentFilters).pipe(
      tap((res: PaginatedResult<Order>) => {
        if (res.current_results) {
          this.orders = res.current_results.map(this.mapData);
        }
      })
    );
  }).bind(this);
  subs = new SubSink();

  ngOnInit() {
    this.cols = [
      {
        header: "OrderID",
        field: "id",
        filterType: "string",
        type: "alphanumeric",
        width: "5%",
      },
      {
        header: "Datum",
        field: "manufacturing_date",
        filterType: "date",
        type: "date",
        width: "15%",
        options: {
          format: "dd.MM.yyyy",
        },
      },
      {
        header: "Ime i prezime",
        field: "fullName",
        filterType: "string",
        type: "alphanumeric",
        width: "15%",
      },
      {
        header: "Adresa",
        field: "fullAddress",
        filterType: "string",
        type: "alphanumeric",
        width: "15%",
      },
      {
        header: "Tip isporuke",
        field: "deliveryInfo",
        filterType: "custom",
        type: "custom",
        width: "15%",
        options: {
          template: this.deliveryInfoTemplate,
        },
      },
      {
        header: "Broj pošiljke",
        field: "deliveryCode",
        filterType: "string",
        type: "alphanumeric",
        width: "15%",
      },
    ];
  }

  mapData = (order, index) => {
    const hasPicture =
      order.order_item_list.filter((oi) => oi.product_type_id > 6).length > 0;
    const hasClothes =
      order.order_item_list.filter((oi) => oi.product_type_id <= 6).length > 0;
    return {
      ...order,
      deliveryNote: order.comment
        ? order.comment
        : "Pozvati primaoca pre dostave",
      packageType:
        hasPicture && hasClothes
          ? "Odeća i ram - LOMLJIVO"
          : hasPicture
          ? "Ram - LOMLJIVO"
          : "Odeća",
      fullName: order["first_name"] + " " + order["last_name"],
      fullAddress:
        order["address"] +
        " " +
        order["number"] +
        " " +
        (order["sub_number"] ? "(" + order["sub_number"] + ")" : "") +
        "/" +
        (order["floor"] ? order["floor"] + "/" : "") +
        order["apartment"] +
        ", " +
        order["postal_code"] +
        " " +
        order["city"],
    };
  };
  constructor(
    private orderService: OrderService,
    private companyService: CompanyService,
    private popupService: PopupService
  ) {}

  markOrdersAsSent(): void {
    this.subs.add(
      forkJoin(
        this.table.selectedValues.map((o) => this.orderService.ship(o.id))
      ).subscribe((res) => {
        this.popupService.fireToast({
          type: "success",
          title: "Porudžbine označene kao poslate",
        });
        this.table.load(this.currentFilters);
      })
    );
  }

  setCurrentFilters(event) {
    this.currentFilters = event;
  }

  setCompanyId(companyId) {
    this.currentFilters.filters["shippingCompanyId"] = companyId;
    this.table.load(this.currentFilters);
  }

  async downloadShippingExcels() {
    if (
      this.orders.filter(
        (o) =>
          o.shipping_carrier_code === "POSTA" &&
          (o.buyer_shipping_cost + o.price === 0 || o.ransom_collected)
      ).length > 0
    ) {
      await this.downloadShippingExcellWithoutRansom();
    }
    await this.downloadShippingExcellWithRansom();
  }

  async downloadShippingExcellWithoutRansom() {
    const workbook = new Excel.Workbook();
    workbook.creater = "Brendly Partner Platform";
    workbook.lastModifiedBy = "Brendly Partner Platform";
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet();
    const ROWS_OFFSET = 6;
    const BANK_CALL_NUMBER = 189;
    let row = null;
    let index = 0;
    this.orders.forEach((o) => {
      if (o.shipping_carrier_code !== "POSTA") {
        return;
      }
      if (!(o.buyer_shipping_cost + o.price === 0 || o.ransom_collected)) {
        return;
      }
      row = worksheet.getRow(ROWS_OFFSET + index);
      row.values = [
        index,
        o.deliveryCode,
        o.first_name + " " + o.last_name,
        o.address,
        o.number,
        o.sub_number,
        o.floor,
        o.apartment,
        o.city,
        o.postal_code,
        o.first_name + " " + o.last_name,
        o.phone_number,
        0,
        0,
        o.weight,
        o.packageType,
        `[${o.id}] ${o.deliveryNote}`,
        o.seller_bank_account_number,
        BANK_CALL_NUMBER,
      ];
      index++;
    });

    if (row) {
      row.eachCell((c) => {
        c.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    }
    const data = await workbook.xlsx.writeBuffer();
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    saveAs(blob, "posta-bez-otkupa.xls");
  }

  async downloadShippingExcellWithRansom() {
    const workbook = new Excel.Workbook();
    workbook.creater = "Brendly Partner Platform";
    workbook.lastModifiedBy = "Brendly Partner Platform";
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet();
    const ROWS_OFFSET = 6;
    const BANK_CALL_NUMBER = 189;
    let row = null;
    let index = 0;
    this.orders.forEach((o) => {
      if (o.shipping_carrier_code !== "POSTA") {
        return;
      }

      if (o.buyer_shipping_cost + o.price === 0 || o.ransom_collected) {
        return;
      }
      row = worksheet.getRow(ROWS_OFFSET + index);
      row.values = [
        index,
        o.deliveryCode,
        o.first_name + " " + o.last_name,
        o.address,
        o.number,
        o.sub_number,
        o.floor,
        o.apartment,
        o.city,
        o.postal_code,
        o.first_name + " " + o.last_name,
        o.phone_number,
        this.isKiM(o.postal_code)
          ? Math.min(o.price + o.buyer_shipping_cost, 2000)
          : o.price + o.buyer_shipping_cost,
        o.price + o.buyer_shipping_cost,
        o.weight,
        o.packageType,
        `[${o.id}] ${o.deliveryNote}`,
        o.seller_bank_account_number,
        BANK_CALL_NUMBER,
      ];
      index++;
    });

    row.eachCell((c) => {
      c.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    const data = await workbook.xlsx.writeBuffer();
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    saveAs(blob, "posta-sa-otkupom.xls");
  }

  changeType(type: string): void {
    this.type = type;
    this.table.load(this.currentFilters);
  }

  isKiM(postalCode) {
    return postalCode.startsWith("38");
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
