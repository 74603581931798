import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./_components/login/login.component";
import { OrdersManufacturingComponent } from "./_components/orders-manufacturing/orders-manufacturing.component";
import { SuppliesComponent } from "./_components/supplies/supplies.component";
import { FormModule } from "./components/form/form.module";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { RouteUrlInterceptor } from "./_interceptors/route-url.interceptor";
import { HttpMessageInterceptor } from "./_interceptors/http-message.interceptor";
import { LoadingSpinnerInterceptor } from "./_interceptors/loading-spinner.interceptor";
import { PopupService } from "./components/popup/popup.service";
import { LoaderService } from "./components/loader/loader.service";
import { TableModule } from "./components/table/table.module";
import { OrderComponent } from "./_components/order/order.component";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "./components/button/button.module";
import { CardModule } from "./components/card/card.module";
import { TextareaModule } from "./components/textarea/textarea.module";
import { SwitchModule } from "./components/switch/switch.module";
import { LoaderModule } from "./components/loader/loader.module";
import { LogoutDirective } from "./_directives/logout.directive";
import { TooltipModule } from "./components/tooltip/tooltip.module";
import { OrdersShippingComponent } from "./_components/orders-shipping/orders-shipping.component";
import { SuppliesTakeoverComponent } from "./_components/supplies-takeover/supplies-takeover.component";
import { PrimerComponent } from "./_components/primer/primer.component";
import { OrdersManufacturingMobileComponent } from "./_components/orders-manufacturing-mobile/orders-manufacturing-mobile.component";
import { AccordionModule } from "./components/accordion/accordion.module";
import { RadioButtonModule } from "./components/radio-button/radio-button.module";
import { PaginatorModule } from "./components/paginator/paginator.module";
import { InputModule } from "./components/input/input.module";
import { ReactiveFormsModule } from "@angular/forms";
import { SelectModule } from "./components/select/select.module";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import * as Raven from "raven-js";
import { environment } from "src/environments/environment";
import { ManufacturedProductsComponent } from "./_components/manufactured-products/manufactured-products.component";
import { ExportOrdersComponent } from "./_components/export-orders/export-orders.component";
import { OrdersComponent } from "./_components/export-orders/orders/orders.component";
import { DialogService } from "primeng/api";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { ItemManufacturingInfoComponent } from "./_components/item-manufacturing-info/item-manufacturing-info.component";
import { JwtRenewInterceptor } from "./_interceptors/jwt-renew.interceptor";
import { JwtAuthorizationInterceptorService } from "./_interceptors/jwt-authorization-interceptor.service";
import { UpdateExportOrderComponent } from "./_components/export-orders/update-export-order/update-export-order.component";
import { OrderRawMaterialsComponent } from "./_components/export-orders/order-raw-materials/order-raw-materials.component";

Raven.config(environment.sentryDSNKey).install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err.originalError);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

export function getToken() {
  return localStorage.getItem("accessToken");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrdersManufacturingComponent,
    SuppliesComponent,
    OrderComponent,
    LogoutDirective,
    OrdersShippingComponent,
    SuppliesTakeoverComponent,
    PrimerComponent,
    OrdersManufacturingMobileComponent,
    ManufacturedProductsComponent,
    ExportOrdersComponent,
    OrdersComponent,
    ItemManufacturingInfoComponent,
    UpdateExportOrderComponent,
    OrderRawMaterialsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TooltipModule,
    LoaderModule,
    SwitchModule,
    TextareaModule,
    CardModule,
    ButtonModule,
    DialogModule,
    FormModule,
    TableModule,
    AccordionModule,
    RadioButtonModule,
    PaginatorModule,
    ReactiveFormsModule,
    SelectModule,
    DynamicDialogModule,
  ],
  providers: [
    TranslateService,
    PopupService,
    DialogService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RouteUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtRenewInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAuthorizationInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMessageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingSpinnerInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  entryComponents: [
    OrderComponent,
    OrdersComponent,
    UpdateExportOrderComponent,
    OrderRawMaterialsComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
