import { NgModule } from '@angular/core';
import { FieldErrorsComponent } from './field-errors.component';
import { SharedModule } from '../shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [FieldErrorsComponent],
  exports: [FieldErrorsComponent],
})
export class FieldsErrorModule {}
