import { NgModule } from '@angular/core';
import { RadioButtonComponent } from './radio-button.component';
import { RadioButtonModule as PrimengRadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from '../shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RadioButtonComponent],
  imports: [SharedModule, FormsModule, PrimengRadioButtonModule],
  exports: [RadioButtonComponent],
})
export class RadioButtonModule {}
