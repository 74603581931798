<p-table
  #dt
  class="dulo-table"
  [columns]="columns"
  [value]="values"
  [(selection)]="selectedValues"
  [paginator]="paginator"
  [rows]="rows"
  [responsive]="true"
  [reorderableColumns]="true"
  [dataKey]="columns[0].field"
  [lazy]="lazy"
  (onLazyLoad)="load($event)"
  [totalRecords]="totalRecords"
  [loading]="(loading$ | async)!"
  (onRowReorder)="onRowReorderHandler($event)"
>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngIf="expandable" style="width: 3em" />
      <col
        *ngFor="let col of columns"
        [style.width]="col.width !== '' ? col.width : 'auto'"
      />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="caption">
    <div *ngIf="exportable">
      <dulo-button
        icon="pi pi-file-o"
        iconPos="left"
        label="csv"
        (click)="dt.exportCSV()"
      ></dulo-button>
      <dulo-button
        icon="pi pi-file-excel"
        iconPos="left"
        label="excel"
        (click)="exportExcel()"
      ></dulo-button>
      <dulo-button
        icon="pi pi-file-pdf"
        iconPos="left"
        label="pdf"
        (click)="exportPdf()"
      ></dulo-button>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr class="header-labels">
      <ng-container *ngIf="rowControlsPosition === 'start'">
        <th *ngIf="expandable"></th>
        <th *ngIf="selectable">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngIf="reorderable"></th>
      </ng-container>

      <ng-container *ngIf="sortable">
        <th
          *ngFor="let col of columns"
          pReorderableColumn
          [pSortableColumn]="col.field"
        >
          {{
            col.translationKey ? (col.translationKey | translate) : col.header
          }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
      <ng-container *ngIf="!sortable">
        <th *ngFor="let col of columns" pReorderableColumn>
          {{
            col.translationKey ? (col.translationKey | translate) : col.header
          }}
        </th>
      </ng-container>
      <ng-container *ngIf="rowControlsPosition === 'end'">
        <th *ngIf="expandable"></th>
        <th *ngIf="selectable">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngIf="reorderable"></th>
      </ng-container>
      <th *ngIf="buttons"></th>
    </tr>
    <tr *ngIf="filterable" class="filters">
      <ng-container *ngIf="rowControlsPosition === 'start'">
        <th *ngIf="expandable"></th>
        <th *ngIf="selectable"></th>
        <th *ngIf="reorderable"></th>
      </ng-container>
      <th *ngFor="let col of columns" [ngSwitch]="col.filterType">
        <dulo-input
          *ngSwitchCase="'string'"
          (onInput)="dt.filter($event.target.value, col.field, 'contains')"
        >
        </dulo-input>
        <ng-container *ngSwitchCase="'date'">
          <dulo-date-picker
            (datesChosen)="dt.filter($event, col.field, 'dateBetween')"
            selectionMode="range"
          ></dulo-date-picker>
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
          <dulo-number-range-input
            [minPlaceholder]="'Od'"
            [maxPlaceholder]="'Do'"
            (range)="dt.filter($event, col.field, 'numberBetween')"
          >
          </dulo-number-range-input>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <dulo-select
            [values]="col.options?.values"
            [rawValues]="col.options?.rawValues | async"
            (onChange)="dt.filter($event.value, col.field, 'in')"
            [multiple]="col.options.multiple"
            [hasNone]="col.options.hasNone"
          >
          </dulo-select>
        </ng-container>
      </th>
      <ng-container *ngIf="rowControlsPosition === 'end'">
        <th *ngIf="expandable"></th>
        <th *ngIf="selectable"></th>
        <th *ngIf="reorderable"></th>
      </ng-container>
      <th *ngIf="buttons"></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-index="rowIndex"
    let-expanded="expanded"
  >
    <tr [pSelectableRow]="rowData" class="{{ rowData?.meta?.rowClass }}">
      <ng-container *ngIf="rowControlsPosition === 'start'">
        <td *ngIf="expandable">
          <a href="#" [pRowToggler]="rowData">
            <i
              id="row-index-{{ index }}"
              [ngClass]="
                expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
              "
            ></i>
          </a>
        </td>
        <td *ngIf="selectable">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngIf="reorderable" [pReorderableRow]="index">
          <i class="fa fa-bars" pReorderableRowHandle></i>
        </td>
      </ng-container>

      <td *ngFor="let col of columns" [ngSwitch]="col.type">
        <span class="ui-column-title">{{ col.header }}</span>
        <ng-container *ngSwitchCase="'alphanumeric'">
          {{ rowData[col.field] }}
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          {{ rowData[col.field] | date : col.options?.format }}
        </ng-container>
        <ng-container *ngSwitchCase="'boolean'">
          <dulo-checkbox
            disabled
            [checked]="rowData[col.field]"
          ></dulo-checkbox>
        </ng-container>
        <ng-container *ngSwitchCase="'file'">
          <div *ngIf="!col?.options?.hidden">
            {{ getFileName(rowData[col.field]) }}
          </div>
          <a
            *ngIf="col?.options?.downloadable"
            [href]="rowData[col.field]"
            download="getFileName(rowData[col.field])"
          >
            <i class="pi pi-download"></i>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="'image'">
          <div *ngIf="!col?.options?.hidden">
            <img
              [src]="rowData[col.field]"
              [alt]="getFileName(rowData[col.field])"
            />
          </div>
          <a
            *ngIf="col?.options?.downloadable"
            [href]="rowData[col.field]"
            download="getFileName(rowData[col.field])"
          >
            <i class="pi pi-download"></i>
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="'custom'">
          <ng-container
            *ngTemplateOutlet="
              col.options?.template;
              context: { $implicit: rowData }
            "
          ></ng-container>
        </ng-container>
      </td>

      <ng-container *ngIf="rowControlsPosition === 'end'">
        <td *ngIf="expandable">
          <a href="#" [pRowToggler]="rowData">
            <i
              [ngClass]="
                expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
              "
            ></i>
          </a>
        </td>
        <td *ngIf="selectable">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngIf="reorderable" [pReorderableRow]="index">
          <i class="fa fa-bars" pReorderableRowHandle></i>
        </td>
      </ng-container>
      <td *ngIf="buttons">
        <ng-container
          *ngTemplateOutlet="
            buttonsTemplate;
            context: { $implicit: rowData, index: index }
          "
        ></ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="rowexpansion"
    let-rowData
    let-columns="columns"
    let-index="rowIndex"
  >
    <tr>
      <td [attr.colspan]="columns.length + extraColumnsCount">
        <ng-container
          *ngTemplateOutlet="
            expandedTemplate;
            context: { $implicit: rowData, index: index }
          "
        ></ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + extraColumnsCount">
        <ng-container
          [ngIf]="emptyMessageTemplate"
          *ngTemplateOutlet="emptyMessageTemplate"
        ></ng-container>
        <ng-container *ngIf="!emptyMessageTemplate">
          {{ emptyMessage }}
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
