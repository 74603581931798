import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class LocalStorageService {
    constructor() {
        this.values = new BehaviorSubject(localStorage);
        this.values$ = this.values.asObservable();
    }
    setItem(key, value) {
        localStorage.setItem(key, value);
        this.values.next(localStorage);
    }
    removeItem(key) {
        localStorage.removeItem(key);
    }
    getItem(key) {
        return localStorage.getItem(key);
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
