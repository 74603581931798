<span class="ui-float-label" *ngIf="floatablePlaceholder">
  <textarea
    pInputTextarea
    class="{{ styleClass }}"
    [rows]="rows"
    [cols]="cols"
    [(ngModel)]="value"
    [autoResize]="autoResize"
    [disabled]="disabled"
    if="float-input"
  ></textarea>
  <label for="float-input">{{ placeholder }}</label>
</span>
<textarea
  pInputTextarea
  class="{{ styleClass }}"
  [rows]="rows"
  [cols]="cols"
  [(ngModel)]="value"
  [autoResize]="autoResize"
  [disabled]="disabled"
  [placeholder]="placeholder"
  *ngIf="!floatablePlaceholder"
></textarea>
