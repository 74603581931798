import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../components/loader/loader.service";
export const SkipLoadingSpinnerInterceptor = 'SkipLoadingSpinner';
export class LoadingSpinnerInterceptor {
    constructor(loaderService) {
        this.loaderService = loaderService;
    }
    intercept(req, next) {
        this.finished = false;
        setTimeout(() => {
            if (!this.finished) {
                this.loaderService.show();
            }
        }, 800);
        return next.handle(req).pipe(finalize(() => {
            this.finished = true;
            this.loaderService.hide();
        }));
    }
}
LoadingSpinnerInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingSpinnerInterceptor_Factory() { return new LoadingSpinnerInterceptor(i0.ɵɵinject(i1.LoaderService)); }, token: LoadingSpinnerInterceptor, providedIn: "root" });
