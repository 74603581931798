/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-raw-materials.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./order-raw-materials.component";
import * as i4 from "primeng/components/dynamicdialog/dynamicdialog-config";
var styles_OrderRawMaterialsComponent = [i0.styles];
var RenderType_OrderRawMaterialsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderRawMaterialsComponent, data: {} });
export { RenderType_OrderRawMaterialsComponent as RenderType_OrderRawMaterialsComponent };
function View_OrderRawMaterialsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.rawMaterial; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.weight; _ck(_v, 4, 0, currVal_1); }); }
export function View_OrderRawMaterialsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "main", [["class", "p-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderRawMaterialsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rawMaterials; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OrderRawMaterialsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-raw-materials", [], null, null, null, View_OrderRawMaterialsComponent_0, RenderType_OrderRawMaterialsComponent)), i1.ɵdid(1, 114688, null, 0, i3.OrderRawMaterialsComponent, [i4.DynamicDialogConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderRawMaterialsComponentNgFactory = i1.ɵccf("app-order-raw-materials", i3.OrderRawMaterialsComponent, View_OrderRawMaterialsComponent_Host_0, {}, {}, []);
export { OrderRawMaterialsComponentNgFactory as OrderRawMaterialsComponentNgFactory };
