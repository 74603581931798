import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public readonly values = new BehaviorSubject<any>(localStorage);
  public readonly values$ = this.values.asObservable();

  constructor() {}

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
    this.values.next(localStorage);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getItem(key: string): string {
    return localStorage.getItem(key);
  }
}
