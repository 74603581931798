<div class="ui-inputgroup">
  <ng-container *ngTemplateOutlet="preTemplate"></ng-container>
  <span class="ui-float-label" *ngIf="floatablePlaceholder">
    <input
      type="password"
      class="form-control"
      pPassword
      id="float-input"
      [disabled]="disabled"
      [showPassword]="showPassword"
      [(ngModel)]="Value"
      [feedback]="feedback"
      promptLabel="{{ 'password.prompt' | translate }}"
      weakLabel="{{ 'password.weak' | translate }}"
      mediumLabel="{{ 'password.medium' | translate }}"
      strongLabel="{{ 'password.strong' | translate }}"
      (input)="onInput.emit($event)"
      #input
    />
    <label for="float-input">{{ placeholder }}</label>
  </span>
  <input
    #input
    type="password"
    pPassword
    [placeholder]="placeholder"
    *ngIf="!floatablePlaceholder"
    [disabled]="disabled"
    [showPassword]="showPassword"
    [(ngModel)]="Value"
    [feedback]="feedback"
    promptLabel="{{ 'password.prompt' | translate }}"
    weakLabel="{{ 'password.weak' | translate }}"
    mediumLabel="{{ 'password.medium' | translate }}"
    strongLabel="{{ 'password.strong' | translate }}"
    (input)="onInput.emit($event)"
  />
  <span
    class="ui-inputgroup-addon"
    [ngStyle]="passwordEyeStyle"
    (click)="toggleShowPassword()"
    ><i class="pi pi-eye" *ngIf="!showPassword"></i
    ><i class="pi pi-eye-slash" *ngIf="showPassword"></i
  ></span>
  <ng-container *ngTemplateOutlet="postTemplate"></ng-container>
</div>
