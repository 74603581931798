import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OrdersManufacturingComponent } from "./_components/orders-manufacturing/orders-manufacturing.component";
import { LoginComponent } from "./_components/login/login.component";
import { SuppliesComponent } from "./_components/supplies/supplies.component";
import { OrdersShippingComponent } from "./_components/orders-shipping/orders-shipping.component";
import { SuppliesTakeoverComponent } from "./_components/supplies-takeover/supplies-takeover.component";
import { PrimerComponent } from "./_components/primer/primer.component";
import { OrdersManufacturingMobileComponent } from "./_components/orders-manufacturing-mobile/orders-manufacturing-mobile.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AlreadyLoggedInGuard } from "./_guards/already-logged-in.guard";
import { ManufacturedProductsComponent } from "./_components/manufactured-products/manufactured-products.component";
import { ExportOrdersComponent } from "./_components/export-orders/export-orders.component";
import { ItemManufacturingInfoComponent } from "./_components/item-manufacturing-info/item-manufacturing-info.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AlreadyLoggedInGuard],
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "orders/manufacturing",
        component: OrdersManufacturingComponent,
      },
      {
        path: "orders/manufacturing-mobile",
        component: OrdersManufacturingMobileComponent,
      },
      {
        path: "orders/manufactured-products",
        component: ManufacturedProductsComponent,
      },
      {
        path: "orders/shipping",
        component: OrdersShippingComponent,
      },
      {
        path: "primer",
        component: PrimerComponent,
      },
      {
        path: "supplies",
        component: SuppliesComponent,
      },
      {
        path: "supplies-takeover",
        component: SuppliesTakeoverComponent,
      },
      {
        path: "export-orders",
        component: ExportOrdersComponent,
      },
      {
        path: "item-manufacturing-info",
        component: ItemManufacturingInfoComponent,
      },
    ],
  },
  {
    path: "**",
    redirectTo: "login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
