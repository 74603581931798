import { HttpParams } from "@angular/common/http";
import * as moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SupplyService {
    constructor(http) {
        this.http = http;
        this.baseUrl = "partner/product_type_items";
    }
    countFreeOrderItems() {
        return this.http.get(`partner/work_orders/count_free_order_items`);
    }
    startWorkOrder(workOrderId) {
        return this.http.put(`partner/work_orders/${workOrderId}/start`, {});
    }
    finishWorkOrder(workOrderId) {
        return this.http.put(`partner/work_orders/${workOrderId}/finish`, {});
    }
    getWorkOrders(filters) {
        let params = this.generateFilters(filters);
        return this.http.get(`partner/work_orders`, {
            params,
        });
    }
    modifyFilterDate(filterValue) {
        let modifiedDate;
        let finalDates = [];
        filterValue.forEach((date) => {
            const dateType = filterValue.indexOf(date) === 0 ? "00-00-01" : "23-59-59";
            if (date) {
                modifiedDate = moment(date).format(`YYYY-MM-DD-${dateType}`);
            }
            else {
                modifiedDate = moment().format(`YYYY-MM-DD-${dateType}`);
            }
            finalDates.push(modifiedDate);
        });
        return finalDates;
    }
    generateFilters(filters) {
        let params = new HttpParams()
            .set("page", filters.first / filters.rows + "")
            .set("size", filters.rows + "")
            .set("sort_by", filters.sortField ? filters.sortField : "created")
            .set("sort_order", "desc");
        Object.entries(filters.filters).forEach((filter) => {
            const { 0: name, 1: filterInfo } = filter;
            switch (name) {
                case "id":
                    filterInfo.matchMode = "eq";
                    break;
                case "state":
                    filterInfo.matchMode = "in";
                    break;
                default:
                    break;
            }
            if (filterInfo.matchMode === "dateBetween") {
                filterInfo.value = this.modifyFilterDate(filterInfo.value);
                filterInfo.matchMode = "btn";
            }
            params = params.set(name, `${filterInfo.matchMode}:${filterInfo.value}`);
        });
        return params;
    }
    createWorkOrder(workOrderInfo) {
        return this.http.post(`partner/work_orders`, workOrderInfo);
    }
    getAll() {
        return this.http.get(`${this.baseUrl}`);
    }
    getAllForTakeover(date) {
        let mm = date.getMonth() + 1 + "";
        let dd = date.getDate() + "";
        if (mm.length === 1) {
            mm = "0" + mm;
        }
        if (dd.length === 1) {
            dd = "0" + dd;
        }
        const yy = date.getFullYear();
        return this.http.get(`partner/orders/items_for_manufacturing`, {
            params: new HttpParams().set("manufacturing_date", yy + "-" + mm + "-" + dd + "-00-00-00"),
        });
    }
    update(id, amount) {
        return this.http.put(`${this.baseUrl}/${id}/amount`, { amount });
    }
}
SupplyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupplyService_Factory() { return new SupplyService(i0.ɵɵinject(i1.HttpClient)); }, token: SupplyService, providedIn: "root" });
