<p-dialog
  header="Kreiraj radni nalog"
  [(visible)]="display"
  [style]="{ width: '30vw' }"
>
  <div class="m-4">
    <form [formGroup]="form" (ngSubmit)="createWorkOrder()">
      <dulo-radio-button
        formControlName="workOrderCreationOption"
        *ngFor="let workOrderOption of workOrderOptions"
        (onClick)="changeType(workOrderOption.value)"
        [Selected]="type"
        [value]="workOrderOption.value"
        [label]="workOrderOption.label"
        class="mr-2"
      >
      </dulo-radio-button>
      <div class="mt-4 align-items-center d-flex">
        <dulo-input
          formControlName="value"
          #inputElement
          [placeholder]="
            type === 'BY_PRODUCTS_AMOUNT'
              ? 'npr. 100'
              : 'npr. 21000, 21105, 22099'
          "
        ></dulo-input>

        <dulo-button
          type="submit"
          class="ml-2"
          label="Kreiraj radni nalog"
        ></dulo-button>
      </div>
      <p
        class="text-danger font-weight-bold"
        style="font-size: 10px"
        *ngIf="form.get('value').touched && !form.get('value').valid"
      >
        Ovo polje je obavezno.
      </p>
    </form>
  </div>
</p-dialog>

<div class="m-4 d-flex justify-content-between">
  <div>
    <dulo-button (click)="show()" label="Kreiraj radni nalog"> </dulo-button>
    <p style="font-size: 15px" class="mt-2 mb-0 h6">
      Ukupan broj dostupnih proizvoda za proizvodnju:
      <app-loading-spinner *ngIf="isLoading | async"></app-loading-spinner>
      <span *ngIf="!(isLoading | async)" class="font-weight-bold h5">{{
        freeOrderItems$ | async
      }}</span>
    </p>
  </div>

  <dulo-button label="Osveži" (click)="reloadTable()"></dulo-button>
</div>

<ng-container>
  <dulo-table
    [columns]="cols"
    [rows]="10"
    [paginator]="true"
    [lazy]="true"
    [buttons]="true"
    [buttonsTemplate]="buttonsTemplate"
    [request]="workOrders$"
  >
    <ng-template #buttonsTemplate let-workOrder>
      <div
        class="d-flex justify-content-start flex-wrap"
        *ngIf="workOrder.state !== workOrderState.PROCESSING_FAILED"
      >
        <dulo-button
          class="mb-1 mr-1"
          (click)="downloadStickers(workOrder.stickersFileUrl)"
          label="Stikeri"
          [disabled]="workOrder.state === workOrderState.PROCESSING"
          [duloTooltip]="
            workOrder.state === workOrderState.PROCESSING
              ? 'Morate sačekati da se završi obrada'
              : ''
          "
        ></dulo-button>
        <dulo-button
          class="mb-1 mr-1"
          (click)="downloadPrepress(workOrder.prepressFileUrl)"
          label="Pripreme"
          [disabled]="workOrder.state === workOrderState.PROCESSING"
          [duloTooltip]="
            workOrder.state === workOrderState.PROCESSING
              ? 'Morate sačekati da se završi obrada'
              : ''
          "
        ></dulo-button>
        <dulo-button
          class="mb-1 mr-1"
          (click)="downloadPrepress(workOrder.productListFileUrl)"
          label="Spisak"
          [disabled]="workOrder.state === workOrderState.PROCESSING"
          [duloTooltip]="
            workOrder.state === workOrderState.PROCESSING
              ? 'Morate sačekati da se završi obrada'
              : ''
          "
        ></dulo-button>
        <dulo-button
          class="mb-1 mr-1"
          (click)="downloadPrepress(workOrder.shippingLabelsFileUrl)"
          label="Adresnice"
          [disabled]="workOrder.state === workOrderState.PROCESSING"
          [duloTooltip]="
            workOrder.state === workOrderState.PROCESSING
              ? 'Morate sačekati da se završi obrada'
              : ''
          "
        ></dulo-button>
        <dulo-button
          class="mb-1 mr-1"
          *ngIf="workOrder.shippingLabelsExtraFileUrl"
          (click)="downloadPrepress(workOrder.shippingLabelsExtraFileUrl)"
          label="Dodatne adresnice"
          [disabled]="workOrder.state === workOrderState.PROCESSING"
          [duloTooltip]="
            workOrder.state === workOrderState.PROCESSING
              ? 'Morate sačekati da se završi obrada'
              : ''
          "
        ></dulo-button>
        <dulo-button
          class="mb-1 mr-1"
          [class.wo-ready]="workOrder.state !== workOrderState.STARTED"
          [class.wo-started]="workOrder.state === workOrderState.STARTED"
          style="min-width: 112px"
          (click)="handleWorkOrderCreation(workOrder.state, workOrder.id)"
          [duloTooltip]="
            workOrder.state === workOrderState.PROCESSING
              ? 'Morate sačekati da se završi obrada'
              : ''
          "
          [style.visibility]="
            workOrder.state !== workOrderState.FINISHED ? 'visible' : 'hidden'
          "
          [disabled]="
            workOrder.state !== workOrderState.READY &&
            workOrder.state !== workOrderState.STARTED
          "
          [label]="
            workOrder.state === workOrderState.STARTED
              ? 'Završi nalog '
              : 'Započni nalog'
          "
        ></dulo-button>
      </div>
    </ng-template>
  </dulo-table>
</ng-container>

<ng-template #stateTemplate let-workOrder>
  <div class="d-flex">
    <p class="m-0">{{ workOrder.stateTranslated }}</p>
    <div *ngIf="workOrder.state === workOrderState.PROCESSING" class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>
