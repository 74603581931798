import { enableProdMode } from "@angular/core";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { GIT_COMMIT_ID } from "./git-commit-id";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
Sentry.init({
    release: GIT_COMMIT_ID,
    dsn: environment.sentryDSNKey,
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new TracingIntegrations.BrowserTracing({
            tracingOrigins: [environment.apiUrl],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch((err) => console.error(err));
