import { NgModule } from '@angular/core';
import { AutoCompleterComponent } from './auto-completer.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [AutoCompleterComponent],
  imports: [
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    AutoCompleteModule,
    HttpClientModule,
  ],
  exports: [AutoCompleterComponent],
})
export class AutoCompleterModule {}
