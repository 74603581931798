import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/api";
import { ExportOrderService } from "src/app/_services/export-order.service";

@Component({
  selector: "app-update-export-order",
  templateUrl: "./update-export-order.component.html",
  styleUrls: ["./update-export-order.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateExportOrderComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private exportOrderService: ExportOrderService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      importCost: 0,
      exportCost: 0,
    });
  }

  updateExportOrder(): void {
    this.exportOrderService
      .updateExportOrder(this.config.data.id, this.form.value)
      .subscribe(() => {
        this.form.reset();
        this.ref.close(true);
      });
  }
}
