import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'resolveSelect',
})
export class ResolveSelectPipe implements PipeTransform {
  transform(value: any[], hasNone: boolean = true): SelectItem[] {
    const values = [];
    if (hasNone) {
      values.push({
        label: this.translate.instant('select.none'),
        value: null,
      });
    }
    values.push(
      ...value.map(
        (v: any): SelectItem => {
          return {
            label: v.name,
            value: v.id,
            disabled: v.disabled,
          };
        },
      ),
    );
    return values;
  }

  constructor(private translate: TranslateService) {}
}
