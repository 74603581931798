import { Injectable } from "@angular/core";
import { Observable, throwError, Subject, defer, BehaviorSubject } from "rxjs";
import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";

import {
  catchError,
  concatMap,
  tap,
  take,
  switchMap,
  finalize,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../_services/auth.service";
import {
  SKIP_INTERCEPTORS,
  SKIP_JWT_RENEW_INTERCEPTOR,
} from "./skip-interceptors";
import { JwtService } from "../_services/jwt.service";

@Injectable({
  providedIn: "root",
})
export class JwtRenewInterceptor implements HttpInterceptor {
  requestInProgress = false;
  tokenSubject = new Subject();

  constructor(
    private authService: AuthService,
    private router: Router,
    private jwtService: JwtService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get(SKIP_INTERCEPTORS) === SKIP_INTERCEPTORS) {
      return next.handle(req);
    }

    if (
      req.headers.get(SKIP_JWT_RENEW_INTERCEPTOR) === SKIP_JWT_RENEW_INTERCEPTOR
    ) {
      return next.handle(req);
    }

    if (this.requestInProgress && req.headers.get("refresh") !== "refresh") {
      return this.tokenSubject.pipe(
        take(1),
        switchMap((token) => {
          return next.handle(req);
        })
      );
    }

    if (
      this.jwtService.isUserLoggedIn() &&
      this.jwtService.isTokenExpired() &&
      !this.requestInProgress
    ) {
      this.requestInProgress = true;
      return this.authService
        .renewRefreshToken({
          access_token: this.jwtService.getAccessToken(),
          user_id: this.jwtService.getUserId(),
          refresh_token: this.jwtService.getRefreshToken(),
        })
        .pipe(
          concatMap((res: any) => {
            this.jwtService.saveRefreshToken(res.refresh_token);
            this.jwtService.saveAccessToken(res.access_token);
            this.tokenSubject.next(res.access_token);
            return next.handle(req).pipe(
              tap(() => {
                this.tokenSubject = new Subject();
              })
            );
          }),
          catchError((err) => {
            this.jwtService.removeRefreshToken();
            this.jwtService.removeAccessToken();
            this.router.navigate(["/login"]);

            return throwError(err);
          }),
          finalize(() => {
            this.requestInProgress = false;
          })
        );
    }
    return next.handle(req);
  }
}
