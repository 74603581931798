<p-radioButton
  [name]="name"
  [value]="value"
  [label]="label"
  [disabled]="disabled"
  [styleClass]="styleClass"
  [(ngModel)]="Selected"
  (onClick)="
    onClick.emit({
      originalEvent: $event,
      value: value
    })
  "
></p-radioButton>
