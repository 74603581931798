import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  forwardRef,
  Type,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'dulo-number-range-input',
  templateUrl: './number-range-input.component.html',
  styleUrls: ['./number-range-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<NumberRangeInputComponent> => NumberRangeInputComponent,
      ),
      multi: true,
    },
  ],
})
export class NumberRangeInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild('min', { static: true }) minInput: InputComponent;
  @ViewChild('max', { static: true }) maxInput: InputComponent;
  @Input() disabled: boolean = false;
  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};
  @Input() minRangeValue = Number.MIN_VALUE;
  @Input() maxRangeValue = Number.MAX_VALUE;
  @Input() minPlaceholder: string;
  @Input() maxPlaceholder: string;
  @Input() onlyPositive: boolean;

  minValue = new BehaviorSubject<number>(null);
  maxValue = new BehaviorSubject<number>(null);

  value: { min: number; max: number } = {
    min: this.minRangeValue,
    max: this.maxRangeValue,
  };

  get Value(): { min: number; max: number } {
    return this.value;
  }

  set Value(value: { min: number; max: number }) {
    this.value = value;
    this.minValue.next(value.min);
    this.maxValue.next(value.max);
    this.onModelChange(this.value);
    this.onModelTouched();
  }

  @Output('range') range = new EventEmitter<{ min: number; max: number }>();

  constructor() {}

  ngOnInit(): void {}

  check(): void {
    const min = this.minInput.value ? +this.minInput.value : this.minRangeValue;
    const max = this.maxInput.value ? +this.maxInput.value : this.maxRangeValue;
    this.Value = {
      min: min,
      max: max,
    };
    this.range.emit({
      min: min,
      max: max,
    });
  }

  writeValue(value: { min: number; max: number }): void {
    if (!value) {
      return;
    }
    this.value = value;
    this.minValue.next(this.value.min);
    this.maxValue.next(this.value.max);
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
