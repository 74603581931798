import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  forwardRef,
  Type,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dulo-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<SwitchComponent> => SwitchComponent,
      ),
      multi: true,
    },
  ],
})
export class SwitchComponent implements OnInit, ControlValueAccessor {
  checked: boolean;
  @Input() disabled: boolean = false;
  @Input() style: any;
  @Input() label: string;
  @Output() onChange = new EventEmitter<any>();
  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  get Checked(): boolean {
    return this.checked;
  }

  @Input() set Checked(value: boolean) {
    this.checked = value;
    this.onModelChange(value);
    this.onModelTouched();
    this.cdr.markForCheck();
  }
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  writeValue(checked: boolean): void {
    this.checked = checked;
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
