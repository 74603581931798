import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { SkipRouteUrlInterceptor } from "../_interceptors/route-url.interceptor";
import { PaginatedResult } from "../components/table/paginated-result";
import { Order } from "../_view-models/order";
import * as moment from "moment";
import { JwtService } from "./jwt.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  baseUrl = "partner/orders";
  constructor(private http: HttpClient, private jwtService: JwtService) {}

  getActiveWorkOrders() {
    return this.http.get(
      "partner/work_orders?sort_by=id&sort_order=desc&state=in:STARTED"
    );
  }

  getWorkOrders(workOrderId: string, filters: any) {
    let params = new HttpParams()
      .set("page", filters.first / filters.rows + "")
      .set("size", filters.rows + "")
      .set("sort_by", filters.sortField ? filters.sortField : "created")
      .set("sort_order", +filters.sortOrder === 1 ? "asc" : "desc");

    return this.http.get(`partner/work_orders/${workOrderId}/orders`, {
      params,
    });
  }

  getAll(type: string, filters: any): Observable<PaginatedResult<Order>> {
    let params = new HttpParams()
      .set("page", filters.first / filters.rows + "")
      .set("size", filters.rows + "")
      .set("sort_by", filters.sortField ? filters.sortField : "created")
      .set("sort_order", +filters.sortOrder === 1 ? "asc" : "desc");
    switch (type) {
      case "manufacturing": {
        params = params
          .set(
            "manufacturing_date",
            "eq:" + moment().format("YYYY-MM-DD-00-00-00")
          )
          .set("state", "in:ACCEPTED,MANUFACTURING");
        break;
      }
      case "manufactured-products": {
        Object.entries(filters.filters).forEach(
          (filter: [string, string]) =>
            (params = params.set(filter[0], filter[1]))
        );
        break;
      }
      case "ready_for_shipping": {
        params = params
          .set(
            "manufacturing_date",
            "lt:" + moment().add(1, "days").format("YYYY-MM-DD-00-00-00")
          )
          .set("state", "in:READY_FOR_SHIPPING");
        break;
      }
      case "manufacturing-ready_for_shipping": {
        params = params
          .set(
            "manufacturing_date",
            "lt:" + moment().add(1, "days").format("YYYY-MM-DD-00-00-00")
          )
          .set("state", "in:ACCEPTED,MANUFACTURING,READY_FOR_SHIPPING");
        break;
      }
      case "manufacturing-shipping": {
        params = params
          .set(
            "manufacturing_date",
            "eq:" + moment().format("YYYY-MM-DD-00-00-00")
          )
          .set("state", "in:ACCEPTED,MANUFACTURING,READY_FOR_SHIPPING");
        break;
      }
      case "shipped": {
        params = params.set("state", "in:SHIPPING");
        break;
      }
      default: {
        break;
      }
    }

    if (filters.filters["shippingCompanyId"]) {
      params = params.set(
        "shippingCompanyId",
        "eq:" + filters.filters["shippingCompanyId"]
      );
    }

    return this.http.get<PaginatedResult<Order>>(this.baseUrl, {
      params,
    });
  }

  finishManufacturing(id: number) {
    return this.http.get(`${this.baseUrl}/${id}/finish_manufacturing`);
  }

  startManufacturing(id: number) {
    return this.http.get(`${this.baseUrl}/${id}/start_manufacturing`);
  }

  repeatOrderItemManufacturing(id: string) {
    return this.http.put(`${this.baseUrl}/${id}/repeat_manufacturing`, {});
  }

  toggleOrderItemIsManufactured(id) {
    return this.http.put(`${this.baseUrl}/${id}/manufactured`, {});
  }

  getPhoto(url: string) {
    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders().set(SkipRouteUrlInterceptor, ""),
    });
  }

  ship(id: string) {
    return this.http.get(`${this.baseUrl}/${id}/start_shipping`);
  }

  updatePrimers(orderItems: { id: number; primerUsed: string }[]) {
    return this.http.put(
      `partner/order_items/primer_used`,
      orderItems.map((oi) => {
        return {
          id: oi.id,
          primer_used: oi.primerUsed,
        };
      })
    );
  }

  getOrderItemsCount() {
    let params = new HttpParams();
    params = params.set(
      "manufacturing_date",
      "eq:" + moment().format("YYYY-MM-DD-00-00-00")
    );
    return this.http.get(`partner/order_items/count`, {
      params: params,
    });
  }

  getShippingLabel(orderId) {
    return this.http.get(`${this.baseUrl}/${orderId}/get_shipping_label`, {
      observe: "response",
      responseType: "blob",
    });
  }

  getItemsManufacturingData(): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/items_for_manufacturing_data/${
        this.jwtService.getDecodedAccessToken().user_id
      }`
    );
  }
}
