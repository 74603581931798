import { NgModule } from '@angular/core';
import { CardComponent } from './card.component';
import { SharedModule } from '../shared.module';
import { CardModule as PrimeNgCardModule } from 'primeng/card';

@NgModule({
  declarations: [CardComponent],
  imports: [SharedModule, PrimeNgCardModule],
  exports: [CardComponent],
})
export class CardModule {}
