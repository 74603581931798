<span class="dulo-file-upload">
  <p-fileUpload
    [name]="name"
    [url]="url"
    [auto]="auto"
    [method]="method"
    [multiple]="multiple"
    [maxFileSize]="maxFileSize"
    [fileLimit]="fileLimit"
    [files]="files"
    [accept]="accept"
    invalidFileSizeMessageSummary=""
    invalidFileSizeMessageDetail=""
    invalidFileTypeMessageSummary=""
    fileTypeMessageSummary=""
    invalidFileLimitMessageSummary=""
    invalidFileLimitMessageDetail=""
    chooseLabel="{{ 'fileUpload.choose' | translate }}"
    cancelLabel="{{ 'fileUpload.cancel' | translate }}"
    uploadLabel="{{ 'fileUpload.upload' | translate }}"
    [showUploadButton]="!formUpload"
    [showCancelButton]="multiple"
    [customUpload]="formUpload"
    (onSelect)="select($event)"
    (onClear)="clear($event)"
    (onRemove)="remove($event)"
    (onProgress)="onProgress.emit($event)"
    (onUpload)="onUpload.emit($event)"
  ></p-fileUpload>
</span>
