import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
  isLoading = new BehaviorSubject<boolean>(false);

  show(): void {
    if (!this.isLoading.getValue()) {
      this.isLoading.next(true);
    }
    this.isLoading.next(true);
  }
  hide(): void {
    if (this.isLoading.getValue()) {
      this.isLoading.next(false);
    }
    this.isLoading.next(false);
  }
}
