import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import { FormModel } from "src/app/components/form/form-model";
import { FormComponent } from "src/app/components/form/form.component";
import { SubSink } from "subsink";
import { FormEvent } from "src/app/components/form/form-event";
import { AuthService } from "src/app/_services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild(FormComponent, { static: true }) form: FormComponent;
  namespace = "components.login";
  fields: FormModel[] = [
    {
      type: "input",
      formControlName: "email",
      translationKey: `Korisničko ime`,
      options: {
        hideLabel: true,
        floatablePlaceholder: true,
      },
    },
    {
      type: "password",
      formControlName: "password",
      translationKey: `Lozinka`,
      options: {
        hideLabel: true,
        floatablePlaceholder: true,
      },
    },
    {
      type: "button",
      translationKey: `Uloguj se`,
      formControlName: "login",
      options: {
        label: "Login",
        class: "btn-primary",
        onClick: this.login.bind(this),
      },
    },
  ];
  subs = new SubSink();
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  login(event: FormEvent): void {
    const data = {
      email: this.form.getValues().email,
      password: this.form.getValues().password,
    };
    this.subs.add(
      this.authService.login(data).subscribe((tokenData): void => {
        this.router.navigate(["supplies-takeover"]);
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
