import { NgModule } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';
import { SharedModule } from '../shared.module';
import { TooltipModule as PrimeNgTooltipModule, Tooltip } from 'primeng/tooltip';


@NgModule({
  declarations: [TooltipDirective],
  imports: [
    SharedModule,
    PrimeNgTooltipModule,
  ],
  exports: [TooltipDirective],
  providers: [Tooltip]
})
export class TooltipModule { }
