import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  forwardRef,
  Type,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dulo-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<DatePickerComponent> => DatePickerComponent,
      ),
      multi: true,
    },
  ],
})
export class DatePickerComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() style = { width: '100%' };
  @Input() selectionMode: 'single' | 'multiple' | 'range';
  @Input() disabled: boolean;
  @Input() defaultDate: Date = new Date();
  value: Date;
  @ViewChild(Calendar, { static: true }) calendar: Calendar;
  @Output() datesChosen = new EventEmitter<any>();
  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  dateChosenSubscription: Subscription;
  calendarOnSelectSubscription: Subscription;
  constructor() {}

  ngOnInit(): void {
    if (this.selectionMode === 'single') {
      this.calendarOnSelectSubscription = this.calendar.onSelect.subscribe(
        (res: any): void => this.datesChosen.emit([res]),
      );
    }
    this.dateChosenSubscription = this.datesChosen.subscribe(
      (res: any[]): void => {
        if (!res) {
          this.onModelChange('');
          return;
        }
        if (this.selectionMode === 'single') {
          this.onModelChange(res[0]);
        } else {
          this.onModelChange(res);
        }
      },
    );
  }

  showOkButton(): boolean {
    return this.selectionMode != 'single';
  }

  isRangeChosen(): boolean {
    if (this.selectionMode === 'range') {
      return (
        this.calendar.inputFieldValue &&
        this.calendar.inputFieldValue.split('-').length === 2
      );
    }
    return true;
  }

  finish(): void {
    this.calendar.hideOverlay();
    let splitter: string;
    if (this.selectionMode === 'range') {
      splitter = '-';
    } else if (this.selectionMode === 'multiple') {
      if (this.calendar.inputFieldValue === '') {
        this.cancel();
        return;
      } else {
        splitter = ',';
      }
    }
    const dates = this.calendar.inputFieldValue
      .split(splitter)
      .map((ds: string): Date => this.createDateFromDateString(ds));
    this.datesChosen.emit(dates);
  }

  cancel(): void {
    this.calendar.onClearButtonClick({});
    this.datesChosen.emit(null);
  }

  createDateFromDateString(dateS: string): Date {
    const dateParts = dateS.split('.');
    return new Date(
      parseInt(dateParts[2], 10),
      parseInt(dateParts[1], 10) - 1,
      parseInt(dateParts[0], 10),
    );
  }

  writeValue(obj: any): void {
    if (obj != '') {
      this.value = new Date(obj);
      if (this.selectionMode === 'single') {
        this.datesChosen.emit([this.value]);
      } else {
        this.datesChosen.emit(this.value);
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.dateChosenSubscription.unsubscribe();
    if (this.calendarOnSelectSubscription) {
      this.calendarOnSelectSubscription.unsubscribe();
    }
  }
}
