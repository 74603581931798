import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogService } from "primeng/api";
import { tap } from "rxjs/operators";
import { ExportOrderService } from "src/app/_services/export-order.service";
import { ExportOrder } from "src/app/_view-models/export-order";
import { PopupService } from "src/app/components/popup/popup.service";
import { TableColumn } from "src/app/components/table/table-column";
import { TableComponent } from "src/app/components/table/table.component";
import { ExportOrderStateTranslation } from "src/app/enums/export-order-state-translation.enum";
import { ExportOrderState } from "src/app/enums/export-order-state.enum";
import { OrdersComponent } from "./orders/orders.component";
import { UpdateExportOrderComponent } from "./update-export-order/update-export-order.component";
import { OrderRawMaterialsComponent } from "./order-raw-materials/order-raw-materials.component";

@Component({
  selector: "app-export-orders",
  templateUrl: "./export-orders.component.html",
  styleUrls: ["./export-orders.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportOrdersComponent implements OnInit {
  @ViewChild(TableComponent, { static: true })
  table: TableComponent;
  @ViewChild("stateTemplate", { static: true })
  stateTemplate: TemplateRef<any>;
  display = false;
  cols: TableColumn[];
  form: FormGroup;

  pageSize = 40;
  currentFilters = {
    first: 0,
    rows: this.pageSize,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  exportOrderOptions = [
    {
      label: "Presek stanja",
      value: "Presek stanja",
    },
  ];

  rawMaterials = [
    { label: "PACKAGE_EXPORT_SMALL", value: "PACKAGE_EXPORT_SMALL" },
    { label: "PACKAGE_EXPORT_LARGE", value: "PACKAGE_EXPORT_LARGE" },
    { label: "STICKER_HUB_ZAGREB", value: "STICKER_HUB_ZAGREB" },
  ];

  exportOrders$ = ((event) => {
    this.currentFilters = {
      ...this.currentFilters,
      ...event,
      filters: {
        ...event.filters,
      },
    };
    return this.exportOrderService.getExportOrders(this.currentFilters).pipe(
      tap((res) => {
        res.current_results.forEach((result) => {
          switch (result.state) {
            case ExportOrderState.CREATED:
              result.stateTranslated = ExportOrderStateTranslation.CREATED;
              break;

            case ExportOrderState.IN_TRANSIT:
              result.stateTranslated = ExportOrderStateTranslation.IN_TRANSIT;
              break;

            case ExportOrderState.DELIVERED:
              result.stateTranslated = ExportOrderStateTranslation.DELIVERED;
              break;

            default:
              break;
          }

          return result;
        });
      })
    );
  }).bind(this);

  public get exportOrderState(): typeof ExportOrderState {
    return ExportOrderState;
  }

  get formRawMaterials() {
    return this.form.controls["rawMaterials"] as FormArray;
  }

  constructor(
    private exportOrderService: ExportOrderService,
    private fb: FormBuilder,
    private popupService: PopupService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.cols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        type: "alphanumeric",
        width: "5%",
      },
      {
        header: "Datum kreiranja",
        field: "created",
        filterType: "date",
        type: "date",
        width: "15%",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Datum početka",
        field: "startTime",
        filterType: "date",
        type: "date",
        width: "15%",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Datum zatvaranja",
        field: "endTime",
        filterType: "date",
        type: "date",
        width: "15%",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Status izvoznog naloga",
        field: "state",
        filterType: "select",
        type: "custom",
        width: "15%",
        options: {
          multiple: true,
          template: this.stateTemplate,
          values: [
            {
              value: ExportOrderState.CREATED,
              label: ExportOrderStateTranslation.CREATED,
            },
            {
              value: ExportOrderState.DELIVERED,
              label: ExportOrderStateTranslation.DELIVERED,
            },
            {
              value: ExportOrderState.IN_TRANSIT,
              label: ExportOrderStateTranslation.IN_TRANSIT,
            },
          ],
        },
      },
    ];

    this.form = this.fb.group({
      exportOrderCreationOption: [],
      rawMaterials: this.fb.array([]),
    });

    this.addRawMaterial();
  }

  reloadTable(): void {
    this.table.load(this.currentFilters);
  }

  createExportOrder(): void {
    this.exportOrderService
      .createExportOrder(this.form.value.rawMaterials)
      .subscribe(() => {
        this.reloadTable();
        this.form.reset();
      });
    this.display = false;
  }

  addRawMaterial(): void {
    const rawMaterialGroup = this.fb.group({
      rawMaterial: [this.rawMaterials[0].value, Validators.required],
      weight: [0, Validators.required],
    });

    this.formRawMaterials.push(rawMaterialGroup);
  }

  removeRawMaterial(id: number): void {
    this.formRawMaterials.removeAt(id);
  }

  handleExportOrderCreation(exportOrderState: string, exportOrderId: string) {
    if (exportOrderState === ExportOrderState.IN_TRANSIT) {
      this.popupService
        .fire({
          title: `Da li ste sigurni da želite da završite izvozni nalog ${exportOrderId}?`,
          type: "warning",
          showCancelButton: true,
        })
        .then((res) => {
          if (res.value) {
            this.exportOrderService.finishExportOrder(exportOrderId).subscribe(
              () => {
                this.popupService.fire({
                  title: "Uspešno završen izvozni nalog",
                  type: "success",
                });
                this.reloadTable();
              },
              (err) => {
                this.popupService.fire({
                  title: "Greška",
                  text: err,
                  type: "error",
                });
              }
            );
          }
        });
    } else if (exportOrderState === ExportOrderState.CREATED) {
      this.popupService
        .fire({
          title: `Da li ste sigurni da želite da započnete izvozni nalog ${exportOrderId}?`,
          type: "warning",
          showCancelButton: true,
        })
        .then((res) => {
          if (res.value) {
            this.exportOrderService.startExportOrder(exportOrderId).subscribe(
              () => {
                this.popupService.fire({
                  title: "Uspešno započet izvozni nalog",
                  type: "success",
                });
                this.reloadTable();
              },
              (err) => {
                this.popupService.fire({
                  title: "Greška",
                  text: err,
                  type: "error",
                });
              }
            );
          }
        });
    }
  }

  showRawMaterials(rawMaterials: any): void {
    this.dialogService.open(OrderRawMaterialsComponent, {
      data: { rawMaterials },
      width: "40vw",
      dismissableMask: true,
    });
  }

  showOrders(exportOrder: ExportOrder): void {
    this.dialogService.open(OrdersComponent, {
      data: { exportOrder },
      width: "90%",
      dismissableMask: true,
    });
  }

  showUpdateOrderForm(exportOrder: ExportOrder): void {
    const ref = this.dialogService.open(UpdateExportOrderComponent, {
      data: { id: exportOrder.id },
      width: "30vw",
      dismissableMask: true,
    });

    ref.onClose.subscribe((value) => {
      if (value) {
        this.reloadTable();
      }
    });
  }

  show() {
    this.display = true;
  }
}
