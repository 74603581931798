/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./orders-manufacturing.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/table/table.component.ngfactory";
import * as i3 from "../../components/table/table.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../components/generators/excel-generator/excel-generator.service";
import * as i6 from "@angular/common";
import * as i7 from "../../components/button/button.component.ngfactory";
import * as i8 from "../../components/button/button.component";
import * as i9 from "../../components/card/card.component.ngfactory";
import * as i10 from "../../components/card/card.component";
import * as i11 from "./orders-manufacturing.component";
import * as i12 from "../../_services/order.service";
import * as i13 from "../../_services/jwt.service";
var styles_OrdersManufacturingComponent = [i0.styles];
var RenderType_OrdersManufacturingComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_OrdersManufacturingComponent, data: {} });
export { RenderType_OrdersManufacturingComponent as RenderType_OrdersManufacturingComponent };
function View_OrdersManufacturingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Broj porud\u017Ebina za danas: ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Broj proizvoda za danas: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ordersCount; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.orderItemsCount; _ck(_v, 3, 0, currVal_1); }); }
function View_OrdersManufacturingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stavke porud\u017Ebine"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "dulo-table", [["id", "order-items-table"]], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(4, 245760, null, 0, i3.TableComponent, [i4.HttpClient, i5.ExcelGeneratorService, i1.ChangeDetectorRef], { paginator: [0, "paginator"], columns: [1, "columns"], values: [2, "values"], filterable: [3, "filterable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.orderItemsCols; var currVal_2 = _v.parent.context.$implicit.order_item_list; var currVal_3 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_OrdersManufacturingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrdersManufacturingComponent_4)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OrdersManufacturingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "dulo-table", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.setCurrentFilters($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(2, 245760, [[1, 4], ["ordersTable", 4]], 0, i3.TableComponent, [i4.HttpClient, i5.ExcelGeneratorService, i1.ChangeDetectorRef], { expandable: [0, "expandable"], rows: [1, "rows"], expandedTemplate: [2, "expandedTemplate"], lazy: [3, "lazy"], request: [4, "request"], mapData: [5, "mapData"], columns: [6, "columns"], filterable: [7, "filterable"], sortable: [8, "sortable"] }, null), (_l()(), i1.ɵand(0, [["orderExpandedTemplate", 2]], null, 0, null, View_OrdersManufacturingComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.currentFilters.rows; var currVal_2 = i1.ɵnov(_v, 3); var currVal_3 = true; var currVal_4 = _co.orderRequest; var currVal_5 = _co.mapData; var currVal_6 = _co.cols; var currVal_7 = false; var currVal_8 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_OrdersManufacturingComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { ordersTable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "span", [["class", "app-orders-manufacturing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "container-fluid my-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "dulo-button", [["class", "m-2"], ["label", "Skini zip"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadZip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(5, 114688, null, 0, i8.ButtonComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "dulo-button", [["class", "m-2"], ["label", "Skini nalepnice"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadStickers() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ButtonComponent_0, i7.RenderType_ButtonComponent)), i1.ɵdid(7, 114688, null, 0, i8.ButtonComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "dulo-card", [], null, null, null, i9.View_CardComponent_0, i9.RenderType_CardComponent)), i1.ɵdid(9, 114688, null, 0, i10.CardComponent, [], { headerTemplate: [0, "headerTemplate"], mainTemplate: [1, "mainTemplate"] }, null), (_l()(), i1.ɵand(0, [["ordersHeaderTemplate", 2]], null, 0, null, View_OrdersManufacturingComponent_1)), (_l()(), i1.ɵand(0, [["ordersTemplate", 2]], null, 0, null, View_OrdersManufacturingComponent_2))], function (_ck, _v) { var currVal_0 = "Skini zip"; _ck(_v, 5, 0, currVal_0); var currVal_1 = "Skini nalepnice"; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10); var currVal_3 = i1.ɵnov(_v, 11); _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
export function View_OrdersManufacturingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-orders-manufacturing", [], null, null, null, View_OrdersManufacturingComponent_0, RenderType_OrdersManufacturingComponent)), i1.ɵdid(1, 245760, null, 0, i11.OrdersManufacturingComponent, [i12.OrderService, i1.ChangeDetectorRef, i13.JwtService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrdersManufacturingComponentNgFactory = i1.ɵccf("app-orders-manufacturing", i11.OrdersManufacturingComponent, View_OrdersManufacturingComponent_Host_0, {}, {}, []);
export { OrdersManufacturingComponentNgFactory as OrdersManufacturingComponentNgFactory };
