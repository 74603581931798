import { NgModule } from "@angular/core";

import { LoaderComponent } from "./loader.component";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SharedModule } from "../shared.module";
import { LoaderService } from "./loader.service";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";

@NgModule({
  declarations: [LoaderComponent, LoadingSpinnerComponent],
  imports: [SharedModule, ProgressSpinnerModule],
  exports: [LoaderComponent, LoadingSpinnerComponent],
  providers: [LoaderService],
})
export class LoaderModule {}
