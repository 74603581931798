<main
  *ngIf="itemManufacturingData$ | async as itemManufacturingData"
  class="d-flex flex-lg-row flex-column p-3 gap-2"
>
  <section class="w-50 mr-5">
    <p class="title">Stanje za sve države</p>

    <div class="pt-3">
      <p class="p1">
        Dostupne da udju u radni nalog:
        {{ itemManufacturingData.availableItemsForWorkOrderCountGlobal }}
      </p>
      <hr />
      <p class="mb-0">
        Neprihvaćene: {{ itemManufacturingData.pendingItemsCountGlobal }}
      </p>
      <p>
        Fali na zalihama:
        {{ itemManufacturingData.missingSkuItemsCountGlobal }}
      </p>
      <p>
        Već u radnom nalogu:
        {{ itemManufacturingData.workingOrdersItemsCountGlobal}}
      </p>
      <p class="font-weight-bold p2">
        Ukupno proizvoda: {{ itemManufacturingData.allItemsCountGlobal }}
      </p>
    </div>

    <div class="pt-3">
      <p class="mb-0">
        Dnevni prosek u prethodnih 7 dana:
        {{ itemManufacturingData.averageDailyItemsCountGlobal }}
      </p>
      <p>
        Preporučen iznos za izradu:
        {{
          itemManufacturingData.suggestedItemsNumberForDailyManufacturingGlobal
        }}
      </p>
    </div>
  </section>
  <section class="w-50">
    <p class="title">Stanje za {{ country }}</p>

    <div class="pt-3">
      <p class="p1">
        Dostupne da udju u radni nalog:
        {{ itemManufacturingData.availableItemsForWorkOrderCount }}
      </p>
      <hr />
      <p class="mb-0">
        Neprihvaćene: {{ itemManufacturingData.pendingItemsCount }}
      </p>
      <p>Fali na zalihama: {{ itemManufacturingData.missingSkuItemsCount }}</p>
      <p>
        Već u radnom nalogu:
        {{ itemManufacturingData.workingOrdersItemsCount }}
      </p>
      <p class="font-weight-bold p2">
        Ukupno proizvoda: {{ itemManufacturingData.allItemsCount }}
      </p>
    </div>

    <div class="pt-3">
      <p class="mb-0">
        Dnevni prosek u prethodnih 7 dana:
        {{ itemManufacturingData.averageDailyItemsCount }}
      </p>
      <p>
        Preporučen iznos za izradu:
        {{ itemManufacturingData.suggestedItemsNumberForDailyManufacturing }}
      </p>
    </div>
  </section>
</main>
