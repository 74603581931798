import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Table } from "primeng/table";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { TableColumn } from "./table-column";
// import { ExcelGeneratorService } from '../excel-generator/excel-generator.service';
import { FilterUtils } from "primeng/components/utils/filterutils";
import { PaginatedResult } from "src/app/components/table/paginated-result";
import { SubSink } from "subsink";
import { ExcelGeneratorService } from "../generators/excel-generator/excel-generator.service";
import { TableFilter } from "./table-filter";
@Component({
  selector: "dulo-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() buttons = false;
  @Input() expandable = false;
  @Input() rows: number = 10;
  @Input() paginator: boolean = true;
  @Input() buttonsTemplate: TemplateRef<any>;
  @Input() expandedTemplate: TemplateRef<any>;
  @Input() emptyMessageTemplate: TemplateRef<any>;
  @Input() emptyMessage: string = 'Nema podataka';
  @Input() lazy: boolean = false;
  @Input() url: string;
  @Input() request: (event) => Observable<any>;
  @Input() filterData: Function = (x: any, index: number): any => x;
  @Input() mapData: Function = (x: any, index: number): any => x;
  @Input() disableLoading = false;
  @Input() columns: TableColumn[];
  @Input() values: any[] = [];
  @Input() filterable: boolean = true;
  @Input() sortable: boolean = true;
  @Input() reorderable: boolean = false;
  @Input() selectable: boolean = false;
  @Input() exportable: boolean = false;
  @Input() exportableColumns: string[];
  @Input() exportPdfName = 'table.pdf';
  @Input() rowControlsPosition: 'start' | 'end' = 'start';
  @Output() rowReorder: EventEmitter<any> = new EventEmitter();

  totalRecords: number;
  extraColumnsCount: number;
  selectedValues: any[] = [];
  @ViewChild("dt", { static: true }) dt: Table;
  loading$ = new BehaviorSubject<boolean>(true);

  @Input() set loading(value: boolean) {
    this.loading$.next(value);
  }

  subs = new SubSink();
  constructor(
    private http: HttpClient,
    private excelGenerator: ExcelGeneratorService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.totalRecords = !!this.values ? this.values.length / this.rows + 1 : 0;
    this.extraColumnsCount =
      +this.buttons + +this.selectable + +this.expandable;
    this.registerFilters();
  }

  onRowReorderHandler($event): void {
    this.rowReorder.emit($event);
  }
  registerFilters(): void {
    FilterUtils['dateBetween'] = (value, filter): boolean => {
      let startDate = filter[0];
      let endDate = filter[1];
      if (startDate.toDateString() === endDate.toDateString()) {
        return startDate.toDateString() === new Date(value).toDateString();
      } else {
        return (
          new Date(startDate.toDateString()) <=
            new Date(new Date(value).toDateString()) &&
          new Date(new Date(value).toDateString()) <=
            new Date(endDate.toDateString())
        );
      }
    };
    FilterUtils['numberBetween'] = (value, filter): boolean => {
      return value >= filter.min && value <= filter.max;
    };
  }

  exportPdf(): void {
    if (this.exportableColumns) {
    } else {
    }
  }

  exportExcel(): void {
    if (this.exportableColumns) {
    } else {
      this.excelGenerator.saveExcel(this.values);
    }
  }

  getFileName(url: string): string {
    return url ? url.split('/').pop() : '';
  }

  load(event: TableFilter): void {
    if (this.disableLoading) {
      this.values = [];
      this.totalRecords = 0;
      return;
    }
    this.loading$.next(true);

    if (this.url) {
      this.subs.add(
        this.http
          .post(this.url, event)
          .pipe(
            finalize(() => {
              this.loading$.next(false);
            })
          )
          .subscribe((res: PaginatedResult<any>): void => {
            this.values = res.current_results
              .filter((v: any, index: number): any => this.filterData(v, index))
              .map((v: any, index: number): any => this.mapData(v, index));
            this.totalRecords = res.total_results;
          })
      );
    } else {
      this.subs.add(
        this.request(event)
          .pipe(
            finalize(() => {
              this.loading$.next(false);
            })
          )
          .subscribe((res: PaginatedResult<any>): void => {
            if (res.current_results) {
              this.values = res.current_results
                .filter((v: any, index: number): any =>
                  this.filterData(v, index)
                )
                .map((v: any, index: number): any => this.mapData(v, index));
            } else {
              this.values = [];
            }
            this.totalRecords = res.total_results;
          })
      );
    }
  }

  rerender(): void {
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
