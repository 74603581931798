import { NgModule } from '@angular/core';
import { FormComponent } from './form.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { SelectModule } from '../select/select.module';
import { ButtonModule } from '../button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwitchModule } from '../switch/switch.module';
import { AutoCompleterModule } from '../auto-completer/auto-completer.module';
import { FieldsErrorModule } from '../field-errors/field-errors-module';
import { SharedModule } from '../shared.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { HttpClientModule } from '@angular/common/http';
import { PasswordModule } from '../password/password.module';
import { InputModule } from '../input/input.module';
import { NumberRangeInputModule } from '../number-range-input/number-range-input.module';
import { RadioButtonModule } from '../radio-button/radio-button.module';
import { SelectButtonModule } from '../select-button/select-button.module';
import { TextareaModule } from '../textarea/textarea.module';

@NgModule({
  declarations: [FormComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    DatePickerModule,
    SelectModule,
    ButtonModule,
    SwitchModule,
    AutoCompleterModule,
    FieldsErrorModule,
    FileUploadModule,
    InputModule,
    PasswordModule,
    NumberRangeInputModule,
    HttpClientModule,
    RadioButtonModule,
    SelectButtonModule,
    TextareaModule,
  ],
  exports: [FormComponent],
})
export class FormModule {}
