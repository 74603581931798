import {
  Component,
  OnInit,
  Input,
  Type,
  forwardRef,
  Output,
  EventEmitter,
  TemplateRef,
  SimpleChanges,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { ResolveSelectButtonPipe } from './resolve-select-button.pipe';

@Component({
  selector: 'dulo-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<SelectButtonComponent> => SelectButtonComponent,
      ),
      multi: true,
    },
  ],
})
export class SelectButtonComponent implements OnInit, ControlValueAccessor {
  @Input() rawValues: any[];
  @Input() values: SelectItem[];
  @Input() disabled: boolean;
  @Input() styleClass: string;
  @Input() multiple: boolean;
  @Input() template: TemplateRef<any>;
  @Output() onChange = new EventEmitter<any>();
  valuesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(null);
  namespace: string = 'select-button';

  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};

  selectedItem: any;

  get SelectedItem(): any {
    return this.selectedItem;
  }
  @Input() set SelectedItem(value: any) {
    this.selectedItem = value;
    this.onModelChange(value);
    this.onModelTouched();
  }

  constructor(
    private resolveSelectButtonPipe: ResolveSelectButtonPipe,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rawValues'] && changes['rawValues'].currentValue) {
      this.values = this.resolveSelectButtonPipe.transform(this.rawValues);
      this.valuesLoaded.next(true);
    }
  }

  writeValue(obj: any): void {
    if (this.values) {
      this.selectedItem = obj;
      this.cdr.markForCheck();
    } else {
      this.valuesLoaded
        .pipe(
          filter((v): boolean => v != null),
          take(1),
        )
        .subscribe((): void => {
          this.selectedItem = obj;
          this.cdr.markForCheck();
        });
    }
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngOnDestroy(): void {
    this.valuesLoaded.unsubscribe();
  }
}
