import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ChangeDetectionStrategy,
  forwardRef,
  Type,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'dulo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (): Type<InputComponent> => InputComponent,
      ),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() floatablePlaceholder: boolean = true;
  @Input() preTemplate: TemplateRef<any>;
  @Input() postTemplate: TemplateRef<any>;
  @Input() disabled: boolean = false;
  value: string;
  @Input() styleClass: string;
  @Input() regex:
    | 'pint'
    | 'int'
    | 'pnum'
    | 'num'
    | 'hex'
    | 'email'
    | 'alpha'
    | 'alphanum'
    | RegExp = /^.{0,}$/;
  @Input() enableTypingIfErrors = false;
  @Output() onInput = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();
  onModelChange: Function = (): void => {};
  onModelTouched: Function = (): void => {};
  get Value(): string {
    return this.value;
  }
  @Input() set Value(value: string) {
    this.value = value;
    this.onModelChange(value);
    this.onModelTouched();
    this.cdr.markForCheck();
  }
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  writeValue(value: string): void {
    this.Value = value + '';
  }
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
