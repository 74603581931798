import { NgModule } from '@angular/core';
import { InputComponent } from './input.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SharedModule } from '../shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [InputComponent],
  imports: [SharedModule, FormsModule, KeyFilterModule],
  exports: [InputComponent],
})
export class InputModule {}
