import { HttpParams, HttpHeaders } from "@angular/common/http";
import { SkipRouteUrlInterceptor } from "../_interceptors/route-url.interceptor";
import * as moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./jwt.service";
export class OrderService {
    constructor(http, jwtService) {
        this.http = http;
        this.jwtService = jwtService;
        this.baseUrl = "partner/orders";
    }
    getActiveWorkOrders() {
        return this.http.get("partner/work_orders?sort_by=id&sort_order=desc&state=in:STARTED");
    }
    getWorkOrders(workOrderId, filters) {
        let params = new HttpParams()
            .set("page", filters.first / filters.rows + "")
            .set("size", filters.rows + "")
            .set("sort_by", filters.sortField ? filters.sortField : "created")
            .set("sort_order", +filters.sortOrder === 1 ? "asc" : "desc");
        return this.http.get(`partner/work_orders/${workOrderId}/orders`, {
            params,
        });
    }
    getAll(type, filters) {
        let params = new HttpParams()
            .set("page", filters.first / filters.rows + "")
            .set("size", filters.rows + "")
            .set("sort_by", filters.sortField ? filters.sortField : "created")
            .set("sort_order", +filters.sortOrder === 1 ? "asc" : "desc");
        switch (type) {
            case "manufacturing": {
                params = params
                    .set("manufacturing_date", "eq:" + moment().format("YYYY-MM-DD-00-00-00"))
                    .set("state", "in:ACCEPTED,MANUFACTURING");
                break;
            }
            case "manufactured-products": {
                Object.entries(filters.filters).forEach((filter) => (params = params.set(filter[0], filter[1])));
                break;
            }
            case "ready_for_shipping": {
                params = params
                    .set("manufacturing_date", "lt:" + moment().add(1, "days").format("YYYY-MM-DD-00-00-00"))
                    .set("state", "in:READY_FOR_SHIPPING");
                break;
            }
            case "manufacturing-ready_for_shipping": {
                params = params
                    .set("manufacturing_date", "lt:" + moment().add(1, "days").format("YYYY-MM-DD-00-00-00"))
                    .set("state", "in:ACCEPTED,MANUFACTURING,READY_FOR_SHIPPING");
                break;
            }
            case "manufacturing-shipping": {
                params = params
                    .set("manufacturing_date", "eq:" + moment().format("YYYY-MM-DD-00-00-00"))
                    .set("state", "in:ACCEPTED,MANUFACTURING,READY_FOR_SHIPPING");
                break;
            }
            case "shipped": {
                params = params.set("state", "in:SHIPPING");
                break;
            }
            default: {
                break;
            }
        }
        if (filters.filters["shippingCompanyId"]) {
            params = params.set("shippingCompanyId", "eq:" + filters.filters["shippingCompanyId"]);
        }
        return this.http.get(this.baseUrl, {
            params,
        });
    }
    finishManufacturing(id) {
        return this.http.get(`${this.baseUrl}/${id}/finish_manufacturing`);
    }
    startManufacturing(id) {
        return this.http.get(`${this.baseUrl}/${id}/start_manufacturing`);
    }
    repeatOrderItemManufacturing(id) {
        return this.http.put(`${this.baseUrl}/${id}/repeat_manufacturing`, {});
    }
    toggleOrderItemIsManufactured(id) {
        return this.http.put(`${this.baseUrl}/${id}/manufactured`, {});
    }
    getPhoto(url) {
        return this.http.get(url, {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders().set(SkipRouteUrlInterceptor, ""),
        });
    }
    ship(id) {
        return this.http.get(`${this.baseUrl}/${id}/start_shipping`);
    }
    updatePrimers(orderItems) {
        return this.http.put(`partner/order_items/primer_used`, orderItems.map((oi) => {
            return {
                id: oi.id,
                primer_used: oi.primerUsed,
            };
        }));
    }
    getOrderItemsCount() {
        let params = new HttpParams();
        params = params.set("manufacturing_date", "eq:" + moment().format("YYYY-MM-DD-00-00-00"));
        return this.http.get(`partner/order_items/count`, {
            params: params,
        });
    }
    getShippingLabel(orderId) {
        return this.http.get(`${this.baseUrl}/${orderId}/get_shipping_label`, {
            observe: "response",
            responseType: "blob",
        });
    }
    getItemsManufacturingData() {
        return this.http.get(`${this.baseUrl}/items_for_manufacturing_data/${this.jwtService.getDecodedAccessToken().user_id}`);
    }
}
OrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtService)); }, token: OrderService, providedIn: "root" });
