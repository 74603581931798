import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';
import * as i0 from "@angular/core";
export class ExcelGeneratorService {
    constructor() { }
    saveExcel(values) {
        const worksheet = xlsx.utils.json_to_sheet(values);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'table');
    }
    saveAsExcelFile(buffer, fileName) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}
ExcelGeneratorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExcelGeneratorService_Factory() { return new ExcelGeneratorService(); }, token: ExcelGeneratorService, providedIn: "root" });
