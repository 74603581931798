<div class="container-fluid my-5" *ngIf="companies$ | async as companies">
  <dulo-card
    [mainTemplate]="ordersTemplate"
    [headerTemplate]="ordersHeaderTemplate"
  >
    <ng-template #ordersHeaderTemplate>
      <div class="px-2">
        <div class="my-2">
          <dulo-radio-button
            class="mr-2"
            [Selected]="'ready_for_shipping'"
            (onClick)="changeType('ready_for_shipping')"
            name="type"
            value="ready_for_shipping"
            label="Samo READY_FOR_SHIPING"
          ></dulo-radio-button>
          <dulo-radio-button
            name="type"
            (onClick)="changeType('manufacturing-ready_for_shipping')"
            value="manufacturing-ready_for_shipping"
            label="Svi"
          ></dulo-radio-button>
        </div>
        <dulo-button
          label="Označi kao poslate"
          (click)="markOrdersAsSent()"
        ></dulo-button>
        <dulo-button
          label="Skini excel za poštu"
          (click)="downloadShippingExcels()"
        ></dulo-button>
      </div>

      <div class="px-2">
        <div class="my-2">
          <dulo-radio-button
            name="company"
            [Selected]="companies[0].id"
            [value]="company.id"
            (onClick)="setCompanyId(company.id)"
            *ngFor="let company of companies"
            [label]="company.name"
          ></dulo-radio-button>
        </div>
      </div>
    </ng-template>
    <ng-template #ordersTemplate>
      <dulo-table
        [columns]="cols"
        [rows]="currentFilters.rows"
        [lazy]="true"
        [request]="orderRequest"
        [mapData]="mapData"
        [filterable]="false"
        [sortable]="false"
        [selectable]="true"
        (onChange)="setCurrentFilters($event)"
      >
      </dulo-table>
    </ng-template>
  </dulo-card>
</div>

<ng-template #deliveryInfoTemplate let-order>
  <div class="d-flex flex-column">
    <p>
      {{
        this.fulfillmentTypeTranslation[
          order["delivery_info"]["fulfillment_type"]
        ]
      }}
    </p>
    <p *ngIf="order['delivery_info']['delivery_date']">
      {{ order["delivery_info"]["delivery_date"] | date: "dd.MM.yyyy." }}
    </p>
  </div>
</ng-template>
