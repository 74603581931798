<p-paginator
  [totalRecords]="totalRecords"
  [rows]="pageSize"
  [first]="page"
  [pageLinkSize]="pageLinkSize"
  [style]="elStyle"
  [styleClass]="elClass"
  [alwaysShow]="alwaysShow"
  [showCurrentPageReport]="showCurrentPageReport"
  [currentPageReportTemplate]="currentPageReportTemplate"
  (onPageChange)="pageChangeHandler.emit($event)"
></p-paginator>
