import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { map, tap } from "rxjs/operators";
import { ProductTypeService } from "src/app/_services/product-type.service";
import { SupplyService } from "src/app/_services/supply.service";
import { ColorService } from "src/app/_services/color.service";
import { PopupService } from "src/app/components/popup/popup.service";
import { TableColumn } from "src/app/components/table/table-column";
import { WorkOrderCreationOption } from "src/app/enums/work-order-creation-option.enum";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WorkOrderState } from "src/app/enums/work-order-state.enum";
import { TableComponent } from "src/app/components/table/table.component";
import { saveAs } from "file-saver";
import { WorkOrderStateTranslation } from "src/app/enums/work-order-state-translation.enum";
import { BehaviorSubject } from "rxjs";
import { LoaderService } from "src/app/components/loader/loader.service";

@Component({
  selector: "app-supplies-takeover",
  templateUrl: "./supplies-takeover.component.html",
  styleUrls: ["./supplies-takeover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuppliesTakeoverComponent implements OnInit, OnDestroy {
  freeOrderItems$ = new BehaviorSubject<number>(0);
  workOrders$ = ((event) => {
    this.currentFilters = {
      ...this.currentFilters,
      ...event,
      filters: {
        ...event.filters,
      },
    };
    return this.supplyService.getWorkOrders(this.currentFilters).pipe(
      tap((res) => {
        res.current_results.forEach((result) => {
          switch (result.state) {
            case WorkOrderState.READY:
              result.stateTranslated = WorkOrderStateTranslation.READY;
              break;

            case WorkOrderState.FINISHED:
              result.stateTranslated = WorkOrderStateTranslation.FINISHED;
              break;

            case WorkOrderState.STARTED:
              result.stateTranslated = WorkOrderStateTranslation.STARTED;
              break;

            case WorkOrderState.PROCESSING:
              result.stateTranslated = WorkOrderStateTranslation.PROCESSING;
              break;

            case WorkOrderState.PROCESSING_FAILED:
              result.stateTranslated =
                WorkOrderStateTranslation.PROCESSING_FAILED;
              break;

            default:
              break;
          }

          return result;
        });
      })
    );
  }).bind(this);

  supplies$ = this.supplyService.getAllForTakeover(new Date()).pipe(
    map((supplies) => {
      return supplies.map((s) => {
        const supply = {
          ...s,
          have_enough_stock: s.real_stock_amount_in_base >= s.amount,
        };
        return supply;
      });
    })
  );
  exportPdfName = `preuzmi-iz-sivare-${new Date().toLocaleDateString()}`;
  display = false;
  @ViewChild("stateTemplate", { static: true })
  stateTemplate: TemplateRef<any>;
  @ViewChild(TableComponent, { static: true })
  table: TableComponent;
  cols: TableColumn[];
  isLoading = new BehaviorSubject<boolean>(false);

  refreshInterval;

  type = WorkOrderCreationOption.BY_PRODUCTS_AMOUNT;
  form: FormGroup;

  public get workOrderState(): typeof WorkOrderState {
    return WorkOrderState;
  }

  pageSize = 40;
  currentFilters = {
    first: 0,
    rows: this.pageSize,
    sortOrder: 1,
    sortField: "created",
    filters: {},
  };

  workOrderOptions = [
    {
      label: "Broj komada za izradu",
      value: WorkOrderCreationOption.BY_PRODUCTS_AMOUNT,
    },
    {
      label: "Lista porudžbina",
      value: WorkOrderCreationOption.BY_ORDER_LIST,
    },
  ];

  constructor(
    private productTypeService: ProductTypeService,
    private supplyService: SupplyService,
    private loader: LoaderService,
    private popupService: PopupService,
    private fb: FormBuilder
  ) {}

  reloadTable() {
    this.table.load(this.currentFilters);
    this.supplyService
      .countFreeOrderItems()
      .subscribe((response: number) => this.freeOrderItems$.next(response));
  }

  downloadProductList(fileUrl: string) {
    saveAs(fileUrl, "spisak.pdf");
  }

  downloadStickers(fileUrl: string) {
    saveAs(fileUrl, "stikeri.zip");
  }

  downloadPrepress(fileUrl: string) {
    saveAs(fileUrl, "pripremi.zip");
  }

  handleWorkOrderCreation(workOrderState: string, workOrderId: string) {
    if (workOrderState === WorkOrderState.STARTED) {
      this.popupService
        .fire({
          title: `Da li ste sigurni da želite da završite radni nalog ${workOrderId}?`,
          type: "warning",
          showCancelButton: true,
        })
        .then((res) => {
          if (res.value) {
            this.supplyService.finishWorkOrder(workOrderId).subscribe(
              () => {
                this.popupService.fire({
                  title: "Uspešno završen radni nalog",
                  type: "success",
                });
                this.reloadTable();
              },
              (err) => {
                this.popupService.fire({
                  title: "Greška",
                  text: err,
                  type: "error",
                });
              }
            );
          }
        });
    } else if (workOrderState === WorkOrderState.READY) {
      this.popupService
        .fire({
          title: `Da li ste sigurni da želite da započnete radni nalog ${workOrderId}?`,
          type: "warning",
          showCancelButton: true,
        })
        .then((res) => {
          if (res.value) {
            this.supplyService.startWorkOrder(workOrderId).subscribe(
              () => {
                this.popupService.fire({
                  title: "Uspešno započet radni nalog",
                  type: "success",
                });
                this.reloadTable();
              },
              (err) => {
                this.popupService.fire({
                  title: "Greška",
                  text: err,
                  type: "error",
                });
              }
            );
          }
        });
    }
  }

  createWorkOrder() {
    if (!this.form.valid) {
      this.form.get("value").markAsTouched();
      return;
    }

    this.display = false;
    this.supplyService.createWorkOrder(this.form.value).subscribe(() => {
      this.reloadTable();
    });
  }

  show() {
    this.form.setValue({
      workOrderCreationOption: WorkOrderCreationOption.BY_PRODUCTS_AMOUNT,
      value: "",
    });
    this.form.get("value").markAsUntouched();
    this.display = true;
  }

  changeType(type: WorkOrderCreationOption) {
    this.type = type;
    this.form.patchValue({
      workOrderCreationOption: this.type,
    });
  }

  ngOnInit() {
    this.isLoading.next(true);
    this.supplyService.countFreeOrderItems().subscribe((response: number) => {
      this.freeOrderItems$.next(response);
      this.isLoading.next(false);
    });

    this.cols = [
      {
        header: "ID",
        field: "id",
        filterType: "string",
        type: "alphanumeric",
        width: "5%",
      },
      {
        header: "Broj proizvoda za izradu",
        field: "productsAmount",
        filterType: "string",
        type: "alphanumeric",
        width: "10%",
      },
      {
        header: "Datum kreiranja",
        field: "created",
        filterType: "date",
        type: "date",
        width: "15%",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Datum početka",
        field: "startTime",
        filterType: "date",
        type: "date",
        width: "15%",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Datum zatvaranja",
        field: "endTime",
        filterType: "date",
        type: "date",
        width: "15%",
        options: {
          format: "dd.MM.yyyy HH:mm",
        },
      },
      {
        header: "Status radnog naloga",
        field: "state",
        filterType: "select",
        type: "custom",
        width: "12%",
        options: {
          multiple: true,
          template: this.stateTemplate,
          values: [
            { value: WorkOrderState.CREATE, label: WorkOrderState.CREATE },
            {
              value: WorkOrderState.PROCESSING,
              label: "U obradi",
            },
            { value: WorkOrderState.READY, label: "Spreman" },
            { value: WorkOrderState.STARTED, label: "U izradi" },
            { value: WorkOrderState.FINISHED, label: "Zatvoren" },
            {
              value: WorkOrderState.PROCESSING_FAILED,
              label: "Greška pri obradi",
            },
          ],
        },
      },
    ];

    this.form = this.fb.group({
      workOrderCreationOption: [this.type, Validators.required],
      value: ["", Validators.required],
    });

    this.refreshInterval = setInterval(() => {
      this.reloadTable();
    }, 5 * 60 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  // cols: TableColumn[]  = [{
  //   header: 'Proizvod',
  //   field: 'product_type_name',
  //   filterType: 'select',
  //   type: 'alphanumeric',
  //   options: {
  //     rawValues: this.productTypeService.getAll().pipe(map((productTypes) => {
  //       return productTypes.map((pt) => {
  //         return {
  //           id: pt['name'],
  //           name: pt['name']
  //         };
  //       });
  //     })),
  //     hasNone: false
  //   }
  // }, {
  //   header: 'Boja',
  //   field: 'color_name',
  //   filterType: 'select',
  //   type: 'alphanumeric',
  //   options: {
  //     rawValues: this.colorService.getAll().pipe(map((colors) => {
  //       return colors.map((c) => {
  //         return {
  //           id: c['name'],
  //           name: c['name']
  //         };
  //       });
  //     })),
  //     hasNone: false
  //   }
  // }, {
  //   header: 'Veličina',
  //   field: 'size_name',
  //   filterType: 'select',
  //   type: 'alphanumeric',
  //   options: {
  //     values: [
  //       {value: 'XS', label: 'XS'},
  //       {value: 'S', label: 'S'},
  //       {value: 'M', label: 'M'},
  //       {value: 'L', label: 'L'},
  //       {value: 'XL', label: 'XL'},
  //       {value: 'XXL', label: 'XXL'},
  //       {value: 'XXXL', label: 'XXXL'}
  //     ]
  //   },
  // }, {
  //   header: 'Količina',
  //   field: 'amount',
  //   filterType: 'number',
  //   type: 'alphanumeric',
  // }, {
  //   header: 'Zalihe',
  //   field: 'real_stock_amount_in_base',
  //   filterType: 'number',
  //   type: 'alphanumeric',
  // },  {
  //   header: 'Ima dovoljno',
  //   field: 'have_enough_stock',
  //   filterType: 'select',
  //   type: 'boolean',
  //   options: {
  //     values: [
  //       {value: false, label: 'Nema'},
  //       {value: true, label: 'Ima'}
  //     ]
  //   }
  // }];
}
