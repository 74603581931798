import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { AccordionComponent } from './accordion.component';
import { AccordionModule as PrimeNgAccordionModule } from 'primeng/accordion';

@NgModule({
  declarations: [AccordionComponent],
  imports: [SharedModule, PrimeNgAccordionModule],
  exports: [AccordionComponent],
})
export class AccordionModule {}
