import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';

@Pipe({
  name: 'resolveSelectButton',
})
export class ResolveSelectButtonPipe implements PipeTransform {
  transform(value: any[]): SelectItem[] {
    const values = [];
    values.push(
      ...value.map(
        (v: any): SelectItem => {
          return {
            label: v.name,
            value: v.id,
            disabled: v.disabled,
          };
        },
      ),
    );
    return values;
  }

  constructor(private translate: TranslateService) {}
}
