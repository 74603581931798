export const environment = {
  production: true,
  apiProtocol: "https://",
  apiHost: "api-brendly-prod.herokuapp.com",
  appUrl: "https://partner.brendly.rs",
  apiUrl: "",
  s3Url: "https://brendly-prod.s3.eu-central-1.amazonaws.com/",
  sentryDSNKey:
    "https://7af7771aa37afabc215ac814464a3be8@o1377780.ingest.us.sentry.io/4507500115132416",
  countries: [
    {
      alpha2Code: "rs",
      apiUrl: "https://api.brendly.rs/",
    },
    {
      alpha2Code: "hr",
      apiUrl: "https://api.brendly.hr/",
    },
  ],
};
environment.apiUrl = environment.apiProtocol + environment.apiHost + "/";
