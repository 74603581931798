<div>
  <dulo-input
    #min
    class="dulo-input min"
    (onChange)="check()"
    [disabled]="disabled"
    [placeholder]="minPlaceholder"
    [regex]="onlyPositive ? 'pnum' : 'num'"
    [Value]="minValue | async"
  >
  </dulo-input>
  <dulo-input
    #max
    class="dulo-input max"
    (onChange)="check()"
    [disabled]="disabled"
    [placeholder]="maxPlaceholder"
    [regex]="onlyPositive ? 'pnum' : 'num'"
    [Value]="maxValue | async"
  >
  </dulo-input>
</div>
<input type="text" [(ngModel)]="Value" class="value" />
