import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../components/local-storage-service";
export class JwtService {
    constructor(localStorageService) {
        this.localStorageService = localStorageService;
        this.ACCESS_TOKEN_KEY = "accessToken";
        this.REFRESH_TOKEN_KEY = "refreshToken";
        this.jwtHelper = new JwtHelperService();
        this.accessTokens = new BehaviorSubject(this.getAccessToken());
        this.accessTokens$ = this.accessTokens.asObservable();
    }
    stillLoggedIn() {
        return this.isUserLoggedIn() && !this.isTokenExpired();
    }
    isUserLoggedIn() {
        return this.getAccessToken() !== null;
    }
    getAccessToken() {
        return this.localStorageService.getItem(this.ACCESS_TOKEN_KEY);
    }
    getRefreshToken() {
        return this.localStorageService.getItem(this.REFRESH_TOKEN_KEY);
    }
    getDecodedAccessToken() {
        return this.jwtHelper.decodeToken(this.getAccessToken());
    }
    saveAccessToken(accessToken) {
        this.localStorageService.setItem(this.ACCESS_TOKEN_KEY, accessToken);
        this.accessTokens.next(accessToken);
    }
    removeAccessToken() {
        if (localStorage.getItem(this.ACCESS_TOKEN_KEY)) {
            localStorage.removeItem(this.ACCESS_TOKEN_KEY);
            this.accessTokens.next(null);
        }
    }
    removeRefreshToken() {
        if (localStorage.getItem(this.REFRESH_TOKEN_KEY)) {
            localStorage.removeItem(this.REFRESH_TOKEN_KEY);
        }
    }
    saveRefreshToken(refreshToken) {
        this.localStorageService.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
    }
    getAccessTokenExpirationDate() {
        return this.jwtHelper.getTokenExpirationDate(this.getAccessToken());
    }
    isTokenExpired() {
        return this.jwtHelper.isTokenExpired(this.getAccessToken());
    }
    getUserId() {
        return this.jwtHelper.decodeToken(this.getAccessToken()).user_id;
    }
}
JwtService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtService_Factory() { return new JwtService(i0.ɵɵinject(i1.LocalStorageService)); }, token: JwtService, providedIn: "root" });
